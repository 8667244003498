//Standard libraries
import axios, { AxiosPromise, AxiosRequestConfig, Method } from 'axios'
//Foundation libraries
import { executeRequest } from '../../axios/axiosConfig'
import { PRODUCTION, SHOW_API_FLOW } from '../../constants/common'
import { getSite } from '../../hooks/useSite'
import { localStorageUtil } from '../../utils/storageUtil'
//Redux
import {
  ORDER_EXTEND_ATTRIBUTE_NAMES,
  ORDER_ITEM_EXTEND_ATTRIBUTE_FILTER,
  ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS,
  ORDER_STATUS,
  TaxValues,
} from '@constants/order'
import { API_CALL_ACTION } from '@redux/actions/api'
import DateService from '@services/DateService'
import Log from '@services/Log'
import RequestService, { RecordSetResponse, RequestProps } from '@services/RequestService'
import { CheckoutPayload } from '@typesApp/checkout'
import { Attribute, IOrderDetails, IOrderItem, Order } from '@typesApp/order'
import { OrderItemInfo } from '@typesApp/orderReturn'
import { getCookieByName } from '@utils/cookie'

/**
 * @deprecated Use Redux Toolkit Query instead
 */
class OrderService {
  [x: string]: any
  readonly GET_HISTORY_REQUEST_PAGE_SIZE: number = 10

  /**
   * Gets the order details for a specific order ID.
   * `@method`
   * `@name Order#findByOrderId`
   * `@property {string} orderId (required)` The order identifier
   */
  findByOrderId({ ...rest }: Partial<RequestProps>) {
    return RequestService.request<IOrderDetails>({
      ...rest,
      method: 'GET',
      path: '/store/{storeId}/order/{orderId}',
    })
  }

  /**
   * Gets the order history for the authenticated user
   *
   * `@method`
   * `@name Order#getOrderHistory`
   */
  getOrderHistory(
    pageNumber: number | string,
    userId: string,
    pageSize?: number,
    storeId?: string,
    dateBefore?: string
  ) {
    return RequestService.request<RecordSetResponse<{ Order: Order[] }>>({
      method: 'GET',
      path: '/store/{storeId}/order/@history',
      queryParams: {
        pageNumber,
        pageSize: pageSize || this.GET_HISTORY_REQUEST_PAGE_SIZE,
        userId,
        dateBefore,
      },
      pathParams: {
        storeId,
      },
    })
  }

  /**
  * Find order by the parent order ID.
  * `@method`
  * `@name Order#findByParentOrderId`
  *
  * `@param {any} headers (optional)` will add headers to rest request
  *
  * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
  *
  * `@param {any} parameters` have following properties:
     ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.

   ** `@property {string} orderId (required)` The order identifier.
   ** `@property {string} pageNumber ` Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work.
   ** `@property {string} pageSize ` Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work.
  */
  findByParentOrderId(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    let siteContext = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    const domain = url || siteContext
    const path = '/store/{storeId}/order'
    let requestUrl = domain + path
    const method: Method = 'GET'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error("Request '/store/{storeId}/order' missing path parameter storeId")
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    queryParameters.set('q', 'findByParentOrderId')

    if (parameters['orderId'] === undefined) {
      throw new Error("Request '/store/{storeId}/order' missing required parameter orderId")
    }
    if (parameters['orderId'] !== undefined) {
      const name = 'orderId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageNumber'] !== undefined) {
      const name = 'pageNumber'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageSize'] !== undefined) {
      const name = 'pageSize'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    const showAPIFlow = process.env.NODE_ENV !== PRODUCTION ? localStorageUtil.get(SHOW_API_FLOW) === 'true' : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('@redux/store').default
      if (store) {
        store.dispatch(API_CALL_ACTION(from + ' -> Transaction: ' + method + ' ' + requestUrl + '?' + queryParameters))
      }
    }

    return executeRequest(requestOptions)
  }

  paypalExpressCheckStatus(payload: CheckoutPayload): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'GET',
      path: '/store/{storeId}/paypal/checkStatus/@self',
    })
  }

  async requestInvoice({ siteInfo, orderId }): Promise<any> {
    try {
      const { storeID, transactionContext } = siteInfo
      const { data, headers } = await axios.get(
        `${transactionContext}/store/${storeID}/sapinvoice/${orderId}/download`,
        {
          responseType: 'arraybuffer',
        }
      )
      const base64Prescription = Buffer.from(data, 'binary').toString('base64')
      const stream = await fetch(`data:${headers['content-type']};base64,${base64Prescription}`)
      return await stream.blob()
    } catch (e) {
      Log.error(String(e))
    }
  }

  getInvoiceUrl(orderExtendAttribute: Order['orderExtendAttribute'] = []): string {
    return (
      orderExtendAttribute.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.INVOICE_URLS)?.attributeValue ||
      ''
    )
  }

  isRoxable(orderExtendAttribute: Order['orderExtendAttribute'] = []): string {
    return (
      orderExtendAttribute.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.IS_ROX_ORDER)?.attributeValue ||
      ''
    )
  }

  getTrackingIds(orderExtendAttribute: Order['orderExtendAttribute'] = []): string[] {
    try {
      const trackingIds =
        orderExtendAttribute.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.TRACK_NUMBER)?.attributeValue ||
        '{}'
      const trackingIdsObject = JSON.parse(trackingIds)
      return trackingIdsObject['tracking-codes'].filter(Boolean)
    } catch (e) {
      return []
    }
  }

  getOrderItemTaxes(orderId: string, orderItemExtendAttribute?: Attribute<string>[]): TaxValues {
    if (orderItemExtendAttribute == null) {
      return {
        provincial: 0,
        federal: 0,
      }
    }
    const provincialTaxAttributes = orderItemExtendAttribute.filter(attribute =>
      ORDER_ITEM_EXTEND_ATTRIBUTE_FILTER.PROVINCIAL_TAX(orderId, attribute)
    )

    const countryTaxAttributes = orderItemExtendAttribute.filter(attribute =>
      ORDER_ITEM_EXTEND_ATTRIBUTE_FILTER.FEDERAL_TAX(orderId, attribute)
    )

    const provincialTaxes = provincialTaxAttributes.reduce((accum, current) => {
      const value = parseFloat(current?.attributeValue) || 0
      return accum + value
    }, 0)

    const federalTaxes = countryTaxAttributes.reduce((accum, current) => {
      const value = parseFloat(current?.attributeValue) || 0
      return accum + value
    }, 0)

    return {
      provincial: provincialTaxes,
      federal: federalTaxes,
    }
  }

  getOrderTaxes(orderId: string, orderItems: IOrderItem[]): TaxValues {
    const taxValues = orderItems?.reduce(
      (accum, current) => {
        const itemTaxes = this.getOrderItemTaxes(orderId, current?.orderItemExtendAttribute)
        return {
          provincial: accum.provincial + itemTaxes.provincial,
          federal: accum.federal + itemTaxes.federal,
        }
      },
      { provincial: 0, federal: 0 } as TaxValues
    )

    return taxValues
  }

  isLegacyReturn(order: Order, n1SiteGoliveDate: string): boolean {
    let placedDate
    if (order && order.placedDate) placedDate = new Date(order.placedDate)
    return placedDate < new Date(n1SiteGoliveDate)
  }
  isReturnEligible(order: Order, n1SiteGoliveDate: string): boolean {
    const returnableStatuses = [
      ORDER_STATUS.Settled, // This is considered as Shipped on FE
      ORDER_STATUS.Delivered,
    ]
    return (
      returnableStatuses.includes(order?.orderStatus) &&
      DateService.getElapsedDays(order?.lastUpdateDate) <= 30 &&
      !this.isLegacyReturn(order, n1SiteGoliveDate)
    )
  }
  getOrderItemReturnDaysLeft(_orderItemInfo: OrderItemInfo): number {
    // TODO: update calculation based on shipment received date field, to be clarified with ESSPI
    return 18
  }
  getOrderItemTrackingNumber(orderId: string, orderItemExtendAttribute: Attribute<string>[]): string {
    const trackingNumber =
      orderItemExtendAttribute.find(
        a =>
          a.attributeName.toLowerCase() === ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS.TRACK_NUMBER(orderId).toLowerCase()
      )?.attributeValue || ''

    return trackingNumber
  }

  /**
   * Fetch Reminder Order
   * `@method`
   * `@name Order#fetchReminderOrder`
   * `@param {string} reminderId (required)` The reminder identifier
   * `@param {string} storeId (required)` The store identifier
   */
  fetchReminderOrder(reminderId: string, storeId: string) {
    let data = {
      reminderId,
      storeId,
    }
    return RequestService.request({
      body: data,
      method: 'POST',
      path: '/store/{storeId}/reordercart/{reminderId}',
      pathParams: {
        reminderId,
        storeId,
      },
    })
  }
}
const orderService = new OrderService()
export default orderService
