import { MainHead } from '@components/MainHead'
import PrescriptionLensesProvider from '@components/PrescriptionLenses/PrescriptionLensesContext'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { SET_HOSTNAME, SET_HOSTNAME_URLS, SET_LOCATION_ORIGIN } from '@redux/actions/site'
import wrapper from '@redux/store/index'
import theme from '@themes/index'
import { appWithTranslation } from 'next-i18next'
import NextApp, { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { logger } from '../logging'

import '../styles/global.scss'

import { CookiesProvider, parseCookies } from '@utils/Cookies'
import { serverInitialPropsWrapper } from '@utils/SSR/wrapperGetServerProps'
import { TmpCookiesObj } from 'cookies-next/lib/types'
import { getHostnameUrls } from '@utils/getHostnameUrls'
import { PageProvider } from '@utils/SSR/PageContext'
import LayoutKeyBoardAvoidingOffset from '@layouts/BaseLayout/components/LayoutKeyboardAvoiding'

type MyAppProps = AppProps & {
  cookies: TmpCookiesObj
  hostnameUrls: Record<string, string>
  currentHostname: string
  locationOrigin: string
}

export function MyApp({ Component, cookies, hostnameUrls, currentHostname, locationOrigin, ...rest }: MyAppProps) {
  const { store, props } = wrapper.useWrappedStore(rest)
  const { pageProps } = props

  store.dispatch(SET_HOSTNAME_URLS(hostnameUrls))
  store.dispatch(SET_HOSTNAME(currentHostname))
  store.dispatch(SET_LOCATION_ORIGIN(locationOrigin))

  return (
    <>
      <LayoutKeyBoardAvoidingOffset />
      <MainHead hostnameUrls={hostnameUrls} locationOrigin={locationOrigin} />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CookiesProvider cookies={cookies}>
            <PageProvider initialCookies={cookies}>
              <CssBaseline />
              <PrescriptionLensesProvider>
                <Component {...pageProps} />
              </PrescriptionLensesProvider>
            </PageProvider>
          </CookiesProvider>
        </ThemeProvider>
      </Provider>
    </>
  )
}

MyApp.getInitialProps = serverInitialPropsWrapper<MyAppProps>(async (stateManager, context) => {
  const { hostname, hostnameUrls, locationOrigin } = getHostnameUrls(context.ctx.req)
  try {
    return {
      ...NextApp.getInitialProps(context),
      cookies: parseCookies(context.ctx.req),
      hostnameUrls,
      currentHostname: hostname,
      locationOrigin,
    }
  } catch (e: any) {
    logger.error(
      `<<_app>> smth went wrong code: ${e.code || e.statusCode} message: ${e.message} url: ${e?.config?.url}`
    )
    return { initialProps: { error: e } }
  }
})

export default appWithTranslation(MyApp)
