import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '@services/AxiosBaseQuery'
import { getCookieByName } from '@utils/cookie'
import { BancontactPaymentUrlPayload, BancontactRedirectUrlsPayload } from './types'

export const bancontactApi = createApi({
  reducerPath: 'bancontactApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: build => ({
    getBancontactPaymentUrl: build.query<any, BancontactPaymentUrlPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { userAgent } = window.navigator
        const forterToken = getCookieByName('forterToken')
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/bancontact/saleMerchantUrl/@self`,
          method: 'GET',
          body:
            {
              userAgent,
              forterToken,
              ...args,
            } || {},
          pathParams: { storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    setBancontactRedirectUrls: build.mutation<any, BancontactRedirectUrlsPayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, redirectUrls } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/bancontact/saleMerchantUrl/@self`,
          method: 'POST',
          body: redirectUrls,
          pathParams: { storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
  }),
})

export const { useLazyGetBancontactPaymentUrlQuery, useSetBancontactRedirectUrlsMutation } = bancontactApi
