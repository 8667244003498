import { ACCOUNT_CHILDREN, CART, CHECKOUT_CHILDREN } from '@constants/routes'
import { EXPIRED_PASSWORD_PAGE_ERROR } from '@foundation/constants/common'
import {
  INITIATED_FROM_STORAGE,
  IS_REORDER_IN_3_CLICKS_BUTTON_CLICKED,
  IS_RETURNING_CUSTOMER_BUTTON_CLICKED,
  PERSONALIZATION_ID,
  USER_IS_LOGGING_IN,
} from '@foundation/constants/user'

export const preProcessLogonAndChangePasswordError = (error: any) => {
  if (error?.isAxiosError && error.response?.data?.errors && error.response.data.errors[0]) {
    return {
      ...error.response.data.errors[0],
      [EXPIRED_PASSWORD_PAGE_ERROR]: true,
    }
  } else {
    return {
      errorMessage: error.toLocaleString(),
      [EXPIRED_PASSWORD_PAGE_ERROR]: true,
    }
  }
}

export const clearUserState = (userState: any) => {
  for (const variableKey in userState) {
    if (
      variableKey !== PERSONALIZATION_ID &&
      variableKey !== INITIATED_FROM_STORAGE &&
      variableKey !== IS_RETURNING_CUSTOMER_BUTTON_CLICKED &&
      variableKey !== USER_IS_LOGGING_IN &&
      variableKey !== IS_REORDER_IN_3_CLICKS_BUTTON_CLICKED &&
      userState.hasOwnProperty(variableKey)
    ) {
      delete userState[variableKey]
    }
  }
}

export const shouldResetReorderIn3ClicksButtonClick = (
  pathname: string,
  isReorderIn3ClicksButtonClicked: boolean
): boolean => {
  const allowedRoutes = [
    CART,
    CHECKOUT_CHILDREN.FINALIZE,
    CHECKOUT_CHILDREN.ORDER_CONFIRMATION,
    ACCOUNT_CHILDREN.ORDER_HISTORY,
  ]
  const isPageNotAllowed = !allowedRoutes.some(route => pathname.includes(route))

  return isPageNotAllowed && isReorderIn3ClicksButtonClicked
}
