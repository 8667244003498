import { groupBy } from '@utils/helpers'
import { IOrderItem, Order, OrderItemWithRoxProps, RoxableAttributes } from './../types/order'
import { orderHasPrescriptionUploaded } from './order'
import {
  getAntiBlue,
  getBrand,
  getCorrectionType,
  getIsPolarized,
  getLensThickness,
  getLensTreatment,
  getLensesColor,
  getTransitionType,
} from './productAttributes'

export const getRxAttributes = (lensObject): RoxableAttributes => {
  return {
    correctionType: getCorrectionType(lensObject),
    lensBrand: getBrand(lensObject),
    lensType: getAntiBlue(lensObject) || getTransitionType(lensObject) || 'Clear',
    lensColor: getLensesColor(lensObject),
    lensThickness: getLensThickness(lensObject),
    lensTreatment: getLensTreatment(lensObject),
    polarized: getIsPolarized(lensObject) === 'True',
  }
}

export const isRxCart = (orderExtendAttribute: Order['orderExtendAttribute']): boolean => {
  return !!orderExtendAttribute?.find(a => a.attributeName === 'IS_ROX_ORDER' && a.attributeValue === 'true')
}

export const isRxProduct = (
  // @ts-expect-error
  orderItemExtendAttribute: Order['orderItemExtendAttribute']
): boolean => {
  return !!orderItemExtendAttribute?.find(a => {
    return a.attributeName === 'IsRoxLens' && a.attributeValue === 'true'
  })
}

export const isRox = (orderItemExtendAttribute: IOrderItem['orderItemExtendAttribute']): boolean => {
  try {
    return !!orderItemExtendAttribute?.find(a => a.attributeName === 'IsRox')
  } catch (e) {
    return false
  }
}

export const isRxFrame = (orderItemExtendAttribute: IOrderItem['orderItemExtendAttribute']): boolean => {
  try {
    return !!orderItemExtendAttribute?.find(a => a.attributeName === 'RxLensId')
  } catch (e) {
    return false
  }
}

export const isRxLens = (orderItemExtendAttribute: IOrderItem['orderItemExtendAttribute']): boolean => {
  try {
    return !!orderItemExtendAttribute?.find(a => a.attributeName === 'IsRoxLens')
  } catch (e) {
    return false
  }
}

export const getGroupedFrames = (orderItems: IOrderItem[]) => {
  return groupBy(orderItems || [], item => item.xitem_field1 || item.orderItemId)
}

export const parseRxOrderItems = (items: IOrderItem[]): IOrderItem[] | OrderItemWithRoxProps[] => {
  const groupedFrames = getGroupedFrames(items)
  const groupedFrameValues = Object.values(groupedFrames)

  return groupedFrameValues.map(framesArray => {
    if (framesArray.length > 1) {
      const lens = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find(a => a.attributeName === 'IsRoxLens')
      )

      const frame = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find(a => a.attributeName === 'RxLensId')
      )

      const roxableServices = framesArray.filter(item => item?.orderItemId !== frame?.orderItemId)
      const isPrescriptionUploadedForLens = roxableServices ? orderHasPrescriptionUploaded(roxableServices[0]) : false
      const roxableAttributes = getRxAttributes(lens)

      return {
        ...frame,
        prescriptionUploaded: isPrescriptionUploadedForLens,
        roxableAttributes,
        roxableServices,
        prescriptionDetails: lens?.prescriptionDetails || null,
      } as OrderItemWithRoxProps
    } else return framesArray[0]
  })
}

export const parseOrderItems = (items: IOrderItem[]): IOrderItem[] | OrderItemWithRoxProps[] => {
  const groupedFrames = getGroupedFrames(items)
  const groupedFrameValues = Object.values(groupedFrames)

  return groupedFrameValues.map(framesArray => {
    if (framesArray.length > 1) {
      const lens = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find(a => a.attributeName === 'IsRoxLens')
      )

      const frame = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find(a => a.attributeName === 'RxLensId')
      )

      const roxableServices = framesArray.filter(item => item?.orderItemId !== frame?.orderItemId)

      const roxableAttributes = getRxAttributes(lens)

      return {
        ...frame,
        roxableAttributes,
        roxableServices,
      } as OrderItemWithRoxProps
    } else return framesArray[0]
  })
}

export const getRxPrice = (rxServices: any[], rxFramePrice: string | undefined): number | undefined => {
  try {
    return (
      rxServices?.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue?.orderItemPrice || 0)
      }, 0) + parseFloat(rxFramePrice || '0')
    )
  } catch (e) {
    return undefined
  }
}

export const getRxLensPrice = (rxServices: OrderItemWithRoxProps['roxableServices']): number | undefined => {
  try {
    return rxServices?.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue?.orderItemPrice || 0)
    }, 0)
  } catch (e) {
    return undefined
  }
}

export const getRxLensDiscountedPrice = (rxServices: OrderItemWithRoxProps['roxableServices']): number | undefined => {
  try {
    const withDiscount = rxServices.filter(rx => rx.x_offerDiscountpriceRx)
    if (withDiscount.length === 0) return undefined
    return rxServices?.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue?.x_offerDiscountpriceRx || currentValue?.orderItemPrice || 0)
    }, 0)
  } catch (e) {
    return undefined
  }
}

export const getRxDiscountedPrice = (
  rxServices: OrderItemWithRoxProps['roxableServices'] | undefined,
  rxFramePrice: string | undefined
) => {
  try {
    if (!rxServices || !rxFramePrice) return undefined

    return (
      rxServices?.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue?.x_offerDiscountpriceRx || currentValue?.orderItemPrice || 0)
      }, 0) + parseFloat(rxFramePrice || '0')
    )
  } catch (e) {
    return undefined
  }
}
