import { TFunction } from 'next-i18next'
import { Order } from '@typesApp/order'
import { ORDER_RETURN_STATUS } from '@constants/order'
import { getOrderReturnedStatus } from './order'

import theme from '../themes/VisionDirect/light-theme'

function TranslatedOrderStatuses(t: TFunction) {
  return {
    M: t('Order.Status_.M'),
    Q: t('Order.Status_.Q'),
    L: t('Order.Status_.L'),
    H: t('Order.Status_.H'),
    D: t('Order.Status_.D'),
    R: t('Order.Status_.R'),
    X: t('Order.Status_.X'),
    '1': t('Order.Status_.1'),
    E: t('Order.Status_.E'),
  }
}

export const isActiveOrderStatus = (order: Order): boolean => {
  const { orderStatus: initOrderStatus } = order

  const returnedStatus = getOrderReturnedStatus(order)
  const orderStatus = returnedStatus || initOrderStatus
  const inactiveStatuses = [ORDER_RETURN_STATUS.ReturnComplete]

  return !inactiveStatuses.includes(orderStatus)
}

export interface StatusMap {
  color: string
  label: string
}

export const statusMap = (t: TFunction, status: string) => {
  let statusColor = theme.palette.success.main
  const statuses = TranslatedOrderStatuses(t)

  switch (status) {
    case 'E':
      // case 'H':
      statusColor = theme.palette.error.main
      break
  }

  return {
    color: statusColor,
    label: statuses[status],
  }
}
