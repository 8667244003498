import { createReducer, AnyAction } from '@reduxjs/toolkit'
//Redux
import initStates from './initStates'
import { ThemeReducerState } from './reducerStateInterface'
import { THEME_MODE_TOGGLE_ACTION } from '../actions/theme'

/**
 * theme reducer
 * handles theme state
 * @param state State object managed by theme reducer
 * @param action The dispatched action
 */
const uiReducer = createReducer(initStates.theme, (builder) => {
  builder.addCase(THEME_MODE_TOGGLE_ACTION, (state: ThemeReducerState, action: AnyAction) => {
    state.themeMode = action.payload
  })
})

export default uiReducer
