import { cmsDataInstance } from './cmsManager'
import { pageDataInstance } from './pageDataManager'
import { storeInfoDataInstance } from './storeInfoManager'
import { IStoreManagerSSR, StoreManagerLoadersTypeMap } from './types'

export type StoreManagerLoadersType<T extends keyof StoreManagerLoadersTypeMap> = {
  [K in T]: IStoreManagerSSR<StoreManagerLoadersTypeMap[K]['locale']>
}

export class StoreManagerLoaders<T extends keyof StoreManagerLoadersTypeMap> {
  private loaders = {} as StoreManagerLoadersType<T>

  register<K extends T>(key: K, loader: IStoreManagerSSR<StoreManagerLoadersTypeMap[K]['locale']>) {
    this.loaders[key] = loader
  }

  get<K extends T>(key: K): StoreManagerLoadersType<T>[K] {
    return this.loaders[key]
  }
}

export const storeManagerLoaders = new StoreManagerLoaders()
storeManagerLoaders.register('storeInfo', storeInfoDataInstance)
storeManagerLoaders.register('cms', cmsDataInstance)
storeManagerLoaders.register('pageData', pageDataInstance)
