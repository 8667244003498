export const TOP_CATEGORIES_DEPTH_LIMIT = '11,11'

export const PRODUCT_LIST_FIELDS = 'facetView'

export const KEYWORD_LIMIT = 4

export const PAGINATION_CONFIGS = {
  pageLimit: 18,
  pageDefaultOffset: 0, //The page offset to start with on pageload and filters
}

export const SEARCH_RESULTS_LIMIT = 20

export const FACETS_LIMIT = 20

export const MAX_ALGOLIA_HITS_FOR_ANALYTICS = 20
