const storeUtil = {
  isNumeric: (input: string) => isFinite(Number(input)),
}

export const isCanadaStore = (locale: string): boolean => {
  const CANADA_COUNTRY_CODE = 'ca'
  return locale.endsWith(`_${CANADA_COUNTRY_CODE}`)
}

export default storeUtil
