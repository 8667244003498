import { storageSessionHandler } from '@foundation/utils/storageUtil'
import CurrencyService from '@services/CurrencyService'

import { Redirect } from 'next/types'

/**
 * The returned function is executed only once and the result is
 * * @param {string}
 * * @returns {string}
 */
const executedList: Record<string, unknown> = {}
/**
 *
 */
export function executeOnce<Args extends unknown[], Return>(cb: (...args: Args) => Return, id: string) {
  return (...args: Args): Return => {
    const hasBeenExecuted = id in executedList
    if (hasBeenExecuted) return executedList[id] as Return

    const returnedValue = cb(...args)
    executedList[id] = returnedValue
    return returnedValue
  }
}

export const requiredValuesMissingInObject = (obj, values: string[]): boolean => {
  return Object.keys(obj).filter(key => values.includes(key) && !!obj[key]).length <= 0
}

export const notNull = <T>(x: T | null): x is T => x !== null
export const notUndefined = <T>(x: T | undefined): x is T => x !== undefined

// create function using localeCompare to compare strings
export const areEquals = (a: string | undefined, b: string | undefined): boolean => {
  if (!a || !b) return false
  return a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0
}

export const stringToBoolean = (s?: string | boolean): boolean => {
  if (!s) return false
  if (typeof s === 'boolean') return s
  return s.toLowerCase() === 'true'
}

export const booleanToString = (b: boolean): string => {
  return `${b}`
}

type RedirectTo = {
  destination: string
  permanent?: boolean
}

export type RedirectReturn = {
  redirect: Redirect
}
export const redirectTo = ({ destination, permanent = false }: RedirectTo): RedirectReturn => {
  return {
    redirect: {
      permanent,
      destination,
    },
  }
}

export const openNewTab = (url: string, fileName?: string): void => {
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('target', '_blank')
  link.setAttribute('rel', 'noopener noreferrer')
  link.style.visibility = 'hidden'
  fileName && (link.download = fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getHeadersWithToken = () => {
  const currentUserSession = storageSessionHandler.getCurrentUserAndLoadAccount()
  return {
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    WCToken: currentUserSession?.WCToken,
    WCTrustedToken: currentUserSession?.WCTrustedToken,
  }
}

export const numberToMoney = (
  price: number | string | null,
  currency: string,
  defaultCurrency: string | null
): string | null => {
  // TODO: Replace with actual currency function when its done and merged
  if (!price) {
    return null
  }
  const currencySymbol = CurrencyService.getSymbolByName(currency)

  return currency === defaultCurrency
    ? `${Number(price).toFixed(2).replace('.', ',')} ${currencySymbol}`
    : `${currencySymbol} ${Number(price).toFixed(2)}`
}
