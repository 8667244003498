import { TmpCookiesObj } from 'cookies-next/lib/types'
import React, { useContext, createContext, useReducer, Dispatch } from 'react'

type PageState = {
  cookies: Record<string, string> | TmpCookiesObj
}

type PageAction = {
  type: 'SET_COOKIES'
  payload: Record<string, string>
}

function pageReducer(state: PageState): PageState {
  return state
}

const PageContext = createContext<[PageState, Dispatch<PageAction>] | undefined>(undefined)

// allows passing ssr initial state to page components
function PageProvider({
  children,
  initialCookies,
}: {
  children: React.ReactNode
  initialCookies: Record<string, string> | TmpCookiesObj
}) {
  const [state, dispatch] = useReducer(pageReducer, { cookies: initialCookies })

  return <PageContext.Provider value={[state, dispatch]}>{children}</PageContext.Provider>
}

function usePageState(): [PageState, Dispatch<PageAction>] {
  const context = useContext(PageContext)
  if (!context) {
    throw new Error('usePageState must be used within a PageProvider')
  }
  return context
}

export { PageProvider, usePageState }
