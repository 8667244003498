import userContextService from '@foundation/apis/transaction/userContext.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { LoginIdentityResponse } from '@typesApp/account'
import { IPayloadBasePromoCode } from '@typesApp/common'
import jsonBig from 'json-bigint'
import Log from '../../../services/Log'
import { IContextSliceState } from '../IContextSliceState'
import { evaluateIsPartiallyAuthenticated } from '@utils/user'

export type IFetchUserContextArgs = IPayloadBasePromoCode | LoginIdentityResponse | undefined

export const fetchUserContext = createAsyncThunk<IContextSliceState, IFetchUserContextArgs>(
  'context/fetchUserContext',
  async (args, { rejectWithValue }) => {
    try {
      const response = await userContextService.get(args)
      const userPayload = jsonBig.parse(response?.request.response || '{}')
      userPayload.entitlement.currentTradingAgreementIds[0] =
        userPayload.entitlement.currentTradingAgreementIds[0].toString()

      return { ...userPayload, isPartiallyAuthenticated: evaluateIsPartiallyAuthenticated(userPayload) }
    } catch (e) {
      Log.error('Error in retrieving context' + e)
      return rejectWithValue(e)
    }
  }
)
