import { StateManagerSSR } from './stateManager'
import { StoreManagerLoadersTypeMap } from './types'

export class StoreInfoData extends StateManagerSSR<StoreManagerLoadersTypeMap['storeInfo']['locale']> {
  private static instance: StoreInfoData | null = null

  constructor() {
    super('siteInfo')
  }

  public static Instance = (): StoreInfoData => {
    if (!this.instance) {
      this.instance = new StoreInfoData()
    }
    return this.instance
  }
}

export const storeInfoDataInstance = StoreInfoData.Instance()
