import config from '@configs/index'
import { isEmpty } from '@utils/helpers'

export interface Helper {
  getLang: (locale?: string | null) => string
  getCountry: (country?: string | null) => string
  generateQuery: (args: Args) => string
  storeName: string
}

export type Args = Record<string, string | string[] | undefined | null>

export const helper: Helper = {
  storeName: config.name?.toLowerCase() as string,
  getLang: (locale?: string | null) => {
    return locale?.split('-')[0] || 'en-gb'
  },
  getCountry: (country?: string | null) => {
    if (!country) return 'gb'
    return country?.toLowerCase() !== 'uk' ? country?.toLowerCase() : 'gb'
  },
  generateQuery: (args: Args): string => {
    if (!args || isEmpty(args)) return ''
    const rawQuery = Object.keys(args).reduce<string>((acc, item) => {
      if (!args[item]) {
        return acc
      }
      const argsArr = args[item]
      if (Array.isArray(argsArr)) {
        let query = ''
        if (item.toLowerCase() === 'breadcrumb') {
          query = item + '=' + argsArr.map(el => `${el}`).join(',')
        } else {
          query = argsArr.map(el => `${item}=${el}`).join('&')
        }
        return `${acc}&${query}`
      }

      return `${acc}&${item}=${args[item]}`
    }, '')
    const query = rawQuery[0] === '&' ? rawQuery.substring(1) : rawQuery
    return query
  },
}
