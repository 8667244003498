import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IOrganizationSliceState } from './IOrganizationSliceState'
import { organizationInitState as initialState } from './initState'
import { fetchEntitledOrganization } from './thunks/fetchEntitledOrganization'

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchEntitledOrganization.fulfilled, (state, action: PayloadAction<IOrganizationSliceState>) => {
      Object.assign(state, action.payload)
    })
  },
})

export default organizationSlice.reducer
