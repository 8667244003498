import { createAsyncThunk } from '@reduxjs/toolkit'
import { CancelToken } from 'axios'
import { ORDER_CONFIGS } from '../../../configs/order'
import cartService from '../../../foundation/apis/transaction/cart.service'
import orderService from '../../../foundation/apis/transaction/order.service'

export interface IFinalizeOrderArgs {
  widget?: string
  cancelToken?: CancelToken
  currency?: string
  contractId?: string
  responseFormat?: string
  body?: any

  orderId: string
  ppExpress: boolean
}

const finalizeOrder = createAsyncThunk<any, IFinalizeOrderArgs>(
  'order/finalizeOrder',
  async (args, { rejectWithValue }) => {
    const { ppExpress } = args

    let ppExpressStatus

    if (ppExpress) {
      ppExpressStatus = await orderService.paypalExpressCheckStatus(args)
    }

    if ((ppExpress && ppExpressStatus?.data.decision === 'ACCEPT') || !ppExpress) {
      await cartService.preCheckout(args)
      await cartService.checkOut(args)

      const { body, contractId, currency, orderId, ppExpress, responseFormat, widget } = args

      return await orderService.findByOrderId({
        body,
        queryParams: {
          contractId,
          currency,
          ppExpress,
          responseFormat,
          sortOrderItemBy: ORDER_CONFIGS.sortOrderItemBy,
          widget,
        },
        pathParams: { orderId },
      })
    }
    return rejectWithValue('unknown error')
  }
)

export default finalizeOrder
