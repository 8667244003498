import { ORDER_STATUS } from '@constants/order'
import { AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IOrderDetails } from '@typesApp/order'
import { orderDetailsInitState as initialState } from './initState'
import { fetchOrderDetails } from './thunks/fetchOrderDetails'

const orderDetailsSlice = createSlice({
  name: 'orderDetails',
  initialState,
  reducers: {
    setOrderDetailsPending: (state, action: PayloadAction<string | string[]>) => {
      const { payload } = action
      if (typeof payload === 'string' && !state.loadings.includes(payload)) {
        state.loadings = state.loadings.concat(payload)
      } else if (Array.isArray(payload)) {
        state.loadings = payload
      }
    },
    resetOrderDetails: () => {
      return initialState
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchOrderDetails.fulfilled, (state, action: PayloadAction<IOrderDetails>) => {
      const orderDetails = action.payload
      const { orderId } = orderDetails
      state.data[orderId] = orderDetails
      state.data[orderId].orderComplete = orderDetails.orderStatus === ORDER_STATUS.Created
      state.loadings = state.loadings.filter(id => id !== orderId)
    })
    builder.addCase(fetchOrderDetails.rejected, (state, action: AnyAction) => {
      const orderId = action.payload
      state.data[orderId] = action.error
      state.loadings = state.loadings.filter(id => id !== orderId)
    })
  },
})

export const { setOrderDetailsPending, resetOrderDetails } = orderDetailsSlice.actions

export default orderDetailsSlice.reducer
