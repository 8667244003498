import account, { IAccountState } from '../../features/account'
import { cmsApi } from '../../features/cms/query'
import frameGenius, { IStateFrameGenuis } from '../../features/frameGenius/slice'
import { orderApi } from '../../features/order/query'
import plp, { PlpStateInterface } from '../../features/plp/slice'
import preview, { PreviewState } from '../../features/preview/slice'
import { productApi } from '../../features/product/query'
import product, { ProductApiState, ProductState } from '../../features/product/slice'
import seo, { SeoStateInterface } from '../../features/seo/slice'
import stores, { StoresState } from '../../features/stores/slice'
import success, { ISuccessSliceState } from '../../features/success/slice'
import ui, { IUiState } from '../../features/ui/slice'
import wishList, { WishListState } from '../../features/wishList/slice'
import {
  ApiReducerState,
  ConfirmationReducerState,
  IdMeReducerState,
  ProductTypeFiltersState,
  SearchReducerState,
  SiteReducerState,
  ThemeReducerState,
} from './reducerStateInterface'

import { IOrderSliceState } from '../../features/order/IOrderSliceState'

//Standard libraries
import { accountApi } from '@features/account/query'
import { bffProductApi } from '@features/bffProduct/query'
import { IContextSliceState } from '@features/context/IContextSliceState'
import { IContractSliceState } from '@features/contract/IContractSliceState'
import { IErrorSliceState } from '@features/error/IErrorSliceState'
import error from '@features/error/slice'
import { idMeApi } from '@features/idMe/query'
import { IdealApi } from '@features/payments/ideal/query'
import { IOrderDetailsSliceState } from '@features/orderDetails/IOrderDetailsSliceState'
import { orderReminderApi } from '@features/orderReminder/query'
import { orderReturnApi } from '@features/orderReturn/query'
import { OrderReturnState, orderReturnReducer } from '@features/orderReturn/slice'
import { IOrganizationSliceState } from '@features/organization/IOrganizationSliceState'
import { prescriptionApi } from '@features/prescription/query'
import { IUserSliceState } from '@features/user/IUserSliceState'
import { fiscalCodeApi } from '@features/user/query'
import { vmApi } from '@features/virtualMirror/query'
import { combineReducers } from 'redux'
import { categoryApi } from '../../features/category/query'
import cms, { ICmsState } from '../../features/cms/slice'
import context from '../../features/context/slice'
import contract from '../../features/contract/slice'
import order, { OrderApiState } from '../../features/order/slice'
import orderDetails from '../../features/orderDetails/slice'
import organization from '../../features/organization/slice'
import { plpApi } from '../../features/plp/query'
import { PrescriptionSliceState } from '../../features/prescription/PrescriptionSliceState'
import prescription from '../../features/prescription/slice'
import { tabApi } from '../../features/stores/query'
import user from '../../features/user/slice'
import api from './api'
import confirmation from './confirmation'
import forterWebId from './forterWebId'
import search from './search'
import site from './site'
import theme from './theme'
import checkoutReducer, { CheckoutState } from '@redux/slices/checkout'
import reminderReducer, { ReminderState } from '@redux/slices/reminder'
import payments, { PaymentsState } from '@features/payments/slice'
import { IBancontactInitialState } from '@features/bancontact/types'
import bancontact from '@features/bancontact/slice'
import { bancontactApi } from '@features/bancontact/query'

export * from './reducerStateInterface'

export interface RootReducerState {
  account: IAccountState
  api: ApiReducerState
  user: IUserSliceState
  checkout: CheckoutState
  reminder: ReminderState
  order: IOrderSliceState
  orderApi: OrderApiState
  error: IErrorSliceState
  seo: SeoStateInterface
  contract: IContractSliceState
  search: SearchReducerState
  organization: IOrganizationSliceState
  context: IContextSliceState
  success: ISuccessSliceState
  confirmation: ConfirmationReducerState
  orderDetails: IOrderDetailsSliceState
  orderDetailsLoadings: { [orderId: string]: boolean }
  theme: ThemeReducerState
  site: SiteReducerState
  wishList: WishListState
  preview: PreviewState
  product: ProductState
  cms: ICmsState
  productApi: ProductApiState
  plp: PlpStateInterface
  ui: IUiState
  prescription: PrescriptionSliceState
  stores: StoresState
  productTypeFilters: ProductTypeFiltersState
  forterWebId: { value: string }
  frameGenius: IStateFrameGenuis
  orderReturn: OrderReturnState
  idMe: IdMeReducerState
  payments: PaymentsState
  bancontact: IBancontactInitialState
  bancontactApi: any
}

const reducers = {
  account,
  api,
  checkout: checkoutReducer,
  reminder: reminderReducer,
  user,
  order,
  error,
  contract,
  search,
  context,
  organization,
  success,
  confirmation,
  orderDetails,
  theme,
  site,
  wishList,
  preview,
  [productApi.reducerPath]: productApi.reducer,
  [bffProductApi.reducerPath]: bffProductApi.reducer,
  [vmApi.reducerPath]: vmApi.reducer,
  product,
  [cmsApi.reducerPath]: cmsApi.reducer,
  seo,
  [orderApi.reducerPath]: orderApi.reducer,
  [plpApi.reducerPath]: plpApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [tabApi.reducerPath]: tabApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [prescriptionApi.reducerPath]: prescriptionApi.reducer,
  [fiscalCodeApi.reducerPath]: fiscalCodeApi.reducer,
  plp,
  ui,
  frameGenius,
  cms,
  prescription,
  stores,
  forterWebId,
  orderReturn: orderReturnReducer,
  [orderReturnApi.reducerPath]: orderReturnApi.reducer,
  [orderReminderApi.reducerPath]: orderReminderApi.reducer,
  [idMeApi.reducerPath]: idMeApi.reducer,
  [IdealApi.reducerPath]: IdealApi.reducer,
  payments,
  bancontact,
  [bancontactApi.reducerPath]: bancontactApi.reducer,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
