import Typography, { TypographyProps } from '@mui/material/Typography'
import React from 'react'
import styled from '@mui/material/styles/styled'

export interface StyledTypographyCustomProps extends TypographyProps {
  fontColor?: string
  fontSize?: number
  fontWeight?: string
  isUnderlined?: boolean
  isUppercased?: boolean
  lineHeight?: string
  margin?: string
  component?: string
}

export const StyledTypography = styled((props: StyledTypographyCustomProps) => <Typography {...props} />, {
  shouldForwardProp: (prop) => prop !== 'fontColor' && prop !== 'isUppercased',
})(({ fontColor, fontSize, fontWeight, isUnderlined, isUppercased, lineHeight, margin }) => ({
  color: fontColor ? fontColor : '',
  fontSize,
  fontWeight,
  textTransform: isUppercased ? 'uppercase' : 'none',
  textDecoration: isUnderlined ? 'underline' : 'none',
  lineHeight,
  margin,
}))
