//Standard libraries
import { configureStore } from '@reduxjs/toolkit'
//Redux
import { bffProductApi } from '@features/bffProduct/query'
import { idMeApi } from '@features/idMe/query'
import { orderReminderApi } from '@features/orderReminder/query'
import { orderReturnApi } from '@features/orderReturn/query'
import { prescriptionApi } from '@features/prescription/query'
import { fiscalCodeApi } from '@features/user/query'
import { vmApi } from '@features/virtualMirror/query'
import { createWrapper } from 'next-redux-wrapper'
import { accountApi } from '../../features/account/query'
import { categoryApi } from '../../features/category/query'
import { cmsApi } from '../../features/cms/query'
import { orderApi } from '../../features/order/query'
import { plpApi } from '../../features/plp/query'
import { productApi } from '../../features/product/query'
import { tabApi } from '../../features/stores/query'
import { IdealApi } from '@features/payments/ideal/query'
import { loadState, persistReducers } from '../../localStorage'
import { bancontactApi } from '@features/bancontact/query'
import rootReducer from '../reducers/index'

const persistedState = loadState()

const makeStore = () => {
  const s = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: true,
        serializableCheck: false,
        thunk: true,
      }).concat(
        productApi.middleware,
        bffProductApi.middleware,
        cmsApi.middleware,
        categoryApi.middleware,
        plpApi.middleware,
        fiscalCodeApi.middleware,
        orderApi.middleware,
        accountApi.middleware,
        tabApi.middleware,
        vmApi.middleware,
        orderReturnApi.middleware,
        orderReminderApi.middleware,
        prescriptionApi.middleware,
        idMeApi.middleware,
        IdealApi.middleware,
        bancontactApi.middleware
      ),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: persistedState,
    enhancers: [],
  })
  return s
}

export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = AppStore['dispatch']

export const initialStore = makeStore()
const wrapper = createWrapper<AppStore>(() => initialStore, { debug: false })
persistReducers(initialStore)

export default wrapper
