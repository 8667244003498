import { IdMeReducerState } from '@redux/reducers'
import { createApi } from '@reduxjs/toolkit/query/react'
import { IdMePayload } from '@typesApp/idMe'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'

export const idMeApi = createApi({
  reducerPath: 'idMeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: build => ({
    getIdMePromoCode: build.query<IdMeReducerState, IdMePayload>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, catalogId, langId, orderId, accessToken } = args
        const result = await fetchWithBQ({
          url: 'wcs/resources/store/{storeId}/idme/returntocart',
          method: 'GET',
          body: {},
          queryParams: {
            storeId,
            catalogId,
            langId,
            orderId,
            access_token: accessToken,
          },
        })
        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
  }),
})
export const { useGetIdMePromoCodeQuery, useLazyGetIdMePromoCodeQuery } = idMeApi
