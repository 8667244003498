import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IContextSliceState } from './IContextSliceState'
import { contextInitState as initialState } from './initState'
import { fetchUserContext } from './thunks/fetchUserContext'

const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchUserContext.fulfilled, (state, action: PayloadAction<IContextSliceState>) => {
      Object.assign(state, action.payload)
    })
  },
})

export default contextSlice.reducer
