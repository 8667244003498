const info = {
  default: '#00A7E6',
  0: '#000000',
  10: '#001E2D',
  20: '#00344B',
  30: '#004C6B',
  40: '#00658D',
  50: '#007FB0',
  60: '#009BD5',
  70: '#2EB6F6',
  80: '#82CFFF',
  90: '#C6E7FF',
  95: '#E4F3FF',
  99: '#FBFCFF',
  100: '#FFFFFF',
}

export default info
