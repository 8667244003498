import { IOrderDetails } from '@typesApp/order'
import { Attachment } from '@typesApp/product'

enum Eye {
  LCON = 'LCON',
  RCON = 'RCON',
}

export interface BasketTotal {
  rawTotal: number
  formattedTotal: string
  displayTotal: string
}

export interface ShippingThreshold {
  threshold: BasketTotal & {
    reached: boolean
    remainingSpend: BasketTotal
  }
}

export interface ShippingBasketTotal extends BasketTotal, ShippingThreshold {
  isFree: boolean
}

export interface PromoCode {
  promotionId: string | null
  hasPromo: boolean
  code: string | null
  amount: BasketTotal
}

export interface BasketItemPrescription {
  x_productId?: string
  x_baseCurve?: string
  x_spherePower?: string
  x_addition?: string
  x_partNumber?: string
  x_eye: Eye
  x_dominance?: string
  x_diameter?: string
  x_color?: string
}

export interface BasketItem {
  id: string
  images: Attachment[]
  name: string
  brand: string
  type: string
  link?: string
  quantity: {
    unit: number
    total: number
    packSize?: number
  }
  prices: {
    unit: BasketTotal
    total: BasketTotal
  }
  prescription?: BasketItemPrescription
}

export interface BasketTermFormat {
  text: string
  display: string
}

export interface BasketDropdownOption {
  label: string
  value: number
}

export interface OrderObjectOptions {
  orderDetails?: IOrderDetails | null
}

export enum AdjustmentTypes {
  Discount = 'Discount',
  ShippingAdjustment = 'Shipping Adjustment',
}

export enum AdjustmentDisplayLevel {
  OrderItem = 'OrderItem',
  Order = 'Order',
}

export interface ProductTermDetails {
  singular: string
  plural: string
}
export interface ProductCategoryTerms {
  Container: ProductTermDetails
  Item?: ProductTermDetails
}

export type ProductTerms = {
  [category: string]: ProductCategoryTerms
}

export interface BasketItemQuantity {
  unit: number
  total: number
  packSize?: number
  pricePerBox: number | null
}

export interface BasketTermFormat {
  text: string
  display: string
}

export interface BasketTerms {
  singular: BasketTermFormat
  plural: BasketTermFormat
}
export interface BasketItemTerms {
  container: BasketTerms
  item?: BasketTerms
}

export interface BasketItemPrescription {
  x_productId?: string
  x_baseCurve?: string
  x_spherePower?: string
  x_addition?: string
  x_partNumber?: string
  x_eye: Eye
  x_dominance?: string
  x_diameter?: string
  x_color?: string
  x_cylinder?: string
  x_axis?: string
}
