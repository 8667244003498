import productsService from '@foundation/apis/search/products.service'
import orderService from '@foundation/apis/transaction/order.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IOrderDetails, IOrderItem } from '@typesApp/order'
import { IProduct } from '@typesApp/product'
import { chunk } from '@utils/helpers'
import { setOrderDetailsPending } from '../slice'

export type IFetchOrderDetailsArgs = {
  orderId: string
  currency?: string
  contractId?: string
  skipErrorSnackbar?: boolean
  widget?: string
  storeId?: string
}

export const fetchOrderDetails = createAsyncThunk<IOrderDetails, IFetchOrderDetailsArgs>(
  'orderDetails/fetchOrderDetails',
  async (args, { rejectWithValue, dispatch }) => {
    const { orderId, currency, contractId, skipErrorSnackbar, widget, storeId } = args
    try {
      dispatch(setOrderDetailsPending(orderId))

      const params = {
        queryParams: { currency, contractId, skipErrorSnackbar, widget },
        pathParams: { orderId, storeId },
      }
      let orderDetails: IOrderDetails = await orderService.findByOrderId(params)

      let orderItemDetails: IProduct[] = []

      const orderItems: IOrderItem[] = orderDetails.orderItem
      const id = [...new Set(orderItems.map(i => i.xitem_display_catentry_id))]
      const itemDetailsParams = {
        id,
        currency,
        contractId,
        orderId,
      }
      orderItemDetails = await fetchOrderItemDetailsByIds(itemDetailsParams)

      if (orderItemDetails.length && orderItemDetails.length > 0) {
        const detailedOrderItems = orderDetails.orderItem.map(item => {
          const obj = {
            ...item,
            name: '',
            thumbnail: '',
            attributes: [],
            seo: { href: '' },
          }
          const filteredItem =
            (orderItemDetails as any[]).filter(i => String(i.id) === String(item.xitem_display_catentry_id))[0] || {}
          const { id, name, thumbnail, attributes, seo, attachments } = filteredItem

          Object.assign(obj, { id, name, thumbnail, attributes, seo, attachments })
          return obj
        })
        Object.assign(orderDetails, { detailedOrderItems })
      }

      return orderDetails
    } catch (error) {
      return rejectWithValue(orderId)
    }
  }
)

export const fetchOrderItemDetailsByIds = (param: any) => {
  const promiseArray: Promise<any>[] = []
  const { currency, id, widget, profileName, storeId } = param
  const paramBase = { currency, widget, profileName, storeId }
  const ids = chunk(id, 50)
  ids.forEach(id => {
    const param = Object.assign({}, paramBase, { id })
    promiseArray.push(
      productsService.findProductsUsingGET({
        ...param,
        profileName: 'LX_findItemByIds_Details',
      })
    )
  })
  return Promise.all(promiseArray).then(rs => {
    let contents = []
    rs.forEach(r => {
      if (r.data?.contents) {
        contents = contents.concat(r.data.contents)
      }
    })
    return contents
  })
}
