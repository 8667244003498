import { StyledAnchor } from '@components/UI/Anchor'
import { Flexbox } from '@components/UI/Flexbox'
import { StyledTypography } from '@components/UI/Typography'
import { TableCell } from '@pages_views/OrderHistory/components/OrderHistoryList'
import theme from '@themes/index'
import { TFunction } from 'next-i18next'
import { IOrderSliceState } from '../features/order/IOrderSliceState'
import orderService from '../foundation/apis/transaction/order.service'
import DateService from '../services/DateService'
import { Attribute, Order, OrderItemsAttachments } from '../types/order'
import { ACCOUNT, ACCOUNT_CHILDREN } from './routes'

export const INVENTORY_STATUS = {
  available: 'Available',
  allocated: 'Allocated',
  backordered: 'Backordered',
  unallocated: 'Unallocated',
}

export const RECURRING_ORDER_OPTIONS = [
  {
    key: 'EVERYDAY',
    value: '0',
    fulfillmentInterval: '1',
    fulfillmentIntervalUOM: 'DAY',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.Everyday',
  },
  {
    key: 'EVERYWEEK',
    value: '1',
    fulfillmentInterval: '1',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryWeek',
  },
  {
    key: 'EVERYTWOWEEKS',
    value: '2',
    fulfillmentInterval: '2',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryTwoWeeks',
  },
  {
    key: 'EVERYTHREEWEEKS',
    value: '3',
    fulfillmentInterval: '3',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryThreeWeeks',
  },
  {
    key: 'EVERYFOURWEEKS',
    value: '4',
    fulfillmentInterval: '4',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryFourWeeks',
  },
]

export const SHIPMODE = {
  shipModeCode: {
    PickUp: 'PickupInStore',
  },
}

export const EXPIRY = {
  MONTHS: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
  YEARS: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
}

export const PO_NUMBER = 'poNumber'
export const TAXES_ZIP_CODE = 'TAXES_ZIP_CODE'
export const ESTIMATED_TAXES = 'ESTIMATED_TAXES'

export const ORDER_STATUS = {
  Created: 'M',
  Late: 'L',
  Error: 'H',
  Settled: 'D',
  Confirmed: 'R',
  Cancelled: 'X',
  Delivered: '1',
  PendingPrescription: 'E',
  PendingPayment: 'Q',
}

export const ORDER_RETURN_STATUS = {
  ReturnCreated: 'APP',
  ReturnReceived: 'CRE',
  ReturnComplete: 'CLO',
  ReturnCancelled: 'CAN',
  ReturnPending: 'PND',
  ReturnDelivered: 'SNT',
}

export const ORDER_ITEM_STATUS = {
  Created_SAPSent: 'G',
  Created_Error: 'F',
  Created_M: 'M',
  Created_F: 'F',
  PendingPrescription_V: 'V',
  PendingPrescription_ToVerified: 'W',
  Cancelled_X: 'X',
  Cancelled_PrescriptionRejected: 'N',
  InProgress_Confirmed: 'R',
  InProgress_Late: 'L',
  Shipped_InTransit: 'S',
  Shipped_Billed: 'O',
  Shipped_Settled: 'D',
  Shipped_FailedStatement: 'Z',
  Delivered: '1',
  PendingPayment: 'Q',
}

export const PENDING_PRESCRIPTION_STATUSES = [ORDER_ITEM_STATUS.PendingPrescription_V, ORDER_STATUS.PendingPrescription]

export const SHIPPED_STATUSES = [
  ORDER_ITEM_STATUS.Shipped_Billed,
  ORDER_ITEM_STATUS.Shipped_FailedStatement,
  ORDER_ITEM_STATUS.Shipped_Settled,
  ORDER_ITEM_STATUS.Shipped_InTransit,
]

export const COMPLETE_ORDER_STATUSES = [ORDER_ITEM_STATUS.Delivered, ORDER_RETURN_STATUS.ReturnDelivered]

export const REORDER_ENABLED_STATUSES = [
  ...Object.values(ORDER_RETURN_STATUS),
  ORDER_STATUS.Settled,
  ORDER_STATUS.Delivered,
]

const RMA_ORDER_STATUS_COLOR = theme.palette.color.grey.main

export const ORDER_EXTEND_ATTRIBUTE_NAMES = {
  INVOICE_PROCESSED: 'INVOICE_PROCESSED',
  INVOICE_URLS: 'xinvoiceUrls',
  IS_MIX_ORDER: 'IS_MIX_ORDER', // 'true' | 'false'
  IS_ROX_ORDER: 'IS_ROX_ORDER', // 'true' | 'false'
  IS_CONTACT_LENS: 'isContactLens', // 'true' | 'false'
  HAS_CONTACT_LENS: 'hasContactLens', // 'true' | 'false'
  LANG_ID: 'LanguageId',
  RMA_ITEMS_COMPLETED: 'numberOfRmaItemsCompleted',
  RMA_ITEMS_IN_PROGRESS: 'numberOfRmaItemsInProgress',
  RMA_STATUS: 'RMAStatus',
  TAX_SETTLEMENT: 'TaxSettlement',
  TAX_SYSTEM: 'TaxSystem',
  TRACK_NUMBER: 'track_number',
  X_CONTACT_LENS: 'x_contactLens',
  RX_FILE_UPLOAD_DATE: 'rxfileUploadDate',
  ORDER_REMINDER_DATE: 'reminderDate',
} as const

export interface TaxValues {
  provincial: number
  federal: number
}

export const ORDER_ITEM_EXTEND_ATTRIBUTE_FILTER = {
  PROVINCIAL_TAX: (orderId: string, attribute: Attribute<string>): boolean => {
    const regex = new RegExp(`${orderId}:TAX_RATE:.@PROVINCE@`, 'i')
    return regex.test(attribute.attributeName)
  },
  FEDERAL_TAX: (orderId: string, attribute: Attribute<string>): boolean => {
    const regex = new RegExp(`${orderId}:TAX_RATE:.@COUNTRY@`, 'i')
    return regex.test(attribute.attributeName)
  },
}

export const ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES = {
  RMA_STATUS: 'RMA',
  EXPECTED_DELIVERY_DATE: 'X_ESTIMATED_DELIVERY_DATE',
} as const

const orderItemExtendAttributeNameGetter = (orderId: string, name: string) => `${orderId}:${name}`

export const ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS = {
  TRACK_NUMBER: (orderId: string) => orderItemExtendAttributeNameGetter(orderId, 'TrackNumber'),
  FULFILLMENT_STATUS: (orderId: string) => orderItemExtendAttributeNameGetter(orderId, 'FulfillmentStatus'),
  IS_CONTACT_LENS: (orderId: string) => orderItemExtendAttributeNameGetter(orderId, 'isContactLens'),
  X_CONTACT_LENS: (orderId: string) => orderItemExtendAttributeNameGetter(orderId, 'x_contactLens'),
}

export const NO_CCARD_INFO_NEEDED_PAYMENTS: string[] = ['paypal', 'applepay', 'cod', 'CyberSourceKlarna']

export const CCARD_INFO_PAYMENTS: string[] = ['Visa', 'American Express', 'Master Card']

export const getOrderItemsAttachments = (
  orderItems: IOrderSliceState['orderItems'],
  catentries: IOrderSliceState['catentries']
) => {
  if (!Array.isArray(orderItems) || !catentries) {
    return {}
  }

  const orderItemsImages = {} as OrderItemsAttachments

  for (const orderItem of orderItems) {
    const { productId } = orderItem
    const attachments = catentries[productId]?.attachments

    orderItemsImages[productId] = attachments || []
  }

  return orderItemsImages
}

export const buildOrderHistoryHeaders = (t: TFunction): string[] => [
  t('Order.OrderNumber'),
  t('Order.OrderDate'),
  t('Order.Status'),
  t('Order.TrackingNumber'),
  t('Order.TotalPrice'),
  t('Order.Actions'),
]

export const buildOrderHistoryCells = ({
  isDesktop,
  langCode,
  t,
}: {
  isDesktop: boolean
  langCode: string
  t: TFunction
}): TableCell<Order>[] => [
  {
    key: 'orderId',
    widthPercent: 19,
    render: ({ orderId }) => (
      <Flexbox align-items="flex-start" justify-content={isDesktop ? 'initial' : 'space-between'} gap="10px" flex="1">
        <span>{orderId}</span>
        <StyledAnchor href={`/${ACCOUNT}/${ACCOUNT_CHILDREN.ORDER_HISTORY}?orderId=${orderId}`}>
          <>{t('Order.HistoryViewDetailTooltip')}</>
        </StyledAnchor>
      </Flexbox>
    ),
  },
  {
    key: 'placedDate',
    widthPercent: 18,
    render: ({ placedDate }) => <span>{DateService.format(placedDate || '')}</span>,
  },
  {
    key: 'orderStatus',
    widthPercent: 13,
    render: ({ orderExtendAttribute = [], orderStatus }) => {
      if (!(orderStatus && typeof orderStatus === 'string')) {
        return ''
      }

      const orderReturnStatus = orderExtendAttribute.find(a =>
        a.attributeName.startsWith(ORDER_EXTEND_ATTRIBUTE_NAMES.RMA_STATUS)
      )?.attributeValue
      const isOrderReturnStatusUsable = Object.values(ORDER_RETURN_STATUS).includes(orderReturnStatus || '')
      let itemsRequestedForReturn = 0
      let itemsReturned = 0

      if (isOrderReturnStatusUsable) {
        itemsRequestedForReturn =
          Number(
            orderExtendAttribute.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.RMA_ITEMS_IN_PROGRESS)
              ?.attributeValue
          ) || 0
        itemsReturned =
          Number(
            orderExtendAttribute.find(a => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.RMA_ITEMS_COMPLETED)
              ?.attributeValue
          ) || 0
      }

      // order status text should be hidden on mobile if there are items returned/for return
      const isOrderStatusHidden = !isDesktop && isOrderReturnStatusUsable && (itemsRequestedForReturn || itemsReturned)

      return (
        <Flexbox flex-direction={isDesktop ? 'column' : 'row'}>
          {!isOrderStatusHidden && (
            <StyledTypography variant="caption" fontColor={orderStatus === 'E' ? '#9b0800' : undefined}>
              <>{t(`Order.Status_.${orderStatus}`)}</>
            </StyledTypography>
          )}
          {!!itemsReturned && (
            <StyledTypography fontColor={RMA_ORDER_STATUS_COLOR} variant="caption">
              <>
                {t(
                  orderReturnStatus === ORDER_RETURN_STATUS.ReturnCancelled
                    ? 'Order.ItemsReturnCancelled'
                    : 'Order.ItemsReturned',
                  { count: itemsReturned }
                )}
              </>
            </StyledTypography>
          )}
          {!!itemsRequestedForReturn && (
            <StyledTypography fontColor={RMA_ORDER_STATUS_COLOR} variant="caption">
              {!isDesktop && !!itemsReturned && ', '}
              <>
                {t('Order.ItemsRequestedForReturn', {
                  count: itemsRequestedForReturn,
                })}
              </>
            </StyledTypography>
          )}
        </Flexbox>
      )
    },
  },
  {
    key: 'x_trackingIds',
    widthPercent: 23,
    render: ({ orderExtendAttribute = [] }) => {
      const trackingNumbers = orderService.getTrackingIds(orderExtendAttribute)

      return trackingNumbers?.length ? (
        <Flexbox flex-direction="column">
          {trackingNumbers.map(trackingNumber => (
            <StyledTypography key={trackingNumber} component="p" lineHeight="18px" margin="0" variant="caption">
              {trackingNumber}
            </StyledTypography>
          ))}
        </Flexbox>
      ) : null
    },
  },
  {
    key: 'action',
    widthPercent: 9,
    render: ({ orderExtendAttribute = [] }) => {
      const invoiceUrl = orderService.getInvoiceUrl(orderExtendAttribute)

      return !!invoiceUrl ? (
        <StyledAnchor href={invoiceUrl}>
          <>{t('OrderDetails.Actions.Invoice')}</>
        </StyledAnchor>
      ) : null
    },
  },
]

export const PRODUCT_COUNT_IN_CART_LIMIT = 10 // TODO move this in a configuration

export const RX_PRODUCTS_IN_CART_LIMIT = 999999 // TODO move this in a configuration

export const RETURN_REASONS_CODE_PREFIX = 'RET'
export const RETURN_ITEM_TYPE = 'REF'

export const INSURANCE_DISCOUNT_ATTRIBUTE_NAME: string = 'totalInsuranceAdjustment'
export const INSURANCE_NAME_ATTRIBUTE_NAME: string = 'insurerName'
export const ORDER_TOTAL_THRESHOLD_REACHED = '_ORDER_TOTAL_THRESHOLD_REACHED'
