import { shallowEqual, useSelector } from 'react-redux'

import { DEFAULT_LANG_ID, LANGUAGE_CODE_MAP } from '../../constants/common'
import { Maybe } from '../../types/common'
import { RootReducerState } from '../../redux/reducers'
import config from '../../configs'
import { countryUtil } from '../../utils/countryUtil'
import { useRouter } from 'next/router'
import { getServerLocale } from '@utils/locale'

export interface StoreIdentity {
  country: string
  basePath: string
  langCode: string
}

const getWithFallback = (value: Maybe<string>, fallback: string) => {
  if (!value || value === 'undefined') return fallback
  return value
}

export const langCodeExceptionMap: Record<string, string> = {
  en_gb: 'en_uk',
}

const countryExceptionMap: Record<string, string> = {
  [LANGUAGE_CODE_MAP.en_uk]: 'en-gb',
}

export const useStoreIdentity = () => {
  const router = useRouter()

  return useSelector((state: RootReducerState) => {
    const locale = state.site.currentSite?.locale || null
    const countryFromUrl = getServerLocale(router.isLocaleDomain, router.locale as string)

    let country = getWithFallback(countryFromUrl, config.defaultCountry)
    let langCode = getWithFallback(locale, config.defaultLocale.replace('_', '-'))
    langCode = langCodeExceptionMap[langCode] || langCode
    country = countryExceptionMap[country] || country
    const { currentCountry, currentLangCode } = countryUtil(country, langCode)

    return {
      isRXEnabled: state.site.currentSite?.xStoreCfg?.addPrescriptionLenses || false,
      langCode: currentLangCode,
      country: currentCountry,
      basePath: '',
      langId: state.site.currentSite?.langId?.toString() || DEFAULT_LANG_ID,
    }
  }, shallowEqual)
}
