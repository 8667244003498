import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IContractSliceState } from './IContractSliceState'
import { contractInitState as initialState } from './initState'
import { fetchContract } from './thunks/fetchContract'

const contextSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchContract.fulfilled, (state, action: PayloadAction<IContractSliceState>) => {
      for (const variableKey in state) {
        if (state.hasOwnProperty(variableKey)) {
          delete state[variableKey]
        }
      }
      Object.assign(state, action.payload.contracts)
    })
  },
})
export default contextSlice.reducer
