import React, { HTMLAttributeAnchorTarget } from 'react'
import { LinkProps as LinkMUIProps } from '@mui/material'
import { LinkProps as NextLinkProps } from 'next/link'
import { StyledLink } from './Link.style'
import { useRouter } from 'next/router'
import { getClientLocale, replaceLocaleInUrl } from '@utils/locale'
import { locationOriginSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'

export interface Link extends Omit<LinkMUIProps, 'href'> {
  target?: HTMLAttributeAnchorTarget
  rel?: string | undefined
  children: React.ReactNode
  dataName?: string
  className?: string
  prefetch?: NextLinkProps['prefetch']
  as?: NextLinkProps['as']
  underline?: LinkMUIProps['underline']
  locale?: NextLinkProps['locale']
}

export interface StandardLink extends Link {
  href: NextLinkProps['href']
  onClick?: never
}

export interface ActionLink extends Link {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  href?: never
}

export type LinkProps = StandardLink | ActionLink

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { dataName, className, prefetch = false, variant = 'body2', href, ...rest } = props
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const internalHref = replaceLocaleInUrl({
    isLocaleDomain,
    locationOrigin,
    locale,
    href: href?.toString(),
  })

  const isExternal = /^https?:\/\/|\/\/|mailto:|tel:/i.test(internalHref || '')
  const actionType = typeof props.onClick === 'function' ? 'action' : isExternal ? 'external' : 'internal'

  type OmittedProps = 'href' | 'onClick' | 'component' | 'as'

  const styledLinkProps = {
    'data-name': dataName,
    className,
    variant,
    ref,
    ...(locale ? { locale: getClientLocale(isLocaleDomain, locale as string) } : {}),
    ...(actionType === 'external' || actionType === 'action' ? { href: internalHref } : { href: internalHref }),
    ...(actionType === 'action' ? { onClick: props.onClick } : {}),
    ...(rest as Omit<typeof rest, OmittedProps>),
    prefetch: prefetch,
  }

  const renderInternalLink = (children: React.ReactNode) => {
    return <>{children}</>
  }

  const styledLink = <StyledLink {...styledLinkProps} />

  return actionType === 'action' || actionType === 'external' ? styledLink : renderInternalLink(styledLink)
})

export default Link
