import { ImageCrops } from '@components/common/components/CmsMedia/types'

export const BREAKPOINTS = { sm: 0, md: 768, lg: 1024, xl: 1280, xxl: 1440 }

export const mediaQueries: Record<keyof ImageCrops, string> = {
  xxl: `(min-width: ${BREAKPOINTS.xxl}px)`,
  xl: `(min-width: ${BREAKPOINTS.xl}px) and (max-width: ${BREAKPOINTS.xxl - 1}px)`,
  lg: `(min-width: ${BREAKPOINTS.lg}px) and (max-width: ${BREAKPOINTS.xl - 1}px)`,
  md: `(min-width: ${BREAKPOINTS.md}px) and (max-width: ${BREAKPOINTS.lg - 1}px)`,
  sm: `(min-width: 0px) and (max-width: ${BREAKPOINTS.md - 1}px)`,
}
