import {
  ACTIVITY_TOKEN_ERROR_CODE,
  ACTIVITY_TOKEN_ERROR_KEY,
  CMC_SESSION_ERROR_KEY,
  CONNECTION_TIMEDOUT_ERROR,
  EXPIRED_ACTIVITY_TOKEN_ERROR,
  INVALID_COOKIE_ERROR_CODE,
  INVALID_COOKIE_ERROR_KEY,
  PARTIAL_AUTHENTICATION_ERROR_CODE,
  PARTIAL_AUTHENTICATION_ERROR_KEY,
  PASSWORD_EXPIRED,
  PASSWORD_EXPIRED_ERR_CODE,
} from '../../constants/errors'

//standard libraries

import { EXPIRED_PASSWORD_PAGE_ERROR } from '../../foundation/constants/common'
//Redux
import { RootReducerState } from '../../redux/reducers'
import { defaultStates } from '../../redux/reducers/initStates'
import { IErrorSliceState } from './IErrorSliceState'

const sessionErrors = [
  EXPIRED_ACTIVITY_TOKEN_ERROR,
  INVALID_COOKIE_ERROR_CODE,
  INVALID_COOKIE_ERROR_KEY,
  ACTIVITY_TOKEN_ERROR_CODE,
  ACTIVITY_TOKEN_ERROR_KEY,
  PARTIAL_AUTHENTICATION_ERROR_CODE,
  PARTIAL_AUTHENTICATION_ERROR_KEY,
  CMC_SESSION_ERROR_KEY,
]
const passwordExpiredErrors = [PASSWORD_EXPIRED_ERR_CODE, PASSWORD_EXPIRED]
const connectionTimedoutErrors = [CONNECTION_TIMEDOUT_ERROR]

const sessionErrorSelector = (state: RootReducerState) => {
  const { errorCode, errorKey } = state.error
  let sessionError: IErrorSliceState = {
    ...defaultStates.error,
  }
  if (state.error[EXPIRED_PASSWORD_PAGE_ERROR]) {
    return sessionError
  }
  if ((errorCode && sessionErrors.includes(errorCode)) || (errorKey && sessionErrors.includes(errorKey))) {
    sessionError = state.error
  }
  return sessionError
}

const passwordExpiredErrorSelector = (state: RootReducerState) => {
  const errorCode =
    state.error.errorCode === undefined || state.error.errorCode === '' ? undefined : state.error.errorCode
  const errorKey = state.error.errorKey === undefined || state.error.errorKey === '' ? undefined : state.error.errorKey
  let passwordExpiredError = {}
  if (
    (errorCode && passwordExpiredErrors.includes(errorCode)) ||
    (errorKey && passwordExpiredErrors.includes(errorKey)) ||
    state.error[EXPIRED_PASSWORD_PAGE_ERROR]
  ) {
    passwordExpiredError = state.error
  }
  return passwordExpiredError
}

const genericErrorSelector = (state: RootReducerState): Record<string, string> => {
  const errorCode =
    state.error.errorCode === undefined || state.error.errorCode === '' ? undefined : state.error.errorCode
  const errorKey = state.error.errorKey === undefined || state.error.errorKey === '' ? undefined : state.error.errorKey
  let error = {}
  if (state.error[EXPIRED_PASSWORD_PAGE_ERROR]) {
    return error
  }
  if (
    !(errorCode && sessionErrors.includes(errorCode)) &&
    !(errorKey && sessionErrors.includes(errorKey)) &&
    !(errorCode && passwordExpiredErrors.includes(errorCode)) &&
    !(errorKey && passwordExpiredErrors.includes(errorKey))
  ) {
    error = state.error
  } else if (
    ((errorCode && sessionErrors.includes(errorCode)) || (errorKey && sessionErrors.includes(errorKey))) &&
    state.error.sessionErrorLoginError
  ) {
    error = state.error.sessionErrorLoginError
  }
  return error
}

const connectionTimedoutErrorSelector = (state: RootReducerState) => {
  const errorCode = state.error?.errorCode || ''
  const errorMessage = state.error?.errorMessage || ''
  return connectionTimedoutErrors.includes(errorCode) ? errorMessage : ''
}

const errorCodeSelector = (state: RootReducerState) => state.error.errorCode

const errorMessageSelector = (state: RootReducerState) => state.error.errorMessage

const migratedUserPasswordResetSelector = (state: RootReducerState) => state.error.passwordResetRequired

export {
  connectionTimedoutErrorSelector,
  errorMessageSelector,
  genericErrorSelector,
  passwordExpiredErrorSelector,
  sessionErrorSelector,
  migratedUserPasswordResetSelector,
  errorCodeSelector,
}
