import { StateManagerSSR } from './stateManager'
import { StoreManagerLoadersTypeMap } from './types'

export class CmsData extends StateManagerSSR<StoreManagerLoadersTypeMap['cms']['locale']> {
  private static instance: CmsData | null = null

  constructor() {
    super('cms')
  }

  public static Instance = (): CmsData => {
    if (!this.instance) {
      this.instance = new CmsData()
    }
    return this.instance
  }
}

export const cmsDataInstance = CmsData.Instance()
