import { localeOrDefault } from '@utils/locale'

interface CurrencyValue {
  currency?: string
  value: string | number
  locale?: string
}

class CurrencyService {
  static SYMBOLS = {
    EUR: '€',
    GBP: '£',
    USD: '$',
    CAD: '$',
    AUD: '$',
    NZD: '$',
  } as const

  CURRENCY_EUR = 'EUR' as const
  CURRENCY_GBP = 'GBP' as const
  DEFAULT_CURRENCY = this.CURRENCY_EUR

  LOCALE_TO_CURRENCY_MAP: { [key: string]: string } = {
    'fr-BE': this.CURRENCY_EUR,
    'nl-BE': this.CURRENCY_EUR,
    'es-ES': this.CURRENCY_EUR,
    'it-IT': this.CURRENCY_EUR,
    'fr-FR': this.CURRENCY_EUR,
    'en-UK': this.CURRENCY_GBP,
    'en-IE': this.CURRENCY_EUR,
    'nl-NL': this.CURRENCY_EUR,
  } as const

  // TODO  currencyName might be undefined or null. Handle it.
  /**
   * Retrieves the symbol for a given currency name.
   * @param currencyName The name of the currency.
   * @returns The symbol for the currency, or an empty string if not found.
   */
  getSymbolByName(currencyName: string): string {
    return CurrencyService.SYMBOLS[currencyName] || ''
  }

  /**
   * Retrieves the currency code based on the provided locale.
   * If no locale is provided, the default locale will be used.
   * @param locale The locale for which to retrieve the currency code.
   * @returns The currency code (EUR) for the specified locale, or the default currency code if not found.
   */
  getCurrencyByLocale(locale?: string): string {
    const localeToUse = localeOrDefault(locale)
    return this.LOCALE_TO_CURRENCY_MAP[localeToUse] || this.DEFAULT_CURRENCY
  }

  /**
   * Retrieves the currency symbol for a given locale.
   * @param locale The locale for which to retrieve the currency symbol.
   * @returns The currency symbol for the specified locale, or an empty string if not found.
   */
  getCurrencySymbolByLocale(locale: string): string {
    const localeToUse = localeOrDefault(locale)
    const currencyToUse = this.getCurrencyByLocale(localeToUse)
    return this.getSymbolByName(currencyToUse)
  }

  /**
   * Formats a value with a specified currency and locale.
   * @param currency - The currency code to use for formatting.
   * @param value - The value to format.
   * @param locale - The locale to use for formatting. If not provided, 'en-US' will be used.
   * @returns The formatted value with currency symbol.
   */
  formatValueWithCurrency = ({ currency, value, locale }: CurrencyValue) => {
    const localeToUse = localeOrDefault(locale)
    const currencyToUse = currency || this.getCurrencyByLocale(localeToUse)

    // Fixes en-UK to en-GB
    const intLocale = new Intl.Locale(localeToUse)

    return new Intl.NumberFormat(intLocale.toString(), {
      style: 'currency',
      currency: currencyToUse,
    }).format(+value)
  }
}
const currencyService = new CurrencyService()
export default currencyService
