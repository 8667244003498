import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootReducerState } from '@redux/reducers'
import countryService from '../../foundation/apis/transaction/country.service'
import { CountryStatesResponse } from '../../types/common'
import { countriesListSelector } from '@redux/selectors/account'

interface ICountryListPayload {
  storeId?: string
  profileName?: string | string[]
  countryCode?: string | string[]
  widget?: string
}

export const fetchCountryListDetails = createAsyncThunk<CountryStatesResponse, ICountryListPayload>(
  'account/fetchCountryListDetails',
  async (parameters, { rejectWithValue, getState }) => {
    const state = getState() as RootReducerState

    const countries = countriesListSelector(state)
    if (countries) {
      return {
        countries: countries,
        countryCodeStates: state.account.statesProvinces,
      } as CountryStatesResponse
    }

    try {
      const serviceResponse = await countryService.findCountryStateList(parameters)
      return serviceResponse.data
    } catch (e: any) {
      return rejectWithValue(e)
    }
  }
)
