import { idMeApi } from '@features/idMe/query'
import { EXPIRED_PASSWORD_PAGE_ERROR } from '@foundation/constants/common'
import { IdMeReducerState } from '@redux/reducers'
import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { errorInitState as initialState } from './initState'

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    handleSessionErrorAction: (state, action) => {
      Object.assign(state, { ...action.payload })
      delete state[EXPIRED_PASSWORD_PAGE_ERROR]
    },
    cmsApiErrorAction: (state, action) => {
      Object.assign(state, { ...action.payload })
      delete state[EXPIRED_PASSWORD_PAGE_ERROR]
    },
    loginSuccessAction: state => {
      if (state.handled === false) state.handled = true
      delete state[EXPIRED_PASSWORD_PAGE_ERROR]
    },
    resetErrorSuccessAction: (state, action) => {
      for (const variableKey in state) {
        if (state.hasOwnProperty(variableKey)) {
          delete state[variableKey]
        }
      }
      Object.assign(state, { ...action.payload })
    },
    resetSessionPopupLogonErrorAction: state => {
      if (state.hasOwnProperty('sessionErrorLoginError')) {
        delete state.sessionErrorLoginError
      }
      delete state[EXPIRED_PASSWORD_PAGE_ERROR]
    },
    validationErrorAction: (state, action) => {
      Object.assign(state, { ...action.payload })
      delete state[EXPIRED_PASSWORD_PAGE_ERROR]
    },
    logonAndChangePasswordFailAction: (state, action) => {
      Object.assign(state, { ...action.payload })
    },
    handleLoginError: (state, action) => {
      Object.assign(state, { ...action.payload })
    },
    handleConnectionTimedOutErrorAction: (state, action) => {
      Object.assign(state, { ...action.payload })
    },
    handleAddCcErrorAction: (state, action) => {
      const error = action?.payload?.response?.data
      Object.assign(state, { ...error })
    },
    handleCreateReturnErrorAction: (state, action) => {
      Object.assign(state, { ...action.payload })
    },
    resetAllErrors: state => {
      Object.assign(state, initialState)
    },
    handleMigratedUserAction: (state, action) => {
      Object.assign(state, { ...action.payload })
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(idMeApi.endpoints.getIdMePromoCode.matchFulfilled, idMeApi.endpoints.getIdMePromoCode.matchRejected),
      (state, { payload }: PayloadAction<IdMeReducerState>) => {
        const { idMeInvalidAuthentication } = payload
        const INVALID_AUTHENTICATION = idMeInvalidAuthentication === 'true'
        if (INVALID_AUTHENTICATION)
          Object.assign(state, {
            ...state,
            errorMessage: 'IDMe: ' + payload.idMeErrorMsg,
            errorCode: payload.idMeErrorCode,
            handled: false,
          })
      }
    )
  },
})

export const {
  handleSessionErrorAction,
  cmsApiErrorAction,
  loginSuccessAction,
  resetErrorSuccessAction,
  resetSessionPopupLogonErrorAction,
  validationErrorAction,
  logonAndChangePasswordFailAction,
  handleLoginError,
  handleConnectionTimedOutErrorAction,
  handleAddCcErrorAction,
  handleCreateReturnErrorAction,
  resetAllErrors,
  handleMigratedUserAction,
} = errorSlice.actions

export default errorSlice.reducer
