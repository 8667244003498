import { omit } from '@utils/helpers'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import {
  appliedPromotionCodesSelector,
  orderDiscountNamesSelector,
  totalOrderDiscountSelector,
} from '../../features/order/selector'
import { isReorderIn3ClicksButtonClickedSelector } from '@features/user/selector'
import { RootReducerState } from '../../redux/reducers'
import { CommonData } from '../analytics/tealium/interfaces'
import { useStoreIdentity } from './useStoreIdentity'
import { useDeviceType } from '@hooks/useDeviceType'
import { useRouter } from 'next/router'
import { locationOriginSelector } from '@redux/selectors/site'
import { replaceLocaleInUrl } from '@utils/locale'

export const DEFAULT_LANG = 'en-US'

export const useAnalyticsData = (pageType?: string): Partial<CommonData> => {
  const { country, langCode } = useStoreIdentity()
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const totalOrderDiscount = useSelector(totalOrderDiscountSelector)
  const orderDiscountNames = useSelector(orderDiscountNamesSelector)
  const isReorderIn3ClicksButtonClicked = useSelector(isReorderIn3ClicksButtonClickedSelector)
  const discountCodes = useSelector(appliedPromotionCodesSelector)
  const { t } = useTranslation()
  const deviceType = useDeviceType()

  return useSelector((state: RootReducerState) => {
    const currency = state.site.currentSite?.defaultCurrencyID
    const parsedLanguage = langCode.split('-')[0].toUpperCase()
    const parsedCountry = country.toUpperCase()
    let baseAnalyticsData: Partial<CommonData> = {
      Page_Brand: state.site.currentSite?.xStoreCfg?.analyticsData?.Page_Brand || 'VD',
      Page_Design: '',
      Page_Country: `${parsedCountry}`,
      Page_Language: `${parsedLanguage}`,
      Page_DeviceType: deviceType,
      Page_Platform: state.site.currentSite?.xStoreCfg?.analyticsData?.Page_Platform || 'WCS',
      Page_Server: state.site.currentSite?.xStoreCfg?.analyticsData?.Page_Server || '',
      Page_Section2: '',
      Page_Section3: '',
      Order_Currency: `${currency}`,
      Production: state.site.hostnameUrls?.isProduction ? true : false,
      GDPR_Page: replaceLocaleInUrl({
        isLocaleDomain,
        locationOrigin,
        locale,
        href: t('Footer.Labels.EverythingElseLinks.cookie.url'),
      }),
    }
    switch (pageType) {
      case 'notFound':
        baseAnalyticsData = omit(baseAnalyticsData, ['Order_Currency'])
        break
      case 'home':
        baseAnalyticsData = {
          ...baseAnalyticsData,
        }
        break
      case 'cart':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames,
          Page_Section2: isReorderIn3ClicksButtonClicked ? 'Reorder' : '',
        }
        break
      case 'promo':
        baseAnalyticsData = {
          Order_DiscountCode: discountCodes,
          Order_DiscountName: orderDiscountNames,
        }
        break
      case 'checkout':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Page_Section2: isReorderIn3ClicksButtonClicked ? 'Reorder' : 'Standard',
        }
        break
      case 'delivery':
      case 'payment':
      case 'typ':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames,
          // Order_GiftCardCode NOT AVAILABLE FOR ARNETTE
          Order_DiscountCode: discountCodes,
          Page_Section2: isReorderIn3ClicksButtonClicked ? 'Reorder' : 'Standard',
        }
        break
      case 'page':
        baseAnalyticsData = {
          ...baseAnalyticsData,
        }
        break
      case 'plp':
      case 'pdp':
      case 'profile':
      case 'signIn':
      default:
    }
    return baseAnalyticsData
  }, shallowEqual)
}

export const getUserToken = () => {
  let savedUserToken = sessionStorage.getItem('userToken')
  let randomUserToken = `${Math.floor(Math.random() * 100000)}${new Date().getTime()}`

  const userToken = window?.utag_data?.User_Email_MD5 || savedUserToken || randomUserToken

  sessionStorage.setItem('userToken', userToken)
  return userToken
}
