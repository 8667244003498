import { TabStore } from '../types/tabStores'
import { Store } from '../types/store'

interface MapStoresProps {
  stores: TabStore[]
  storeName: string
}

export const mapStoresData = ({ stores, storeName }: MapStoresProps): Store[] => {
  return stores.map((storeInfo) => {
    const monday = storeInfo.officeHours.find((h) => h.dayOfWeek.toUpperCase() === 'MONDAY')
    const tuesday = storeInfo.officeHours.find((h) => h.dayOfWeek.toUpperCase() === 'TUESDAY')
    const wednesday = storeInfo.officeHours.find((h) => h.dayOfWeek.toUpperCase() === 'WEDNESDAY')
    const thursday = storeInfo.officeHours.find((h) => h.dayOfWeek.toUpperCase() === 'THURSDAY')
    const friday = storeInfo.officeHours.find((h) => h.dayOfWeek.toUpperCase() === 'FRIDAY')
    const saturday = storeInfo.officeHours.find((h) => h.dayOfWeek.toUpperCase() === 'SATURDAY')
    const sunday = storeInfo.officeHours.find((h) => h.dayOfWeek.toUpperCase() === 'SUNDAY')

    return {
      storeId: storeInfo.storeNumber,
      brand: storeName,
      country: storeInfo.address.country,
      storeName: storeInfo.name,
      completeAddress: `${storeInfo.address.city}, ${storeInfo.address.address}, ${storeInfo.address.postalCode}`,
      address: storeInfo.address.address,
      city: storeInfo.address.city,
      zipcode: storeInfo.address.postalCode,
      telephone: storeInfo.phone?.number || '',
      storeEmailAddress: storeInfo.email,
      storeType: storeInfo.officeType,
      mondayOT: monday && !monday.isClosed ? monday.opening : 'closed',
      mondayCT: monday && !monday.isClosed ? monday.closing : 'closed',
      tuesdayOT: tuesday && !tuesday.isClosed ? tuesday.opening : 'closed',
      tuesdayCT: tuesday && !tuesday.isClosed ? tuesday.closing : 'closed',
      wednesdayOT: wednesday && !wednesday.isClosed ? wednesday.opening : 'closed',
      wednesdayCT: wednesday && !wednesday.isClosed ? wednesday.closing : 'closed',
      thursdayOT: thursday && !thursday.isClosed ? thursday.opening : 'closed',
      thursdayCT: thursday && !thursday.isClosed ? thursday.closing : 'closed',
      fridayOT: friday && !friday.isClosed ? friday.opening : 'closed',
      fridayCT: friday && !friday.isClosed ? friday.closing : 'closed',
      saturdayOT: saturday && !saturday.isClosed ? saturday.opening : 'closed',
      saturdayCT: saturday && !saturday.isClosed ? saturday.closing : 'closed',
      sundayOT: sunday && !sunday.isClosed ? sunday.opening : 'closed',
      sundayCT: sunday && !sunday.isClosed ? sunday.closing : 'closed',
      latitude: '',
      longitude: '',
    }
  })
}
