import { pickBy } from '@utils/helpers'
import { TFunction } from 'next-i18next'
import { PRODUCT_TYPES_MAP } from '../constants/product'
import { IBadges } from '../redux/reducers/reducerStateInterface'
import { areEquals, booleanToString } from './common'

export const getProductAttribute = (p, key, notTranslated?: boolean) => {
  let attributes: object
  if (!!notTranslated) {
    attributes = p?.attributes || {}
  } else {
    attributes = p?.attributes_translated || {}
  }
  return attributes[key]?.toString().split('|')[0] ?? ''
}

export const getFacetLabel = (label: string): string => {
  return label.includes('|') ? label.split('|')[0] : label
}

// TODO: Add missing attributes
export const getFrameShape = p => getProductAttribute(p, 'FRAME_SHAPE')
export const getFrameType = p => getProductAttribute(p, 'FRAME_TYPE')
export const getFrameMaterial = p => getProductAttribute(p, 'FRAME_MATERIAL_CLASS')
export const getFrameMaterialFacet = p => getProductAttribute(p, 'FRAME_MATERIAL_FACET')
export const getTempleColorFacet = p => getProductAttribute(p, 'TEMPLE_COLOR_FACET')
export const getFrontColorFacet = p => getProductAttribute(p, 'TEMPLE_COLOR_FACET')
export const getColorCode = p => getProductAttribute(p, 'COLOR_CODE')
export const getIsPolarized = p => {
  return getProductAttribute(p, 'POLARIZED')
}
export const getIsMostPopular = p => getProductAttribute(p, 'LX_IS_MOST_POPULAR')
export const getIsRoxable = p => areEquals(getProductAttribute(p, 'ROXABLE'), PRODUCT_ATTRIBUTE_TRUE_VALUE)
export const getIsSustainable = p => getProductAttribute(p, 'SUSTAINABILITY_CLAIM')
export const getIsCustomizable = p => getProductAttribute(p, 'CUSTOMIZABLE')
export const getIsOnlineExclusive = p => getProductAttribute(p, 'WEB EXCLUSIVE')
export const getIsExclusive = p => getProductAttribute(p, 'EXCLUSIVE')
export const getIsAvantPremiere = p => getProductAttribute(p, 'AVANT_PREMIERE')
export const getIsLimitedEdition = p => getProductAttribute(p, 'LIMITED_EDITION')
export const getAnnualSupplyBadge = p => getProductAttribute(p, 'CL_ANNUAL_SUPPLY_BADGES')
export const getNew = p => getProductAttribute(p, 'IS_NEW')
export const getBadge = p => getProductAttribute(p, 'BADGE')
export const getSoldOut = p => getProductAttribute(p, 'LX_SOLDOUT')
export const getModelCodeDisplay = p => getProductAttribute(p, 'MODEL_CODE_DISPLAY')
export const getFrontColor = p => getProductAttribute(p, 'FRONT_COLOR')
export const getLensesColor = p => getProductAttribute(p, 'LENS_COLOR')
export const getLensesColorFacet = p => getProductAttribute(p, 'LENS_COLOR_FACET')
export const getTempleColor = p => getProductAttribute(p, 'TEMPLE_COLOR')
export const getLensesTreatment = p => getProductAttribute(p, 'LENS_TREATMENT_FACET')
export const getLensMacroMaterial = p => getProductAttribute(p, 'LENS_MATERIAL_MACRO_CLASS')
export const getFrameSize = p => getProductAttribute(p, 'FRAME_SIZE_DISPLAY')
export const getFrame = p => getProductAttribute(p, 'FRAME_SIZE')
export const getBrand = p => getProductAttribute(p, 'BRAND')
export const getCLBrand = p => getProductAttribute(p, 'CL_BRAND')
export const getModelName = (p, notTranslated?: boolean) => getProductAttribute(p, 'MODEL_NAME', notTranslated)
export const getProductType = p => getProductAttribute(p, 'PRODUCT_TYPE')
export const getModelCode = p => getProductAttribute(p, 'DISPLAYSKU')
export const getBridgeWidth = p => getProductAttribute(p, 'BRIDGE_WIDTH')
export const getLensType = p => getProductAttribute(p, 'CORRECTION_TYPE')
export const getLensWidth = p => getProductAttribute(p, 'LENS_WIDTH')
export const getTempleLength = p => getProductAttribute(p, 'TEMPLE_LENGTH')
export const getSearchPageProductType = p => getProductAttribute(p, 'LX_SEARCH_PAGE_PRODUCT_TYPE')
export const getHingeDistance = p => getProductAttribute(p, 'HINGE_DISTANCE')

export const PRODUCT_SIZES_NLS_BASE = 'ProductTile.Sizes.'

/** Returns formatted product size if TFunction from react-i18n was passed as argument.
 * Otherwise returns value of FRAME_SIZE_DISPLAY attribute */
export const getSize = (p, t?: TFunction): string => {
  const frameSizeDisplay = getProductAttribute(p, 'FRAME_SIZE_DISPLAY')
  const productSizeNls = PRODUCT_SIZES_NLS_BASE + frameSizeDisplay

  return t && productSizeNls ? t(productSizeNls) : frameSizeDisplay
}

export const PRODUCT_ATTRIBUTE_TRUE_VALUE = 'True' as const

export interface ProductBadges {
  isNew: boolean
  isBadge: boolean
  isLimitedEdition: boolean
  isExclusive: boolean
  isAvantPremiere: boolean
  isOnlineExclusive: boolean
  isSustainable: boolean
  isCustomizable: boolean
  isPolarized: boolean
  isMostPopular: boolean
  isRoxable: boolean
}

const getPriorityBadgeText = (badges: ProductBadges, t: TFunction): string => {
  switch (true) {
    case badges.isExclusive:
      return t('ProductTile.Labels.exclusive')
    case badges.isOnlineExclusive:
      return t('ProductTile.Labels.onlineExclusive')
    case badges.isAvantPremiere:
      return t('ProductTile.Labels.avantPremiere')
    case badges.isNew:
      return t('ProductTile.Labels.new')
    case badges.isMostPopular:
      return t('ProductTile.Labels.mostPopular')
    default:
      return ''
  }
}

export const getBadges = (
  product,
  t: TFunction,
  plpBadges: IBadges = {} as IBadges,
  isRXEnabled = false
): {
  primaryBadge: string
  secondaryBadges: string
} => {
  const badges: ProductBadges = {
    isBadge: areEquals(booleanToString(plpBadges.BADGE), getBadge(product)),
    isNew: areEquals(booleanToString(plpBadges.IS_NEW), getNew(product)),
    isLimitedEdition: areEquals(booleanToString(plpBadges.LIMITED_EDITION), getIsLimitedEdition(product)),
    isOnlineExclusive: areEquals(booleanToString(plpBadges['WEB EXCLUSIVE']), getIsOnlineExclusive(product)),
    isExclusive: areEquals(booleanToString(plpBadges.EXCLUSIVE), getIsExclusive(product)),
    isAvantPremiere: areEquals(booleanToString(plpBadges.AVANT_PREMIERE), getIsAvantPremiere(product)),
    isRoxable: isRXEnabled && getIsRoxable(product),
    isCustomizable: areEquals(booleanToString(plpBadges.CUSTOMIZABLE), getIsCustomizable(product)),
    isPolarized: areEquals(booleanToString(plpBadges.POLARIZED), getIsPolarized(product)),
    isMostPopular: areEquals(booleanToString(plpBadges.LX_IS_MOST_POPULAR), getIsMostPopular(product)),
    isSustainable: areEquals(booleanToString(plpBadges.SUSTAINABILITY_CLAIM), getIsSustainable(product)),
  }

  const primaryBadge = getPriorityBadgeText(badges, t)

  const currentLensTreatment = getLensesTreatment(product)
  const activeBadges = Object.keys(
    pickBy(
      {
        Polarised: badges.isPolarized,
        Sustainable: badges.isSustainable,
        isLensTreatment: currentLensTreatment === 'Photochromic' || currentLensTreatment === 'Transitions®',
      },
      cb => cb
    )
  )

  const secondaryBadges = (activeBadges.length > 2 ? activeBadges.slice(0, 2) : activeBadges)
    .map(el => (el === 'isLensTreatment' ? 'Light-Adaptive' : el))
    .join(' | ')

  return { primaryBadge, secondaryBadges }
}

/** Used in ProductTile in MoCos slider */
export const getProductColorLabel = (product): string => {
  const productType = PRODUCT_TYPES_MAP[getProductType(product).toLowerCase()] || PRODUCT_TYPES_MAP.frames

  switch (productType) {
    case PRODUCT_TYPES_MAP.accessories:
      return getFrontColor(product)
    case PRODUCT_TYPES_MAP.optical:
      return getFrontColor(product)
    case PRODUCT_TYPES_MAP.sun:
      const frontColor = getFrontColor(product)
      const lensColor = getLensesColor(product) || getLensesColorFacet(product)

      return frontColor === lensColor ? frontColor : `${frontColor} ${lensColor}`.trim()
    default:
      return ''
  }
}
