import { handleLoginError } from '@features/error/slice'
import { sendLoginEvent, sendLoginErrorEvent } from '@foundation/analytics/tealium/lib'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IPayloadBasePromoCode } from '@typesApp/common'
import { loginAndFetchDetails } from './loginAndFetchDetails'
import { userDetailsSelector } from '@features/user/selector'
import { setUserIsLoggingIn } from '@features/user/slice'
import { RootReducerState } from '@redux/reducers'
import { SKIP_CREDENTIALS, SKIP_WC_TOKEN_HEADER } from '@foundation/constants/common'
import { getInsuranceEventModule } from '@components/DirectBilling/utils/insuranceModule'
import { migratedUserPasswordResetSelector } from '@features/error/selector'
import { TFunction } from 'next-i18next'

export type ILoginArgs = IPayloadBasePromoCode & {
  body: {
    logonId: string
    logonPassword: string
  }
  translation: TFunction
  isAutoLogin?: boolean
  [SKIP_WC_TOKEN_HEADER]?: boolean
  [SKIP_CREDENTIALS]?: boolean
}

export const login = createAsyncThunk<any, ILoginArgs>(
  'user/login',
  async (args, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setUserIsLoggingIn(true))
      await dispatch(loginAndFetchDetails(args))
      const email = args?.body?.logonId

      const store = getState() as RootReducerState
      const userState = userDetailsSelector(store)
      const passwordResetRequired = migratedUserPasswordResetSelector(store)
      if (email && userState) {
        sendLoginEvent(userState)
      } else {
        sendLoginErrorEvent(args.translation, passwordResetRequired)
      }
      getInsuranceEventModule().onLogin()
      dispatch(setUserIsLoggingIn(false))
      return
    } catch (e) {
      dispatch(handleLoginError(e))
      dispatch(setUserIsLoggingIn(false))
      return rejectWithValue(e)
    }
  }
)
