import recommend, { RecommendClient } from '@algolia/recommend'
import config from '@configs/index'
import algoliasearch, { SearchClient } from 'algoliasearch'
import aa from 'search-insights'
import { INDICES_MAP } from '../../features/plp/algoliaUtils'

export const algoliaClient: SearchClient = algoliasearch(config?.algolia?.id, config?.algolia?.apiKey, {
  headers: {
    'Content-Type': 'application/json',
  },
})
let algoliaSearchInsightInitialized = false

export const algoliaEventTrigger = (eventName, payload) => {
  if (typeof window !== 'undefined') {
    if (!algoliaSearchInsightInitialized) {
      aa('init', {
        apiKey: config?.algolia?.apiKey,
        appId: config?.algolia?.id,
      })
      algoliaSearchInsightInitialized = true
    }
    aa(eventName, payload)
  }
}

// If you don’t want to perform a search request when the query or indexName is empty (""), you first need to detect it.
// When an empty search is detected, you must return a formatted response as an array of objects of the same length as the requests array.
// Each object requires at least these properties: hits, nbHits, and processingTimeMS.
// https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react-hooks/#detecting-empty-search-requests
export const initSearchClient = (checkForEmptyQuery = false): SearchClient => {
  return {
    ...algoliaClient,
    search(requests) {
      if (
        requests.every(({ indexName, params, query }) => {
          const isQueryEmpty = checkForEmptyQuery ? !query && !params?.query : false
          return !indexName || isQueryEmpty
        })
      ) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: '',
            params: '',
          })),
        })
      }

      return algoliaClient.search(requests)
    },
  }
}

const indexNameBuilder = ({ indexName, groupingSufix, sortOptionSufix = '', orderSufix = '' }): string =>
  `${indexName}${groupingSufix}${sortOptionSufix}${orderSufix}`

export type TInitIndexName = Partial<{
  locale: string
  sortOption?: number
  customerSegment: string
  isGrouped?: boolean
}>
export const initIndexName = ({ locale, sortOption, isGrouped = true, customerSegment }: TInitIndexName): string => {
  const { indexName, groupedSufix, ungroupedSufix, newestSufix, querySuggestionsSufix, ascSufix, descSufix } =
    config.algolia

  const priceSufix = `__${customerSegment}`
  let indexNameLocale = `${indexName}_${locale === 'en_UK' ? 'en_GB' : locale}`
  const groupingSufix = isGrouped ? groupedSufix : ungroupedSufix

  switch (sortOption) {
    case INDICES_MAP.BEST_SELLERS:
      return indexNameBuilder({ indexName: indexNameLocale, groupingSufix })
    case INDICES_MAP.NEW_ARRIVALS:
      return indexNameBuilder({
        indexName: indexNameLocale,
        groupingSufix,
        sortOptionSufix: newestSufix,
        orderSufix: descSufix,
      })
    case INDICES_MAP.PRICE_ASC:
      return indexNameBuilder({
        indexName: indexNameLocale,
        groupingSufix,
        sortOptionSufix: priceSufix,
        orderSufix: ascSufix,
      })
    case INDICES_MAP.PRICE_DESC:
      return indexNameBuilder({
        indexName: indexNameLocale,
        groupingSufix,
        sortOptionSufix: priceSufix,
        orderSufix: descSufix,
      })
    case INDICES_MAP.QUERY_SUGGESTIONS:
      return (indexNameLocale += querySuggestionsSufix)
    default:
      return indexNameBuilder({ indexName: indexNameLocale, groupingSufix })
  }
}

export const recommendClient: RecommendClient = recommend(config?.algolia?.id, config?.algolia?.apiKey, {
  headers: {
    'Content-Type': 'application/json',
  },
})
