/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosRequestConfig, Method, AxiosPromise } from 'axios'
//Foundation libraries
import { executeRequest } from '../../axios/axiosConfig'
import { getSite } from '../../hooks/useSite'
import { localStorageUtil } from '../../utils/storageUtil'
import { PRODUCTION, SHOW_API_FLOW } from '../../constants/common'
//Redux
import { API_CALL_ACTION } from '../../../redux/actions/api'
import { getCookieByName } from '../../../utils/cookie'
import RequestService from '../../../services/RequestService'
import { CheckoutPayload } from '../../../types/checkout'

/**
 * @deprecated Use Redux Toolkit Query instead
 */
const shippingInfoService = {
  /**
   * Updates shipping information for the shopping cart.
   * `@method`
   * `@name ShippingInfo#updateOrderShippingInfo`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {any} body ` Request body.
   ** `@property {string} forUser ` User name to act on behalf of.
   ** `@property {string} forUserId ` User identifier to act on behalf of.
   */
  _updateOrderShippingInfo(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    let siteContext = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/@self/shipping_info'
    let requestUrl = domain + path
    const method: Method = 'PUT'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error("Request '/store/{storeId}/cart/@self/shipping_info' missing path parameter storeId")
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters['forUser'] !== undefined) {
      const name = 'forUser'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['forUserId'] !== undefined) {
      const name = 'forUserId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    const showAPIFlow = process.env.NODE_ENV !== PRODUCTION ? localStorageUtil.get(SHOW_API_FLOW) === 'true' : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('../../../redux/store').default
      if (store) {
        store.dispatch(API_CALL_ACTION(from + ' -> Transaction: ' + method + ' ' + requestUrl + '?' + queryParameters))
      }
    }

    return executeRequest(requestOptions)
  },
  /**
   * Gets the order estimated taxes value without shipping address set.
   * `@method`
   * `@name estimateTaxes`
   * `@property {string} orderId (required)` The order identifier
   * `@property {string} zipCode (required)` The zipCode value
   */
  updateOrderShippingInfo(payload: CheckoutPayload) {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'PUT',
      path: '/store/{storeId}/cart/@self/shipping_info',
      pathParams: { storeId: payload.storeId },
    })
  },
}

export default shippingInfoService
