export const getIsUrlIgnored = (requestUrl: string) => {
  const ignoredUrlsRegexp = [/^\/wcs\/resources\/store\/\d+\/customersegments$/]

  return ignoredUrlsRegexp.find(regexpItem => requestUrl.match(regexpItem))
}

export const getIsDomainIgnored = (requestUrl: string) => {
  const ignoredDomainsRegexp = [/www-vdeprodlivessr\.luxgroup\.net/, /www-/]

  return ignoredDomainsRegexp.find(regexpItem => requestUrl.match(regexpItem))
}

export const getIsErrorIgnored = (errorMessage?: string) => {
  if (!errorMessage) return false
  const ignoredErrorMessages = ['(evaluating \'r[0]["@context"]\')', '\'r[0]["@context"]\'', 'r[0]["@context"]']

  return ignoredErrorMessages.find(ignoredError => errorMessage.indexOf(ignoredError) !== -1)
}

export const getCustomErrorEventName = (event, newTitle, newMessage) => {
  const customException = {
    values: event.exception?.values?.map(eventItem => ({
      ...eventItem,

      value: newMessage,
      type: newTitle,
    })),
  }
  return customException
}
