import { PersonResponse } from '@typesApp/user'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'

export const fiscalCodeApi = createApi({
  reducerPath: 'fiscalCodeApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),

  endpoints: build => ({
    getFiscalCode: build.query<PersonResponse, any>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, orderId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/person/@self`,
          method: 'GET',
          pathParams: { storeId: storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
    postFiscalCode: build.mutation<any, any>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, langId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/person/@self/contact`,
          method: 'POST',
          body: {
            firstName: args.firstName,
            lastName: args.lastName,
            city: args.city,
            country: args.country,
            state: args.state,
            zipCode: args.zipCode,
            phone1: args.phone1,
            email1: args.email1,
            addressType: args.addressType,
            addressLine: args.addressLine,
            fiscalCode: args.fiscalCode.trim(),
          },
          pathParams: { storeId: storeId },
        })

        return result?.data ? { data: result.data } : { error: result.error }
      },
    }),
  }),
})

export const { useGetFiscalCodeQuery, useLazyGetFiscalCodeQuery, usePostFiscalCodeMutation } = fiscalCodeApi
