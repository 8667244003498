import Link, { LinkProps } from '../../UI/Link'

export {
  /**
   * @deprecated This component has been deprecated.
   * Please use `@components/UI/Link` instead.
   */
  Link as StyledAnchor,
}

export type { LinkProps as AnchorProps }
