import { site } from '@foundation/constants/site'
import { AxiosPromise, AxiosRequestConfig, AxiosResponse, Method, ResponseType } from 'axios'
import { IFacet } from '../features/plp/query'

import { executeRequest } from '../foundation/axios/axiosConfig'

export interface PaginationResponse<T = any> {
  contents: T[]
  total: number
  breadCrumbTrailEntryView: T[]
  facets?: IFacet[]
  metaData: {
    price: string
  }
}

export interface BreadCrumbResponse<T = any> {
  breadCrumbTrailEntryView: T[]
}

export type RecordSetResponse<T> = T & {
  recordSetCount: string
  recordSetComplete: string
  recordSetStartNumber: string
  recordSetTotal: string
}

export interface RequestProps {
  body?: any
  extraParams?: {
    siteContextKey?: 'dx' | 'search' | 'transaction'
    [key: string]: any
  }
  headers?: any
  method: Method
  path: string
  pathParams?: Record<string, any>
  queryParams?: Record<string, any>
  responseType?: ResponseType
}

class RequestService {
  axiosRequest<T = any>({
    body: bodyParam,
    extraParams = {},
    headers,
    method,
    path,
    pathParams: pathParamsProp = {},
    queryParams: queryParamsProp = {},
    responseType,
  }: RequestProps): AxiosPromise<T> {
    const siteContextKey = extraParams.siteContextKey || 'transaction'
    const siteContext: string = site ? site[siteContextKey + 'ContextUrl'] || '' : ''
    const domain = extraParams.url || siteContext
    let requestUrl = domain + path
    const form: any = {}
    let body = bodyParam || {}
    const queryParams = new URLSearchParams()
    const formParams = new URLSearchParams()
    let pathParams = Object.assign({}, pathParamsProp)
    if (pathParams.storeId) {
      if (!path.includes('{storeId}')) {
        queryParams.set('storeId', pathParams.storeId)
      }
    } else if (queryParamsProp.storeId) {
      // this block of code is only triggers to the route with no langCode like localhost:3000/IDMeRedirectLandingView/*
      pathParams = {
        ...pathParams,
        storeId: queryParamsProp.storeId,
      }
    } else {
      // eslint-disable-next-line no-console
      console.log(
        `${requestUrl} - Request '${path}' missing path parameter storeId, you have to pass it storeID and langId as params`
      )
      throw new Error(`Request '${path}' missing path parameter storeId`)
    }

    for (const key in pathParams) {
      requestUrl = requestUrl.replace(`{${key}}`, pathParams[key])
    }

    for (const queryParam in queryParamsProp) {
      const queryParamValue = queryParamsProp[queryParam]
      if (queryParamValue === undefined) {
        continue
      }

      if (Array.isArray(queryParamValue)) {
        queryParamValue.forEach(value => {
          queryParams.append(queryParam, value)
        })
      } else {
        queryParams.set(queryParam, queryParamValue)
      }
    }
    queryParams.sort()

    const header: Headers = typeof headers === 'undefined' || headers === null ? new Headers() : new Headers(headers)
    const acceptHeaderValues = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const value of acceptHeaderValues) {
      header.append('Accept', value)
    }

    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headersObject: any = {}
    for (const headerPair of header.entries()) {
      headersObject[headerPair[0]] = headerPair[1]
    }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        data: body,
        headers: headersObject,
        method,
        params: queryParams,
        url: requestUrl,
        responseType,
      },
      { ...extraParams }
    )
    return executeRequest<T>(requestOptions)
  }

  async request<T = any>(props: RequestProps) {
    const axiosResponse: AxiosResponse<T> = await this.axiosRequest(props)
    return axiosResponse.data
  }

  async requestForRtk<T = any>(props: RequestProps) {
    const axiosResponse: AxiosResponse<T> = await this.axiosRequest(props)
    return axiosResponse
  }

  getRecordSetData(
    pageSize: number,
    { recordSetCount, recordSetTotal, recordSetStartNumber }: RecordSetResponse<{}>
  ): {
    currentPage: number
    numberOfPages: number
  } {
    return {
      currentPage: Math.ceil((+recordSetCount + +recordSetStartNumber) / pageSize),
      numberOfPages: Math.ceil(+recordSetTotal / pageSize),
    }
  }
}
const requestService = new RequestService()
export default requestService
