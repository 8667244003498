import useBreakpointLib from 'use-breakpoint'
import { BREAKPOINTS } from './helpers'

type TBreakpoint = keyof typeof BREAKPOINTS

export const useBreakpoint = (defaut?: TBreakpoint) => {
  const { breakpoint } = useBreakpointLib(BREAKPOINTS, defaut)

  return {
    isMobile: breakpoint === 'sm',
    isTablet: breakpoint === 'md',
    isDesktop: breakpoint === 'lg' || breakpoint === 'xl' || breakpoint === 'xxl',
    isDesktopS: breakpoint === 'xl' || breakpoint === 'xxl',
    isDesktopL: breakpoint === 'xxl',
  }
}
