import { StateManagerSSR } from './stateManager'
import { StoreManagerLoadersTypeMap } from './types'

export class PageData extends StateManagerSSR<StoreManagerLoadersTypeMap['pageData']['locale']> {
  private static instance: PageData | null = null

  constructor() {
    super('pageData')
  }

  public static Instance(): PageData {
    if (!this.instance) {
      this.instance = new PageData()
    }
    return this.instance
  }
}

export const pageDataInstance = PageData.Instance()
