import { TrustPilotProductAPI, TrustPilotSummaryProductAPI } from '../types/trustPilotAPI'

export const PRODUCT_SOLDOUT_STATUS = {
  NONE: 'NONE',
  SOLDOUT: 'SOLD OUT',
  COMING_SOON: 'COMING SOON',
  COMING_BACK_SOON: 'COMING BACK SOON',
  INFINITE_INVENTORY: 'INFINITE_INVENTORY',
} as const

export const DEFAULT_DELIVERY_DATES = {
  PLANO_STANDARD: 5,
  RX_MAN: 2,
  RX_STANDARD: 7,
}

// lowercased values related to `FRAME_MATERIAL_CLASS` attribute
export const RECYCLABEL_FRAME_MACRO_MATERIALS = ['bio-acetate', 'bio-plastic']

// lowercased values related to `LENS_MATERIAL_MACRO_CLASS` attribute
export const RECYCLABEL_LENS_MACRO_MATERIALS = ['bio-lenses', 'standard lenses']

export const PRODUCT_TYPES_KEYS = {
  CONTACT_LENSES: 'contact-lenses',
  OPTICAL: 'optical',
  FRAMES: 'frames',
  ACCESSORIES: 'accessories',
  CONTACT_LENSES_ACCESSORIES: 'contact-lenses-accessories',
  SUN: 'sun',
  ELECTRONICS: 'electronics',
} as const

export const PRODUCT_TYPES_MAP = {
  'contact lenses': 'contact-lenses',
  'contact-lenses': 'contact-lenses',
  contactlenses: 'contact-lenses',
  frames: 'frames',
  accessories: 'accessories',
  'contact lenses accessories': 'contact-lenses-accessories',
  'contact-lenses-accessories': 'contact-lenses-accessories',
  contactlensesaccessories: 'contact-lenses-accessories',
  optical: 'optical',
  sun: 'sun',
  electronics: 'electronics',
} as const

export const LX_SEARCH_PAGE_PRODUCT_TYPE = 'LX_SEARCH_PAGE_PRODUCT_TYPE'
export const SKU_TYPE = 'SKU_TYPE'

export const MOCKED_REVIEWS: TrustPilotProductAPI.Root = {
  productReviews: [
    {
      id: '507f191e810c19729de860ea',
      createdAt: '2013-09-07T13:37:00',
      stars: 4,
      content: 'This product was nice, but slightly too soft.',
      consumer: {
        id: '507f191e810c19729de860ea',
        displayName: 'John Doe',
      },
      language: 'en',
      attributeRatings: [
        {
          attributeId: 'attributes.default.quality',
          attributeName: 'Quality',
          rating: 4,
        },
      ],
      attachments: [
        {
          id: '507f191e810c19729de860ea',
          state: 'Published',
          processedFiles: [
            {
              dimension: '1024pxWide',
              mimeType: 'image/png',
              url: 'https://productreviews-attachments.trustpilot.com/507f191e810c19729de860ea_1024pxWide.png',
            },
          ],
        },
      ],
    },
    {
      id: '507f191e810c19729de860ea',
      createdAt: '2013-09-07T13:37:00',
      stars: 4,
      content: 'This product was nice, but slightly too soft.',
      consumer: {
        id: '507f191e810c19729de860ea',
        displayName: 'John Doe',
      },
      language: 'en',
      attributeRatings: [
        {
          attributeId: 'attributes.default.quality',
          attributeName: 'Quality',
          rating: 4,
        },
      ],
      attachments: [
        {
          id: '507f191e810c19729de860ea',
          state: 'Published',
          processedFiles: [
            {
              dimension: '1024pxWide',
              mimeType: 'image/png',
              url: 'https://productreviews-attachments.trustpilot.com/507f191e810c19729de860ea_1024pxWide.png',
            },
          ],
        },
      ],
    },
    {
      id: '507f191e810c19729de860ea',
      createdAt: '2013-09-07T13:37:00',
      stars: 4,
      content: 'This product was nice, but slightly too soft.',
      consumer: {
        id: '507f191e810c19729de860ea',
        displayName: 'John Doe',
      },
      language: 'en',
      attributeRatings: [
        {
          attributeId: 'attributes.default.quality',
          attributeName: 'Quality',
          rating: 4,
        },
      ],
      attachments: [
        {
          id: '507f191e810c19729de860ea',
          state: 'Published',
          processedFiles: [
            {
              dimension: '1024pxWide',
              mimeType: 'image/png',
              url: 'https://productreviews-attachments.trustpilot.com/507f191e810c19729de860ea_1024pxWide.png',
            },
          ],
        },
      ],
    },
  ],
  links: [
    {
      href: '<Url for the resource>',
      method: '<Http method for the resource>',
      rel: '<Description of the relation>',
    },
  ],
}

export const MOCKED_SUMMARY_PRODUCTS: TrustPilotSummaryProductAPI.Root = {
  summaries: [
    {
      sku: 'ABCD-1234',
      numberOfReviews: {
        total: 14,
        oneStar: 2,
        twoStars: 3,
        threeStars: 2,
        fourStars: 5,
        fiveStars: 2,
      },
      starsAverage: 3.1,
    },
  ],
}

export const PRODUCT_URL_SEARCH_PARAMS = {
  ADD_LENS: 'addLens',
  EDIT_LENS: 'editLens',
  EDIT_SIZE: 'editSize',
  EDIT_CONTACT_LENS: 'editContactLens',
} as const

export const SUGGESTED_PRODUCT_FAMILY = {
  X_SELL_FAMILY: 'calculated-x-sell',
  CL_FAMILY: 'x-sell-family',
}

export const DEFAULT_PACKS_PER_EYE_FALLBACK = 1
export const DEFAULT_PACKS_MAX = 12

export const DEFAULT_CLACCESSORIES_MIN = 1
export const DEFAULT_CLACCESSORIES_MAX = 12
