import organizationService from '@foundation/apis/transaction/organization.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { LoginIdentityResponse } from '@typesApp/account'
import { IPayloadBasePromoCode } from '@typesApp/common'
import { IOrganizationSliceState } from '../IOrganizationSliceState'

export type IFetchEntitledOrganizationArgs = IPayloadBasePromoCode | LoginIdentityResponse | undefined

export const fetchEntitledOrganization = createAsyncThunk<IOrganizationSliceState, IFetchEntitledOrganizationArgs>(
  'organization/fetchEntitledOrganization',
  async (args, { rejectWithValue }) => {
    try {
      const response = await organizationService.getEntitledOrganizations(args)
      return response.data
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
