import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReturnCreateResponse, ReturnReason } from '@typesApp/orderReturn'
import { orderReturnApi } from './query'

export interface OrderReturnState {
  orderId: string
  checkedItems?: string[]
  returnReasons?: Map<string, ReturnReason>
  labelType: string
  returnCreateResponse?: ReturnCreateResponse
  isFetchingReturnReasons: boolean
}

const initialState: OrderReturnState = {
  orderId: '',
  checkedItems: [],
  labelType: '',
  isFetchingReturnReasons: false,
}

const orderReturnSlice = createSlice({
  name: 'orderReturn',
  initialState,
  reducers: {
    setReturnOrderId: (state, param: PayloadAction<string>) => {
      const { payload } = param
      state.orderId = payload
    },
    addCheckedItem: (state, param: PayloadAction<string>) => {
      const { payload } = param
      state.checkedItems = state.checkedItems && [...state.checkedItems, payload]
    },
    removeCheckedItem: (state, param: PayloadAction<string>) => {
      const { payload } = param
      const newState = state.checkedItems && [...state.checkedItems].filter(item => item !== payload)
      state.checkedItems = newState && [...newState]
    },
    setReturnReasons: (state, param: PayloadAction<Map<string, ReturnReason>>) => {
      const { payload } = param
      state.returnReasons = { ...payload }
    },
    setLabelType: (state, param: PayloadAction<string>) => {
      const { payload } = param
      state.labelType = payload
    },
    setCreateResponse: (state, param: PayloadAction<ReturnCreateResponse>) => {
      const { payload } = param
      state.returnCreateResponse = payload
    },
    resetReturn: state => {
      state.orderId = initialState.orderId
      state.checkedItems = initialState.checkedItems
      state.returnReasons = initialState.returnReasons
      state.labelType = initialState.labelType
      state.returnCreateResponse = initialState.returnCreateResponse
    },
  },
  extraReducers: builder => {
    builder.addMatcher(orderReturnApi.endpoints.getReturnItemDetails.matchPending, state => {
      state.isFetchingReturnReasons = true
    })
    builder.addMatcher(orderReturnApi.endpoints.getReturnItemDetails.matchRejected, state => {
      state.isFetchingReturnReasons = false
    })
    builder.addMatcher(orderReturnApi.endpoints.getReturnItemDetails.matchFulfilled, state => {
      state.isFetchingReturnReasons = false
    })
  },
})

const { actions, reducer } = orderReturnSlice
export const {
  setReturnOrderId,
  addCheckedItem,
  removeCheckedItem,
  setReturnReasons,
  setLabelType,
  setCreateResponse,
  resetReturn,
} = actions
export { reducer as orderReturnReducer }
