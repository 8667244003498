/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import RequestService, { RequestProps } from '../../../services/RequestService'

const userContextService = {
  /**
   * Gets the context data by the cookies and tokens sent with the request.
   * `@method`
   * `@name UserContext#getContextData`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  get(queryParams: RequestProps['queryParams']) {
    const params = { ...queryParams }
    delete params.WCToken
    delete params.WCTrustedToken
    return RequestService.requestForRtk({
      method: 'GET',
      path: '/store/{storeId}/usercontext/@self/contextdata',
      queryParams: params,
      pathParams: {
        storeId: params.storeId,
        langId: params.langId,
      },
    })
  },
}

export default userContextService
