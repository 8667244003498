import React, { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from 'react'
import { IOrderItem } from '../../types/order'
import { IProduct } from '../../types/product'

type ContextType = {
  isOpenPrescriptionLensesForm: boolean
  isLoadingConfigurator: boolean
  setPrescriptionLensesFormOpen(value: boolean): void
  toggleLoadingConfigurator: () => void
  prescriptionLenses: IProduct | IOrderItem | null
  setPrescriptionLenses: Dispatch<SetStateAction<IProduct | IOrderItem | null>>
}

const _throw = (m: string) => {
  throw new Error(m)
}

const PrescriptionLensesContext = createContext<ContextType | undefined>(undefined)

const PrescriptionLensesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpenPrescriptionLensesForm, setIsOpenPrescriptionLensesForm] = useState<boolean>(false)
  const [isLoadingConfigurator, setIsLoadingConfigurator] = useState<boolean>(false)

  const [prescriptionLenses, setPrescriptionLenses] = useState<IProduct | IOrderItem | null>(null)

  const toggleLoadingConfigurator = () => setIsLoadingConfigurator(v => !v)

  return (
    <PrescriptionLensesContext.Provider
      value={{
        isLoadingConfigurator,
        isOpenPrescriptionLensesForm,
        setPrescriptionLensesFormOpen: setIsOpenPrescriptionLensesForm,
        toggleLoadingConfigurator,

        prescriptionLenses,
        setPrescriptionLenses,
      }}
    >
      {children}
    </PrescriptionLensesContext.Provider>
  )
}

const usePrescriptionLenses = () => {
  const context = useContext(PrescriptionLensesContext)

  return context || _throw('Init PrescriptionLensesContext first.')
}

export default PrescriptionLensesProvider

export { usePrescriptionLenses }
