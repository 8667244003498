//Standard libraries
import { createReducer, AnyAction } from '@reduxjs/toolkit'
//Redux
import initStates from './initStates'
import { SET_FORTER_WEB_ID_ACTION } from '../actions/forterWebId'
const forterWebIdReducer = createReducer(initStates.forterWebId, (builder) => {
  builder.addCase(SET_FORTER_WEB_ID_ACTION, (state: { value: string }, action: AnyAction) => {
    state.value = action.payload || ''
  })
})
export default forterWebIdReducer
