import { PayloadAction, createSlice } from '@reduxjs/toolkit'
export interface CheckoutState {
  billingAddressId: string | null
  shippingAddressId: string | null
  paymentMethodId: string | null
  walletId: string | null
}

export const initialState: CheckoutState = {
  billingAddressId: '',
  shippingAddressId: '',
  paymentMethodId: '',
  walletId: '',
}

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    updateBillingAddressId(state, action: PayloadAction<string>) {
      state.billingAddressId = action.payload
    },
    updateShippingAddressId(state, action: PayloadAction<string>) {
      state.shippingAddressId = action.payload
    },
    updateWalletId(state, action: PayloadAction<string>) {
      state.walletId = action.payload
    },
    updatePaymentMethodId(state, action: PayloadAction<string>) {
      state.paymentMethodId = action.payload
    },
    resetCheckout: () => initialState,
  },
})

export const { updateBillingAddressId, updateShippingAddressId, updatePaymentMethodId, updateWalletId, resetCheckout } =
  checkoutSlice.actions

export default checkoutSlice.reducer
