//Standard libraries
import { AnyAction, createReducer } from '@reduxjs/toolkit'
//Redux
import { IOrganizationSliceState } from '@features/organization/IOrganizationSliceState'
import {
  CONFIRMATION_CANCELLED_ACTION,
  CONFIRMATION_HANDLED_ACTION,
  OPEN_CONFIRMATION_ACTION,
} from '../actions/confirmation'
import initStates from './initStates'
/**
 * confirmation reducer
 */
const confirmationReducer = createReducer(initStates.confirmation, builder => {
  builder.addCase(OPEN_CONFIRMATION_ACTION, (state: IOrganizationSliceState | any, action: AnyAction) => {
    Object.assign(state, action.payload)
  })
  builder.addCase(CONFIRMATION_HANDLED_ACTION, (state: IOrganizationSliceState | any) => {
    for (const variableKey in state) {
      if (state.hasOwnProperty(variableKey)) {
        delete state[variableKey]
      }
    }
  })
  builder.addCase(CONFIRMATION_CANCELLED_ACTION, (state: IOrganizationSliceState | any) => {
    for (const variableKey in state) {
      if (state.hasOwnProperty(variableKey)) {
        delete state[variableKey]
      }
    }
  })
})
export default confirmationReducer
