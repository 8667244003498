/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosPromise, AxiosRequestConfig, Method } from 'axios'

import { CartPayload } from '../../../types/cart'
import { CheckoutPayload } from '../../../types/checkout'
import RequestService from '../../../services/RequestService'
//Foundation libraries
import { executeRequest } from '../../axios/axiosConfig'
import { getCookieByName } from '../../../utils/cookie'
import { getSite } from '../../hooks/useSite'
import { site as siteConstants } from '@foundation/constants/site'

/**
 * @deprecated Use Redux Toolkit Query instead
 */
const cartService = {
  /**
   * Gets order details for the shopping cart.
   * `@method`
   * `@name Cart#getCart`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {integer} pageNumber ` Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work.
   ** `@property {integer} pageSize ` Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work.
   ** `@property {string} currency ` The currency code to use. Example usage : currency=USD. If no currency code is specified, the store default currency shall be used.
   ** `@property {string} sortOrderItemBy ` The order the results are sorted by ie:orderItemID
   ** `@property {string} profileName ` Profile name. Profiles determine the subset of data to be returned by a query.
   */
  getCart(payload: CartPayload) {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'GET',
      path: '/store/{storeId}/cart/@self',
      pathParams: {
        storeId: payload.storeId,
      },
    })
  },

  /**
   * Copies a specified order.
   * `@method`
   * `@name Cart#copyOrder`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {any} body ` The request object for copy order.
   */
  copyOrder(payload: any) {
    return RequestService.request({
      body: payload,
      method: 'POST',
      path: '/store/{storeId}/cart/copy_order',
      pathParams: { storeId: payload.storeId },
    })
  },

  /**
   * Deletes the specified order item from the order.
   * `@method`
   * `@name Cart#deleteOrderItem`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {any} body ` The request body for deleting an order item.
   */
  deleteOrderItem(payload: CartPayload) {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'PUT',
      path: '/store/{storeId}/cart/@self/delete_order_item',
    })
  },

  /**
   * Gets usable shipping information for the shopping cart.
   * `@method`
   * `@name Cart#getUsableShippingInfo`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {integer} pageNumber ` Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work.
   ** `@property {integer} pageSize ` Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work.
   ** `@property {string} orderId ` The order ID.
   */
  getUsableShippingInfo(payload: CheckoutPayload) {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'GET',
      path: '/store/{storeId}/cart/@self/usable_shipping_info',
      pathParams: { storeId: payload.storeId },
    })
  },

  /**
   * Gets usable shipping information for the shopping cart by address.
   * `@method`
   * `@name Cart#getUsableShippingMode`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {integer} pageNumber ` Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work.
   ** `@property {integer} pageSize ` Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work.
   ** `@property {string} orderId ` The order ID.
   */
  getUsableShippingMode(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/@self/usable_shipping_mode'
    let requestUrl = domain + path
    const method: Method = 'GET'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/@self/usable_shipping_mode missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageNumber'] !== undefined) {
      const name = 'pageNumber'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageSize'] !== undefined) {
      const name = 'pageSize'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['orderId'] !== undefined) {
      const name = 'orderId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Gets usable payment information for the shopping cart.
   * `@method`
   * `@name Cart#getUsablePaymentInfo`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {integer} pageNumber ` Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work.
   ** `@property {integer} pageSize ` Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work.
   ** `@property {string} orderId ` The order ID.
   */
  getUsablePaymentInfo(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/@self/usable_payment_info'
    let requestUrl = domain + path
    const method: Method = 'GET'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/@self/usable_payment_info missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageNumber'] !== undefined) {
      const name = 'pageNumber'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageSize'] !== undefined) {
      const name = 'pageSize'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['orderId'] !== undefined) {
      const name = 'orderId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  // XXX: let's use RequestService instead of this bounce of code - DCW-5447
  createCart(parameters: any) {
    return RequestService.request({
      body: parameters,
      method: 'POST',
      path: '/store/{storeId}/cart/createCart',
    })
  },

  // XXX: let's use RequestService instead of this bounce of code - DCW-5447
  // createCart(parameters: any): AxiosPromise<any> {
  //   let site = getSite()
  //   let siteContext: string = ''
  //   if (site) {
  //     siteContext = site.transactionContext || ''
  //   }
  //   let domain = siteContext
  //   let path = '/store/{storeId}/cart/createCart'
  //   let requestUrl = domain + path
  //   let method: Method = 'POST'
  //   let form: any = {}
  //   let body = {}
  //   let header: Headers = new Headers()
  //   let queryParameters = new URLSearchParams()

  //   if (parameters === undefined) {
  //     parameters = {}
  //   }

  //   let headerValues: any = {}
  //   headerValues['Accept'] = [
  //     'application/json',
  //     'application/xml',
  //     'application/xhtml+xml',
  //     'application/atom+xml',
  //   ]
  //   for (let val of headerValues['Accept']) {
  //     header.append('Accept', val)
  //   }
  //   if (site?.storeID === undefined) {
  //     throw new Error(
  //       'Request /store/{storeId}/cart/create missing path parameter storeId'
  //     )
  //   }
  //   requestUrl = requestUrl.replace('{storeId}', site?.storeID)

  //   if (!header.get('Content-Type')) {
  //     header.append('Content-Type', 'application/json; charset=utf-8')
  //   }
  //   const accept = header.get('Accept')
  //   if (accept !== null && accept.indexOf('application/json') > -1) {
  //     header.set('Accept', 'application/json')
  //   }
  //   if (
  //     header.get('content-type') === 'multipart/form-data' &&
  //     Object.keys(form).length > 0
  //   ) {
  //     let formData = new FormData()
  //     for (let p in parameters) {
  //       if (parameters[p].name !== undefined) {
  //         formData.append(p, parameters[p], parameters[p].name)
  //       } else {
  //         formData.append(p, parameters[p])
  //       }
  //     }
  //     body = formData
  //   } else if (Object.keys(parameters).length > 0) {
  //     header.set('content-type', 'application/x-www-form-urlencoded')
  //     body = parameters
  //   }

  //   const headerObject: any = {}
  //   for (let headerPair of header.entries()) {
  //     headerObject[headerPair[0]] = headerPair[1]
  //   }
  //   queryParameters.sort()
  //   const { userAgent } = window.navigator
  //   const forterToken = getCookieByName('forterToken')

  //   body = { ...body, userAgent, forterToken }
  //   let requestOptions: AxiosRequestConfig = Object.assign(
  //     {
  //       params: queryParameters,
  //       method: method,
  //       headers: headerObject,
  //       data: body,
  //       url: requestUrl,
  //     },
  //     { ...parameters }
  //   )

  //   return executeRequest(requestOptions)
  // },

  /**
   * Adds one or more order items to the shopping cart.
   * `@method`
   * `@name Cart#addOrderItem`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {any} body ` The request object for AddOrderItem.
   */
  addOrderItem(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart'
    let requestUrl = domain + path
    const method: Method = 'POST'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Updates one or more order items in the shopping cart.
   * `@method`
   * `@name Cart#updateOrderItem`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {any} body ` Update order item body.
   */
  updateOrderItem(payload: CartPayload) {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken, ...payload.body },
      method: 'PUT',
      path: '/store/{storeId}/cart/@self/update_order_item',
    })
  },

  /**
   * Prepares the shopping cart for checkout. This method must be called before the checkout service.
   * `@method`
   * `@name Cart#preCheckout`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {any} body ` The request object for preCheckout.
   */
  preCheckout(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/@self/precheckout'
    let requestUrl = domain + path
    const method: Method = 'PUT'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/@self/precheckout missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    queryParameters.sort()
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Checks out the shopping cart.
   * `@method`
   * `@name Cart#checkOut`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {any} body ` The request object for checkout.
   */
  checkOut(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/@self/checkout'
    let requestUrl = domain + path
    const method: Method = 'POST'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/@self/checkout missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Locks the shopping cart by a CSR.
   * `@method`
   * `@name Cart#lockCart`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} cartId (required)` The child property of `Parameters`.Order identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  lockCart(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/{cartId}/lock'
    let requestUrl = domain + path
    const method: Method = 'POST'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/{cartId}/lock missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['cartId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/{cartId}/lock missing path parameter cartId')
    }
    requestUrl = requestUrl.replace('{cartId}', parameters['cartId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )
    return executeRequest(requestOptions)
  },

  /**
   * Locks the shopping cart when the buyer administrator/CSR has established a session to act on behalf of a user.
   * `@method`
   * `@name Cart#lockCartOnBehalf`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} cartId (required)` The child property of `Parameters`.Order identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {string} forUser ` User name to act on behalf of.
   ** `@property {string} forUserId ` User identifier to act on behalf of.
   */
  lockCartOnBehalf(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/{cartId}/lockOnBehalf'
    let requestUrl = domain + path
    const method: Method = 'POST'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/{cartId}/lockOnBehalf missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['cartId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/{cartId}/lockOnBehalf missing path parameter cartId')
    }
    requestUrl = requestUrl.replace('{cartId}', parameters['cartId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['forUser'] !== undefined) {
      const name = 'forUser'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['forUserId'] !== undefined) {
      const name = 'forUserId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Unlocks the shopping cart by a CSR.
   * `@method`
   * `@name Cart#unlockCart`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} cartId (required)` The child property of `Parameters`.Order identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  unlockCart(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/{cartId}/unlock'
    let requestUrl = domain + path
    const method: Method = 'POST'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/{cartId}/unlock missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['cartId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/{cartId}/unlock missing path parameter cartId')
    }
    requestUrl = requestUrl.replace('{cartId}', parameters['cartId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Unlocks the shopping cart when the buyer administrator/CSR has established a session to act on behalf of a user.
   * `@method`
   * `@name Cart#unlockCartOnBehalf`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} cartId (required)` The child property of `Parameters`.Order identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {string} forUser ` User name to act on behalf of.
   ** `@property {string} forUserId ` User identifier to act on behalf of.
   */
  unlockCartOnBehalf(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/{cartId}/unlockOnBehalf'
    let requestUrl = domain + path
    const method: Method = 'POST'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/{cartId}/unlockOnBehalf missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['cartId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/{cartId}/unlockOnBehalf missing path parameter cartId')
    }
    requestUrl = requestUrl.replace('{cartId}', parameters['cartId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['forUser'] !== undefined) {
      const name = 'forUser'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['forUserId'] !== undefined) {
      const name = 'forUserId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Cancel the apple pay order.
   * `@method`
   * `@name Cart#cancelApplePayOrder`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  cancelApplePayOrder(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/@self/applepay_cancel'
    let requestUrl = domain + path
    const method: Method = 'POST'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/@self/applepay_cancel missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Update the apple pay order.
   * `@method`
   * `@name Cart#updateApplePayOrder`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {any} body ` The request object for Apple pay order update.
   */
  updateApplePayOrder(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/@self/applepay_update'
    let requestUrl = domain + path
    const method: Method = 'PUT'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/@self/applepay_update missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Gets buyer purchase order information for buyer purchase order ID.
   * `@method`
   * `@name Cart#getBuyerPurchaseOrderDataBean`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} buyerPurchaseOrderId (required)` The child property of `Parameters`.The buyer purchase order identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {string} profileName ` Profile name. Profiles determine the subset of data to be returned by a query.
   */
  getBuyerPurchaseOrderDataBean(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const site = getSite()
    const siteContext: string = siteConstants.transactionContextUrl || ''
    const domain = url || siteContext
    const path = '/store/{storeId}/cart/@self/buyer_purchase_order/{buyerPurchaseOrderId}'
    let requestUrl = domain + path
    const method: Method = 'GET'
    const form: any = {}
    let body = {}
    let header: Headers
    const queryParameters = new URLSearchParams()
    const formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    const headerValues: any = {}
    headerValues['Accept'] = ['application/json', 'application/xml', 'application/xhtml+xml', 'application/atom+xml']
    for (const val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/cart/@self/buyer_purchase_order/{buyerPurchaseOrderId} missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['buyerPurchaseOrderId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/cart/@self/buyer_purchase_order/{buyerPurchaseOrderId} missing path parameter buyerPurchaseOrderId'
      )
    }
    requestUrl = requestUrl.replace('{buyerPurchaseOrderId}', parameters['buyerPurchaseOrderId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['profileName'] !== undefined) {
      const name = 'profileName'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach(value => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (const headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    queryParameters.sort()
    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },
}

export default cartService
