//Standard libraries
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { FOR_USER_ID, LANGID, SKIP_CREDENTIALS, SKIP_WC_TOKEN_HEADER, WC_PREVIEW_TOKEN } from '../constants/common'
import { localStorageUtil, storageSessionHandler, storageStoreIdHandler } from '../utils/storageUtil'

//Custom libraries
import { CommerceEnvironment } from '../../constants/common'
import { PERSONALIZATION_ID } from '../constants/user'
//Redux
//Foundation libraries
import { handleAxiosErrors } from '@features/error/thunks/handleAxiosErrors'
import { sendServerErrorEvent } from '@foundation/analytics/tealium/lib'
import i18n from 'i18next'
// import { parse as losslessParse } from 'lossless-json'
import { INTERNAL_SERVER_ERROR, INTERNAL_SERVER_UNAVAILABLE_ERROR, NOT_FOUND } from '@utils/httpStatusCodes'
import config from '../../configs'
import { axiosHeaderIgnoredServices } from '../configs/axiosHeaderIgnoredService'
import { site } from '../constants/site'
import * as Sentry from '@sentry/react'
import { isClientSide } from '@utils/helpers'

const isServiceInList = (request: AxiosRequestConfig, serviceList: string[]) => {
  const url = request.url === undefined ? '' : request.url
  if (url.length > 0) {
    const storePath = `${site.transactionContextUrl}/store/`
    const path = url.split(storePath).pop()
    if (path && path.length > 0) {
      const serviceName = path.split('/')[1]
      return serviceList.indexOf(serviceName) > -1
    }
  }
  return false
}

const dispatchObject = {
  _dispatch: null,
  set dispatch(dispatch: any) {
    this._dispatch = dispatch
  },
  get dispatch(): any {
    return this._dispatch
  },
}

const processForUserParameter = (params: URLSearchParams) => {
  // Log.debug('PROCESS FOR USER PARAMETER')
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  if (currentUser && currentUser.forUserId) {
    params.set(FOR_USER_ID, currentUser.forUserId)
  }
}

const processTransactionHeader = (header: any, disableWcTokens: boolean = false) => {
  if (typeof window === 'undefined' || typeof sessionStorage === 'undefined') return
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()

  if (!disableWcTokens || (currentUser && currentUser?.isAutologin) || localStorage.beHalfTrue === 'true') {
    if (!header['WCTrustedToken'] && currentUser?.WCTrustedToken) {
      header['WCTrustedToken'] = currentUser.WCTrustedToken
    }
    if (!header['WCToken'] && currentUser?.WCToken) {
      header['WCToken'] = currentUser.WCToken
    }
  }

  if (currentUser) {
    // TODO: uncomment it when lux api allows make a call with WCPersonalization header!!!
    if (!header['WCPersonalization'] && currentUser.personalizationID) {
      header['WCPersonalization'] = currentUser.personalizationID
    }
  }
  // TODO: uncomment it when lux api allows make a call with WCPersonalization header!!!
  if (!header['WCPersonalization']) {
    const personalizationID = localStorageUtil.get(PERSONALIZATION_ID)
    if (personalizationID !== null && personalizationID !== undefined) {
      header['WCPersonalization'] = personalizationID
    }
  }
  const previewToken = storageSessionHandler.getPreviewToken()
  if (previewToken && previewToken[WC_PREVIEW_TOKEN]) {
    header['WCPreviewToken'] = previewToken[WC_PREVIEW_TOKEN]
  }
}

const processSearchHeader = (header: any, disableWcTokens: boolean = false) => {
  if (typeof window === 'undefined' || typeof sessionStorage === 'undefined') return
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()

  if (!disableWcTokens || (currentUser && currentUser?.isAutologin) || localStorage.beHalfTrue === 'true') {
    if (!header['WCTrustedToken'] && currentUser?.WCTrustedToken) {
      header['WCTrustedToken'] = currentUser.WCTrustedToken
    }
    if (!header['WCToken'] && currentUser?.WCToken) {
      header['WCToken'] = currentUser.WCToken
    }
  }
  const previewToken = storageSessionHandler.getPreviewToken()
  if (previewToken && previewToken[WC_PREVIEW_TOKEN]) {
    header['WCPreviewToken'] = previewToken[WC_PREVIEW_TOKEN]
  }
}

const useSnackbarHandleError = (error: AxiosError) => {
  if (error.config) {
    const { skipErrorSnackbar } = error.config as any
    if (
      skipErrorSnackbar === true &&
      error.response &&
      error.response.status < INTERNAL_SERVER_ERROR
      // status 500 and above will be handled by snackbar
    ) {
      return false
    }
  }
  return !(error.isAxiosError && error.response && error.response.status === NOT_FOUND)
}

const initAxios = (dispatch: any, disableWcTokens: boolean = false) => {
  dispatchObject.dispatch = dispatch

  Axios.defaults.withCredentials = isClientSide()

  Axios.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      request.withCredentials = request?.[SKIP_CREDENTIALS] ? false : true

      if (process.env.NODE_ENV === 'development' && config?.akamaiBypassKey) {
        request.headers['X-Akamai-Bot'] = config.akamaiBypassKey
      }

      if (
        request.url?.startsWith(site.transactionContextUrl) &&
        !isServiceInList(request, axiosHeaderIgnoredServices)
      ) {
        const header = request.headers
        if (!request[SKIP_WC_TOKEN_HEADER]) {
          processTransactionHeader(header, disableWcTokens)
        }
      }
      if (request.url?.startsWith(site.searchContextUrl)) {
        const header = request.headers
        processSearchHeader(header, disableWcTokens)
      }

      return request
    },
    function (error: any) {
      return Promise.reject(error)
    }
  )
  Axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    function (error: AxiosError) {
      const errorStatus = error.response?.status
      // If there was no error response, the reason may be a CORS error.
      const noResponseError = !error.response

      if (
        errorStatus !== NOT_FOUND &&
        errorStatus !== INTERNAL_SERVER_ERROR &&
        errorStatus !== INTERNAL_SERVER_UNAVAILABLE_ERROR &&
        !error?.config?.skipServerErrorEvent &&
        !noResponseError
      ) {
        sendServerErrorEvent(error)
        Sentry.captureException(error)
      }

      if (useSnackbarHandleError(error)) {
        dispatch(handleAxiosErrors(error))
      }
      return Promise.reject(error)
    }
  )
}

const executeRequest = async <T = any>(request: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  request.timeout = config.apiCalltimeout
  const params: URLSearchParams = request?.params || new URLSearchParams()
  processForUserParameter(params)

  //verify active storeId in localStorage.
  storageStoreIdHandler.verifyActiveStoreId()
  if (params && !params.has(LANGID)) {
    // add language Id
    const langId = CommerceEnvironment.reverseLanguageMap[(i18n as any).logger.options.lng.split('-').join('_')]
    params.set(LANGID, langId)
  }

  request.params = params
  // if (isNumberParserRequiredService(request)) {
  //   request.transformResponse = [transformNumberResponse]
  // }

  return Axios(request)
}

export { executeRequest, initAxios }
