import { site } from '@foundation/constants/site'
import axios from 'axios'
import { RX_CONF_SELECTOR } from '../../../constants/common'
import { SiteInfo } from '../../../redux/reducers/reducerStateInterface'
import { IProduct } from '../../../types/product'
import { LensDataResponse, PrescriptionDetailsBody, RXFileLink } from '../../../types/rxConfigurator'
import { getIsRoxable } from '../../../utils/productAttributes'

class rxService {
  /**
   *
   * @param siteInfo
   * @param id product id
   * @returns lenseData to be merged with the config object of rx Configurator widget.
   */
  getLensesData(siteInfo: SiteInfo, id: string | null) {
    const { storeID } = siteInfo

    return axios.get<LensDataResponse>(`${site.transactionContextUrl}/store/${storeID}/rox/lensesData/${id}`)
  }

  async uploadPrescriptionFile(siteInfo: SiteInfo, formData: FormData) {
    const { storeID } = siteInfo

    return await axios.post(`${site.transactionContextUrl}/store/${storeID}/prescription/uploadFile`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  async addPrescriptionDetails(siteInfo: SiteInfo, body: PrescriptionDetailsBody) {
    const { storeID } = siteInfo

    return await axios.post(`${site.transactionContextUrl}/store/${storeID}/prescription/prescriptionDetails`, body)
  }

  async uploadFileFromConfigurator(siteInfo: SiteInfo, formData: FormData) {
    const { storeID } = siteInfo

    return await axios.post(`${site.transactionContextUrl}/store/${storeID}/prescription/storeRXFile`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  async downloadPrescriptionFile(siteInfo: SiteInfo, rxFileStorageId: string) {
    const { storeID } = siteInfo

    return await axios.get(
      `${site.transactionContextUrl}/store/${storeID}/prescription/rxFileStorageId/${rxFileStorageId}`,
      {
        responseType: 'arraybuffer',
      }
    )
  }

  async linkRXFileWithOrder(siteInfo: SiteInfo, body: RXFileLink) {
    const { storeID } = siteInfo

    return await axios.post(`${site.transactionContextUrl}/store/${storeID}/prescription/uploadRXFile`, body)
  }

  isProductRoxable(isRXEnabled: boolean, product: Pick<IProduct, 'productAttributes'>): boolean {
    return isRXEnabled && getIsRoxable(product)
  }

  closeConfigurator() {
    if (window.RXC) {
      window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
    }
  }
}
const rxServiceInstance = new rxService()
export default rxServiceInstance
