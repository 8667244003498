import { getFrontColor, getLensesColor, getLensesColorFacet, getProductType } from '@utils/productAttributes'
import { IProduct, ProductType } from '../types/product'

import { PRODUCT_TYPES_MAP } from '../constants/product'
import { IFacet } from '../features/plp/query'
import CurrencyService from '../services/CurrencyService'

/**
 * @deprecated
 */
class ProductUtils {
  /**
   * @deprecated move to common product utils
   */
  getProductType(product?: IProduct): ProductType {
    return product ? PRODUCT_TYPES_MAP[getProductType(product)?.toLowerCase()] : PRODUCT_TYPES_MAP.frames
  }
  /**
   * @deprecated move to common product utils
   */
  getProductColorLabel(product: IProduct): string {
    const productType = this.getProductType(product)

    switch (productType) {
      case PRODUCT_TYPES_MAP.accessories:
        return getFrontColor(product)
      case PRODUCT_TYPES_MAP.optical:
        return getFrontColor(product)
      case PRODUCT_TYPES_MAP.sun:
        const frontColor = getFrontColor(product)
        const lensColor = getLensesColor(product) || getLensesColorFacet(product)

        return frontColor === lensColor ? frontColor : `${frontColor} ${lensColor}`.trim()
      default:
        return ''
    }
  }

  /**
   * @deprecated
   */
  findPriceFacetByName(name: string) {
    return name.startsWith('OfferPrice_')
  }

  /**
   * @deprecated
   */
  formatPriceFacet(priceFacet: IFacet, rangeStartPrefix: string, rangeEndPrefix: string): IFacet {
    const priceCurrencyName = priceFacet.value.replace('price_', '').toUpperCase()
    const currencySymbol = CurrencyService.getSymbolByName(priceCurrencyName)

    return {
      ...priceFacet,
      entry: priceFacet.entry.map(v => ({
        ...v,
        count: `${v.count}`,
        label: v.label.replace(/\({([\*\d.]*)\sTO\s([\*\d.\s]*)]\)/g, (_match, rangeStart, rangeEnd) => {
          if (rangeStart.trim() === '*') {
            return `${rangeStartPrefix} ${currencySymbol + rangeEnd}`
          } else if (rangeEnd.trim() === '*') {
            return `${rangeEndPrefix} ${currencySymbol + rangeStart}`
          } else {
            return `${currencySymbol + rangeStart} - ${currencySymbol + rangeEnd}`
          }
        }),
      })),
    }
  }
}
const productUtils = new ProductUtils()

/**
 * @deprecated
 */
export default productUtils
