import { PRODUCT_LIST_FIELDS } from '@configs/catalog'
import { BREADCRUMB_PROFILE } from '@constants/common'
import { RootReducerState } from '@redux/reducers'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '@services/AxiosBaseQuery'
import { BreadCrumbResponse, PaginationResponse } from '@services/RequestService'
import { IProduct } from '@typesApp/product'
import { omit } from '@utils/helpers'
import { transformResponseBreadCrumb, transformResponseProductList, transformResponseUpdateFacets } from './utils'
export interface IPlpProductResponse {
  facets: IFacet[]
  productList: IProduct[]
  productListTotal: number
  priceMode: string
  breadcrumbs: any[]
  facetsAlwaysEmpty: string[]
  currentPLPFacets: IFacet[]
  selectedFacets: Record<string, { facetName: string; label: string; value: string }>
}

export interface IPlpProduct {
  facets: IFacet[]
  productList: IProduct[]
  productListTotal: number
  priceMode: string
  breadcrumbs: any[]
  facetsAlwaysEmpty: string[]
  currentPLPFacets: IFacet[]
  selectedFacets: Record<string, { facetName: string; label: string; value: string }>
}

export interface IPlpBreadCrumb {
  breadcrumbs: any[]
}

export interface IFacet {
  name: string
  entry: IEntry[]
  value: string
  extendedData: any
}
export interface IEntry {
  label: string
  value: string
  attributeValueIdentifier: string
  count: number | string
  extendedData: {
    sequence: string
    uniqueId: string
  }
}

export interface IPlpFacetArgs {
  [key: string]: string[]
}

export interface IPlpProductArgs {
  currency?: string
  _fields?: string
  offset?: number
  page?: number
  limit?: number
  profileName?: string
  categoryId?: string
  query?: string
  searchTerm?: string
  sortBy?: string
  facet?: string[]
  priceFrom?: number
  priceTo?: number
  discount?: string
  range?: Record<string, string>
}

export interface IPlpBreadCrumbArgs {
  profileName?: string
  categoryId?: string
}

// Used for `useGetUpdatedFacetQuery` hook
export interface GetUpdatedFacetQueryParams extends IPlpProductArgs {
  categoryIdentifier: string // matches `Category['identifier']`, example value: 'dc_contactlenses'
}

export const plpApi = createApi({
  reducerPath: 'plpApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/v2/',
  }),
  endpoints: build => ({
    getBreadCrumb: build.query<IPlpBreadCrumb, IPlpBreadCrumbArgs>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const params: IPlpBreadCrumbArgs = {
          ...args,
          profileName: BREADCRUMB_PROFILE,
        }
        const result = await fetchWithBQ({
          url: 'products',
          method: 'get',
          queryParams: params || {},
          extraParams: { siteContextKey: 'search' },
        })
        return (result?.data as BreadCrumbResponse)
          ? {
              data: transformResponseBreadCrumb(result.data) as IPlpBreadCrumb,
            }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getProduct: build.query<IPlpProduct, IPlpProductArgs>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const defaultCurrencyID = state.site.currentSite?.defaultCurrencyID!
        const params: IPlpProductArgs = {
          ...args,
          currency: defaultCurrencyID,
          _fields: PRODUCT_LIST_FIELDS,
        }
        const result = await fetchWithBQ({
          url: 'products',
          method: 'get',
          queryParams: params || {},
          //body: body || {},
          extraParams: { siteContextKey: 'search' },
          pathParams: { storeId: state.site.currentSite?.storeID! },
        })
        return (result?.data as PaginationResponse)
          ? {
              data: transformResponseProductList(result.data, args) as IPlpProduct,
            }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getUpdatedFacet: build.query<IPlpProduct, GetUpdatedFacetQueryParams>({
      queryFn: async (args, queryApi, _extraOptions, fetchWithBQ) => {
        const state = queryApi.getState() as RootReducerState
        const defaultCurrencyID = state.site.currentSite?.defaultCurrencyID!
        const plpFacet = state.site.currentSite?.xStoreCfg?.plpFacet!
        const plpPriceFacet = state.site.currentSite?.xStoreCfg?.plpPriceFacets!

        const params: IPlpProductArgs = {
          currency: defaultCurrencyID,
          _fields: PRODUCT_LIST_FIELDS,
          ...omit(args, ['categoryIdentifier']),
        }
        const result = await fetchWithBQ({
          url: 'products',
          method: 'get',
          queryParams: params,
          extraParams: { siteContextKey: 'search' },
          pathParams: { storeId: state.site.currentSite?.storeID! },
        })
        return (result?.data as PaginationResponse)
          ? {
              data: transformResponseUpdateFacets(result.data, plpFacet, plpPriceFacet, args) as IPlpProduct,
            }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})
export const { useGetBreadCrumbQuery, useGetProductQuery, useGetUpdatedFacetQuery } = plpApi
