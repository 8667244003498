import debug from 'debug'
import config from '../configs'

const BASE = 'dcw-app'

type ErrorLevel = 'trace' | 'info' | 'warn' | 'error'

const COLOURS: Record<ErrorLevel, string> = {
  trace: 'lightblue',
  info: 'blue',
  warn: 'orange',
  error: 'red',
}

/** Generate a debug message */
const generateMessage = (
  // TODO: generate and send two separate func
  level: ErrorLevel,
  message: ErrorInput,
  source?: string,
  origin_url?: string
) => {
  // Set the prefix which will cause debug to enable the message
  const namespace = `${BASE}:${level}`
  const createDebug = debug(namespace)

  if (!origin_url) {
    if (typeof window !== 'undefined') {
      origin_url = window.location.href
    } else {
      origin_url = config.publicUrl
    }
  }

  // send log to api server for level > of trace...
  if (['trace', 'info'].indexOf(level) === -1 && config.log2api) {
    const msg = typeof message === 'string' ? message : message?.message || ''
    const body = JSON.stringify({
      level,
      origin_url,
      env: 'frontend-app',
      source,
      // Message sometime is an error object with message attribute
      message: `${msg}`,
    })
    const headers = {
      'Content-Type': 'application/json',
    }

    // fetch(config.logServerUrl, {
    //   method: 'POST',
    //   body,
    //   headers,
    // })
  }

  if (createDebug.enabled) {
    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level]

    if (source) {
      createDebug(source, origin_url, message)
    } else {
      createDebug(origin_url, message)
    }
  }
}

/**
 *
 * How to use:
 *  import Log from './libs/Log'
 *  Log.info('AAA', 'window.location.href','app.js');
 *
 * To enable logging is necessary to add an entry 'debug'
 * on browser's localStorage with this value:
 *
 *  [BASE]:<level|*>
 *
 * Level should be a value such as: trace | info | warn | error | *
 * in order to enable a specific logging level.
 *
 * further info: https://levelup.gitconnected.com/step-up-your-console-messaging-game-in-your-react-app-42eee17659ec
 */

type ErrorInput = { message: string } | string

class Log {
  static trace(message: ErrorInput, source?: string, origin_url?: string) {
    // TODO: use better interface to describe message
    return generateMessage('trace', message, source, origin_url)
  }

  static info(message: ErrorInput, source?: string, origin_url?: string) {
    return generateMessage('info', message, source, origin_url)
  }

  static warn(message: ErrorInput, source?: string, origin_url?: string) {
    return generateMessage('warn', message, source, origin_url)
  }

  static error(message: ErrorInput, source?: string, origin_url?: string) {
    return generateMessage('error', message, source, origin_url)
  }
}

export default Log
