import { Link } from '@components/common/Link/Link'
import { styled } from '@mui/material'
import { LinkTypeMap } from '@mui/material/Link'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export const StyledLink = styled(Link, {
  name: 'link',
  shouldForwardProp: prop => prop !== 'prefetch',
})<{ prefetch: boolean }>(({}) => ({
  cursor: 'pointer',
})) as unknown as OverridableComponent<LinkTypeMap>
