import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface Reminder {
  orderId: string
  reminderDate: string
}

export interface ReminderState {
  currentReminderDate: string | null
  reminders: Reminder[]
}

export const initialState: ReminderState = {
  currentReminderDate: null,
  reminders: [],
}

const reminderSlice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {
    addReminder: (state, action: PayloadAction<Reminder>) => {
      state.reminders.push(action.payload)
    },
    updateReminderDate: (state, action: PayloadAction<{ id: string; date: string }>) => {
      const { id, date } = action.payload
      const reminder = state.reminders.find(r => r.orderId === id)

      if (reminder) {
        reminder.reminderDate = date
      }

      state.currentReminderDate = date
    },
    clearReminderState: () => initialState,
  },
})

export const { addReminder, updateReminderDate, clearReminderState } = reminderSlice.actions
export default reminderSlice.reducer
