import personWalletService from '@foundation/apis/transaction/person.wallet.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import Log from '@services/Log'
import { IPayloadBasePromoCode } from '@typesApp/common'
import { PersonWalletResponse } from '@typesApp/user'
import { AxiosResponse } from 'axios'

export type IFetchUserWalletArgs = IPayloadBasePromoCode & { widget: string }

export const fetchUserWallet = createAsyncThunk<any, IFetchUserWalletArgs>(
  'user/fetchUserWallet',
  async (args, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<PersonWalletResponse> = await personWalletService.getWalletBySelf(args)
      return response.data
    } catch (e) {
      Log.error('error: ' + e)
      return rejectWithValue(e)
    }
  }
)
