import { IOrderSliceState } from '../../features/order/IOrderSliceState'
import {
  AccountReducerState,
  ApiReducerState,
  IdMeReducerState,
  ProductTypeFiltersState,
  SearchReducerState,
  ThemeReducerState,
} from './reducerStateInterface'

import { contextInitState } from '@features/context/initState'
import { contractInitState } from '@features/contract/initState'
import { errorInitState } from '@features/error/initState'
import { initialStateFrameGenuis } from '@features/frameGenius/slice'
import { orderDetailsInitState } from '@features/orderDetails/initState'
import { organizationInitState } from '@features/organization/initState'
import { userInitState } from '@features/user/initState'
import { RootReducerState } from '.'
import { initialCmsState } from '../../features/cms/slice'
import { OrderApiState } from '../../features/order/slice'
import { initialPlpState } from '../../features/plp/slice'
import { PrescriptionSliceState } from '../../features/prescription/PrescriptionSliceState'
import { PreviewState } from '../../features/preview/slice'
import { ProductApiState, ProductState } from '../../features/product/slice'
import { initialStoresState } from '../../features/stores/slice'
import { initialStateUi } from '../../features/ui/slice'
import { WishListState } from '../../features/wishList/slice'
import { initialState as CheckoutInitState } from '@redux/slices/checkout'
import { initialState as initialReminderState } from '@redux/slices/reminder'
import { initialState as PaymentsInitstate } from '../../features/payments/slice'
import { initialBancontactState } from '@features/bancontact/slice'

const accountDefaultState: AccountReducerState = {
  isFetching: false,
  orders: null,
}

const apiDefaultState: ApiReducerState = {
  apiFlowList: [],
}

export const PLP_DEFAULT_SORT_OPTION = {
  value: '',
  profileName: 'LX_findProductsByCategory_Grouping',
}

const orderDefaultState: IOrderSliceState = {
  isAddingItem: false,
  isFetching: false,
  cart: undefined,
  numItems: 0,
  orderItems: [],
  parsedOrderItems: {
    rx: null,
    cl: null,
    'cl-acc': null,
    sun: null,
    default: null,
  },
  catentries: undefined,
  isCheckoutDisabled: false,
  shipInfos: undefined,
  payMethods: [],
  isRecurringOrderDisabled: false,
  isFetchingShippingInfo: false,
  isShippingUsedAsBilling: true,
  shippingFormStatus: {
    isValid: false,
  },
  creditCardData: null,
  billingFormStatus: {
    isValid: false,
  },
  creditCardFormStatus: {
    isValid: false,
  },
  paypalExpress: {
    isSelected: false,
    loading: false,
    redirecturl: '',
    decision: '',
    orderId: '',
    error: true,
  },
  orderComplete: false,
  orderDetails: null,
  isFinalizing: false,
  isFinalizationFailed: false,
  selectedPayMethodInfoList: [],
  isRXProductsLimitExceeded: false,
  usePrescription: {
    selectedMacroIndex: 0,
    prescriptionMacroGroups: [],
    prescriptionFormData: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      file: '',
    },
  },
  cartShippingCharges: {
    shippingCharges: undefined,
    error: undefined,
  },
}

const searchDefaultState: SearchReducerState = {
  keywords: [],
}

const themeDefaultState: ThemeReducerState = {
  themeMode: 'light',
}

const productTypeFilters: ProductTypeFiltersState = {
  values: null,
  searchTerm: '',
}

const defaultStates: RootReducerState = {
  account: accountDefaultState,
  api: apiDefaultState,
  confirmation: { key: '', title: '' },
  context: contextInitState,
  contract: contractInitState,
  checkout: CheckoutInitState,
  reminder: initialReminderState,
  error: errorInitState,
  order: orderDefaultState,
  orderDetails: orderDetailsInitState,
  orderDetailsLoadings: {},
  organization: organizationInitState,
  success: { key: '' },
  seo: {},
  search: searchDefaultState,
  site: { currentSite: null, hostnameUrls: {}, hostname: '', locationOrigin: '' },
  theme: themeDefaultState,
  user: userInitState,
  wishList: {} as WishListState,
  preview: {} as PreviewState,
  product: {} as ProductState,
  productApi: {} as ProductApiState,
  orderApi: {} as OrderApiState,
  bancontact: initialBancontactState,
  bancontactApi: {} as any,
  //cmsApi: {} as ICms,
  cms: initialCmsState,
  plp: initialPlpState,
  ui: initialStateUi,
  prescription: {} as PrescriptionSliceState,
  stores: initialStoresState,
  productTypeFilters: productTypeFilters,
  forterWebId: { value: '' },
  frameGenius: initialStateFrameGenuis,
  idMe: {} as IdMeReducerState,
  orderReturn: { orderId: '', checkedItems: [], labelType: '', isFetchingReturnReasons: false },
  payments: PaymentsInitstate,
}

const clearState = (o: any) => {
  for (const variableKey in o) {
    if (o.hasOwnProperty(variableKey)) {
      delete o[variableKey]
    }
  }
}

const initStates = {
  ...defaultStates,
}

export { clearState, defaultStates }

export default initStates
