const store = process.env.NEXT_PUBLIC_STORENAME || 'visiondirect'
const loadTheme = async () => {
  return await import(`./themes/${store}-theme`)
    .then(module => module.default)
    .catch(error => {
      throw new Error(`Theme not found for ${store}`, error)
    })
}

const theme = await loadTheme()

export default theme
