import personService from '@foundation/apis/transaction/person.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import Log from '@services/Log'
import { IPayloadBasePromoCode } from '@typesApp/common'
import { PersonResponse } from '@typesApp/user'
import { AxiosResponse } from 'axios'
import { setUserDetails } from '../slice'

export type IFetchUserDetailsArgs = IPayloadBasePromoCode

export const fetchUserDetails = createAsyncThunk<any, IFetchUserDetailsArgs>(
  'user/fetchUserDetails',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const response: AxiosResponse<PersonResponse> = await personService.findPersonBySelf(args)
      dispatch(setUserDetails(response.data))
      return
    } catch (e) {
      Log.error('error: ' + e)
      return rejectWithValue(e)
    }
  }
)
