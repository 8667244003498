import { ProductTerms } from '@typesApp/Basket'
import { IOrderItem } from '@typesApp/order'
import { getProductType } from '@utils/productAttributes'

const CL_PRODUCT_TYPE = 'CL'
const CL_ACCESSORIES_PRODUCT_TYPE = 'CLAccessories'

/**
 * Capitalize first letter of a string
 * @param str string
 * @returns string
 */
export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return ''
  const [firstLetter, ...restOfTheString] = str
  return `${firstLetter.toUpperCase()}${restOfTheString.join('')}`
}

/**
 * Returns the terms key for the product type
 * @param productType product type
 * @returns productTermsMap key
 */
const getTermsKey = (productType: string): keyof ProductTerms => {
  const productTermsMap: { [key: string]: string } = {
    'Contact Lenses': CL_PRODUCT_TYPE,
    'contact-lenses': CL_PRODUCT_TYPE,
    'contact lenses': CL_PRODUCT_TYPE,
    contactlenses: CL_PRODUCT_TYPE,
    'Contact Lenses Accessories': CL_ACCESSORIES_PRODUCT_TYPE,
    'contact-lenses-accessories': CL_ACCESSORIES_PRODUCT_TYPE,
    'contact lenses accessories': CL_ACCESSORIES_PRODUCT_TYPE,
    accessories: CL_ACCESSORIES_PRODUCT_TYPE,
  }

  return productTermsMap[productType] as keyof ProductTerms
}

/**
 * Generates the product terms for the order item based on product type
 * @param orderItem IOrderItem
 * @param terms terms object (from i18n locale)
 * @returns string
 */
export const generateProductTerms = (orderItem: IOrderItem, terms: ProductTerms): string => {
  const productType = getProductType(orderItem)
  const termsKey = getTermsKey(productType)
  const { quantity } = orderItem
  const formattedQuantity = parseInt(quantity)

  const categoryTerms = terms[termsKey] ?? terms[CL_ACCESSORIES_PRODUCT_TYPE]

  const containerTerm = formattedQuantity === 1 ? categoryTerms?.Container?.singular : categoryTerms?.Container?.plural

  if (termsKey === CL_PRODUCT_TYPE) {
    const packSize = parseInt(orderItem.productAttributes['CL_PACK_SIZE']) ?? null
    const lensCount = packSize * formattedQuantity
    const lensTerm =
      lensCount === 1 ? categoryTerms?.Item?.singular : categoryTerms?.Item?.plural ?? categoryTerms?.Container?.plural

    return `${formattedQuantity} ${containerTerm} = ${lensCount} ${lensTerm}`
  }

  return `${formattedQuantity} ${containerTerm}`
}
