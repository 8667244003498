import { fetchUserContext } from '@features/context/thunks/fetchUserContext'
import { loginStatusSelector } from '@features/user/selector'
import switchContractService from '@foundation/apis/transaction/switchContract.service'
import { RootReducerState } from '@redux/reducers'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { LoginIdentityResponse } from '@typesApp/account'
import { IPayloadBasePromoCode } from '@typesApp/common'
import { contractSelector, currentEntitledContractsSelector } from '../selector'

export type IPreSelectContractArgs = IPayloadBasePromoCode | LoginIdentityResponse | undefined

export const preselectContract = createAsyncThunk<any, IPreSelectContractArgs>(
  'contract/preselectContract',
  async (args, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootReducerState
      //fetch first entitled contract and explicitly set it to entitled contract.
      const contracts = contractSelector(state)
      const entitledContracts: string[] = currentEntitledContractsSelector(state)
      const isLogin = loginStatusSelector(state)

      if (isLogin && entitledContracts.length !== 1) {
        const contract = Object.keys(contracts)[0]
        await switchContractService.changeContract({
          $queryParameters: { contractId: String(contract) },
          ...args,
        })
        dispatch(fetchUserContext(args))
      }
      return
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
