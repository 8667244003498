export const countryUtil = (country = '', langCode = '') => {
  const currentCountry = country.substr(3).toLowerCase()
  const currentLangCode = `${langCode.slice(0, 2)}-${langCode.slice(3, 5).toLowerCase()}`

  return {
    currentCountry,
    currentLangCode,
  }
}
export const localeLangCountryUtil = (langCode = '') => {
  const currentLang = langCode.split('-')
  const currentLangCountry = currentLang[0] + '_' + currentLang[1].toUpperCase()
  return currentLangCountry
}

// TODO: use i18n file directly, when it's complete
export const getCountryCodeFromLocale = (langCode: string): string => {
  const countryCode = langCode?.split('_')[1]

  return countryCode
}
