import { SiteInfoArgs, SiteInfoService } from './SiteInfoService'

//Standard libraries
//Redux
import { SiteInfo } from '../../../redux/reducers'
//Foundation libraries
import { useSite } from './useSite'
import { countryUtil } from '@utils/countryUtil'
import { CommerceEnvironment } from '@constants/common'

/**
 *
 */
function getSite(): SiteInfo | null {
  return SiteInfoService.getSiteInfo().getSiteValue()
}

function setData(s: SiteInfo) {
  return SiteInfoService.getSiteInfo().setData(s)
}

/**
 *
 */
function initSite(s: SiteInfoArgs, lang: string) {
  return SiteInfoService.getSiteInfo().setSite(s, lang)
}

function getSiteDate(locale: string, site: SiteInfoArgs) {
  const country = locale?.toLowerCase()
  const countryValues = CommerceEnvironment.localeLangMap

  const controlUrlCountry = countryValues.find(t => {
    const { currentLangCode } = countryUtil('', t.langCode)
    return country === currentLangCode
  })

  if (!controlUrlCountry) return Promise.resolve(undefined)

  return initSite(site, controlUrlCountry.langCode)
}

export { useSite, getSite, initSite, getSiteDate, setData }
