import { createSlice } from '@reduxjs/toolkit'
import { IBancontactInitialState } from './types'

export const initialBancontactState: IBancontactInitialState = {
  bancontactRedirectUrl: '',
  bancontactFormSubmitting: false,
}

export const bancontactSlice = createSlice({
  name: 'bancontact',
  initialState: initialBancontactState,
  reducers: {
    setBancontactRedirectUrl: (state: any, action) => {
      state.bancontactRedirectUrl = action.payload
    },
    setBancontactFormSubmitting: (state: any, action) => {
      state.bancontactFormSubmitting = action.payload
    },
  },
})

export const { setBancontactRedirectUrl, setBancontactFormSubmitting } = bancontactSlice.actions

export default bancontactSlice.reducer
