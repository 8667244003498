import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import * as ERRORS from '../../../constants/errors'
import {
  cmsApiErrorAction,
  handleConnectionTimedOutErrorAction,
  handleMigratedUserAction,
  handleSessionErrorAction,
} from '../slice'

export type IHandleAxiosErrorsArgs = AxiosError

let handlingError = false

export const handleAxiosErrors = createAsyncThunk<any, IHandleAxiosErrorsArgs>(
  'error/handleAxiosErrors',
  async (error, { dispatch }) => {
    const isLogoff =
      error.config &&
      error?.config?.url?.endsWith('loginidentity/@self') &&
      error?.config?.method?.toLowerCase() === 'delete'
    //ignore logoff error

    if (error.isAxiosError && !handlingError && !isLogoff) {
      handlingError = true
      const errorResponse = error.response
      const isCmsError = errorResponse?.data?.name === 'CMS'

      const ignoredUrls = ['direct-billing/auth/token', '/reorderreminder/', '/xStateProv']
      const isIgnoredUrlError =
        errorResponse?.config?.url && ignoredUrls.some(url => errorResponse?.config?.url?.includes(url))

      if (isIgnoredUrlError) {
        handlingError = false
        return
      }

      if (errorResponse && errorResponse.data && errorResponse.data.errors) {
        const e = errorResponse.data.errors[0]
        // handle expired session
        if (
          e.errorCode === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ||
          e.errorKey === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ||
          e.errorCode === ERRORS.PARTIAL_AUTHENTICATION_ERROR_CODE ||
          e.errorKey === ERRORS.PARTIAL_AUTHENTICATION_ERROR_KEY ||
          e.errorCode === ERRORS.ACTIVITY_TOKEN_ERROR_CODE ||
          e.errorKey === ERRORS.ACTIVITY_TOKEN_ERROR_KEY
        ) {
          const payload = {
            ...e,
            handled: false,
            errorTitleKey: 'SessionError.TimeoutTitle',
            errorMsgKey: 'SessionError.TimeoutMsg',
          }

          // eslint-disable-next-line no-console
          console.log('%c[SESSION ERROR] USER LOGGED OUT - ', 'color: red; font-weight: bold;', e.errorMessage)
          dispatch(handleSessionErrorAction(payload))
          handlingError = false
        }
        // handle invalid session where another user logged in from different location
        else if (e.errorCode === ERRORS.INVALID_COOKIE_ERROR_CODE && e.errorKey === ERRORS.INVALID_COOKIE_ERROR_KEY) {
          const payload = {
            ...e,
            handled: false,
            errorTitleKey: 'SessionError.InvalidTitle',
            errorMsgKey: 'SessionError.InvalidMsgB2C',
          }
          // eslint-disable-next-line no-console
          console.log('%c[SESSION ERROR] USER LOGGED OUT - ', 'color: red; font-weight: bold;', e.errorMessage)
          dispatch(handleSessionErrorAction(payload))
        }
        // handle password expired issue.
        else if (e.errorCode === ERRORS.PASSWORD_EXPIRED_ERR_CODE || e.errorKey === ERRORS.PASSWORD_EXPIRED) {
          //reset password dialog
          const payload = {
            ...e,
            handled: false,
            errorTitleKey: 'reset.title',
            errorMsgKey: 'reset.errorMsg',
          }
          // eslint-disable-next-line no-console
          console.log('%c[SESSION ERROR] USER LOGGED OUT - ', 'color: red; font-weight: bold;', e.errorMessage)
          dispatch(handleSessionErrorAction(payload))
        } else if (e.errorCode === ERRORS.MIGRATED_USER_ERR_CODE || e.errorKey === ERRORS.MIGRATED_USER_ERR_CODE) {
          const payload = {
            ...e,
            handled: false,
            errorTitleKey: 'migratedUser.title',
            errorMsgKey: 'migratedUser.errorMsg',
            passwordResetRequired: true,
          }
          dispatch(handleMigratedUserAction(payload))
        } else {
          //other errors
          const payload = {
            ...e,
            handled: false,
            errorTitleKey: 'SessionError.GenericTitle',
          }
          dispatch(handleSessionErrorAction(payload))
        }
      } else {
        if (error.code && error.code === ERRORS.CONNECTION_TIMEDOUT_ERROR) {
          const payload = {
            errorCode: error.code,
            errorMessage: error.toLocaleString(),
            errorParameters: {
              url: error.config?.url,
            },
            handled: false,
          }
          // eslint-disable-next-line no-console
          dispatch(handleConnectionTimedOutErrorAction(payload))
        } else if (isCmsError) {
          const payload = {
            errorMessage: `CMS ERROR - ${errorResponse.data?.message.toLocaleString()}`,
            handled: false,
          }
          dispatch(cmsApiErrorAction(payload))
        } else {
          const payload = {
            errorMessage: error.toLocaleString(),
            errorParameters: {
              url: error.config?.url,
            },
            handled: false,
          }
          // eslint-disable-next-line no-console
          console.log('%c[SESSION ERROR] USER LOGGED OUT - ', 'color: red; font-weight: bold;')
          dispatch(handleSessionErrorAction(payload))
        }
      }
      setTimeout(() => {
        if (handlingError) {
          handlingError = false
        }
      }, 1000)
    }
  }
)
