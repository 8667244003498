import { PERMANENT_STORE_DAYS } from '@configs/common'
import { INITIATED_FROM_STORAGE, PERSONALIZATION_ID } from '@foundation/constants/user'
import { localStorageUtil, storageSessionHandler } from '@foundation/utils/storageUtil'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { LoginIdentityResponse } from '@typesApp/account'
import { PersonResponse, PersonWalletResponse, Wallet } from '@typesApp/user'
import { userInitState as initialState } from './initState'
import { fiscalCodeApi } from './query'
import { fetchUserDetails } from './thunks/fetchUserDetails'
import { fetchUserWallet } from './thunks/fetchUserWallet'
import { login } from './thunks/login'
import { registration } from './thunks/registration'
import { clearUserState } from './utils'
import { USER_TYPE } from '@foundation/analytics/tealium/types'

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSelectedWallet: (state, action: PayloadAction<Wallet | null>) => {
      if (state.wallet) {
        state.wallet.selectedWallet = action.payload
      }
    },
    setIsReorderIn3ClicksButtonClicked: (state, action: PayloadAction<boolean>) => {
      state.isReorderIn3ClicksButtonClicked = action.payload
      storageSessionHandler.saveCurrentUser(state)
    },
    setIsReturningCustomerButtonClicked: (state, action: PayloadAction<boolean>) => {
      state.isReturningCustomerButtonClicked = action.payload
    },
    setNewsletterSubscribedUnsubscribed: (state, action) => {
      if (state.details) {
        state.details.x_data.hasNewsletter = action.payload.subscribed
        storageSessionHandler.saveCurrentUser(state)
      }
    },
    setUserLoggedIn: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload, {
          userLoggedIn: true,
          isGuest: false,
          //lastUpdated is not needed here, since we will fetch details right after.
        })
        storageSessionHandler.saveCurrentUser(state)
        //set personalizationID to localStorage
        const { personalizationID } = action.payload
        localStorageUtil.set(PERSONALIZATION_ID, personalizationID, PERMANENT_STORE_DAYS)
        state.isLoading = false
      }
      //else is init from storage.
    },
    setGuestLoggedIn: (state, action) => {
      if (action.payload) {
        Object.assign(state, action.payload)
        state.userLoggedIn = false
        state.isGuest = true
        state.lastUpdated = Date.now()
        const { personalizationID } = action.payload
        localStorageUtil.set(PERSONALIZATION_ID, personalizationID, PERMANENT_STORE_DAYS)
        storageSessionHandler.saveCurrentUser(state)
      }
      //else is init from storage.
    },
    setUserIsLoggingIn: (state, action: PayloadAction<boolean>) => {
      state.userIsLoggingIn = action.payload
    },
    setUserDetails: (state, action: PayloadAction<PersonResponse>) => {
      state.details = action.payload
      state.lastUpdated = Date.now()
      state.areDetailsLoading = false

      storageSessionHandler.saveCurrentUser(state)
    },
    setUserType: (state, action: PayloadAction<USER_TYPE>) => {
      state.userType = action.payload
      storageSessionHandler.saveCurrentUser(state)
    },
    clearUser: state => {
      clearUserState(state)
      storageSessionHandler.removeCurrentUser()
      state.lastUpdated = Date.now()
    },
    setUserFromStorage: (state, action: PayloadAction<LoginIdentityResponse>) => {
      clearUserState(state)
      if (action.payload !== null) {
        Object.assign(state, action.payload, {
          [INITIATED_FROM_STORAGE]: true,
        })
      } else {
        Object.assign(state, {
          [INITIATED_FROM_STORAGE]: true,
        })
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(login.rejected, (state, action) => {
      // state.userLoginErrorMsg = Object.assign({}, action.error.response);
      Object.assign(state, {
        userLoggedIn: false,
        isGuest: false,
        isLoading: false,
      })
    })
    builder.addCase(registration.fulfilled, (state, action) => {
      Object.assign(state, action.payload)
      state.userRegistration = true
      state.pendingRegistration = false
      state.userLoggedIn = true
      state.userIsLoggingIn = false
      state.isGuest = false
      state.lastUpdated = Date.now()
      storageSessionHandler.saveCurrentUser(state)
      const { personalizationID } = state
      localStorageUtil.set(PERSONALIZATION_ID, personalizationID, PERMANENT_STORE_DAYS)
    })
    builder.addCase(registration.pending, (state, action) => {
      state.pendingRegistration = true
    })
    builder.addCase(registration.rejected, (state, action) => {
      // state.userRegistrationErrorMsg = Object.assign({}, action.error.response);
      state.pendingRegistration = false
      state.userRegistration = false
      state.isGuest = true
    })
    builder.addCase(fetchUserDetails.pending, state => {
      state.areDetailsLoading = true
    })
    builder.addCase(fetchUserWallet.pending, state => {
      state.areDetailsLoading = true
    })
    builder.addCase(fetchUserWallet.fulfilled, (state, action: PayloadAction<PersonWalletResponse>) => {
      state.wallet = action.payload
      state.lastUpdated = Date.now()
      state.areDetailsLoading = false
      storageSessionHandler.saveCurrentUser(state)
    })
    builder.addMatcher(fiscalCodeApi.endpoints.getFiscalCode.matchFulfilled, (state, action) => {
      if (state.details) {
        const lastContact = action.payload.contact?.reduce((last: any, contact: any) => {
          if (contact.attributes && contact.attributes.some(attribute => attribute.value !== '')) {
            return contact
          }
          return last
        }, null)

        const fiscalCode = lastContact?.attributes?.find(i => i.key === 'addressField1')?.value || ''

        state.details = {
          ...state.details,
          fiscalCode: fiscalCode,
        }
      }
    })
  },
})

export const {
  setSelectedWallet,
  setNewsletterSubscribedUnsubscribed,
  setUserLoggedIn,
  setGuestLoggedIn,
  setUserIsLoggingIn,
  setUserDetails,
  clearUser,
  setUserFromStorage,
  setIsReturningCustomerButtonClicked,
  setIsReorderIn3ClicksButtonClicked,
  setUserType,
} = userSlice.actions

export default userSlice.reducer
