import { IErrorSliceState } from './IErrorSliceState'

export const errorInitState: IErrorSliceState = {
  errorKey: null,
  errorCode: null,
  errorTitleKey: null,
  errorMsgKey: null,
  errorBypass: false,
  handled: null,
  errorMessage: '',
  errorParameters: '',
  passwordResetRequired: false,
}
