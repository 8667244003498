import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import {
  ICreateOrderReminderPayload,
  ICreateOrderReminderResponse,
  IDeleteOrderReminderPayload,
  IDeleteOrderReminderResponse,
  IGetNearestOrderPayload,
  IGetNearestOrderReminderResponse,
  IGetOrderRemiderResponse,
  IGetOrderRemindePayload,
  IUpdateOrderReminderPayload,
  IUpdateOrderReminderResponse,
} from '../../types/order'

export const orderReminderApi = createApi({
  reducerPath: 'orderReminderApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: builder => ({
    getOrderReminder: builder.query<IGetOrderRemiderResponse, IGetOrderRemindePayload>({
      async queryFn(_args, _queryApi, _extraOptions, fetchWithBQ) {
        const { orderId, storeId } = _args
        const result = await fetchWithBQ({
          url: `store/${storeId}/reorderreminder/${orderId}`,
          method: 'GET',
          pathParams: {
            storeId: storeId,
          },
        })

        return result?.data
          ? { data: result.data as IGetOrderRemiderResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    createOrderReminder: builder.mutation<ICreateOrderReminderResponse, ICreateOrderReminderPayload>({
      async queryFn(_args, _queryApi, _extraOptions, fetchWithBQ) {
        const { orderId, storeId } = _args
        const createOrderPayload = _args
        delete createOrderPayload.orderId
        const result = await fetchWithBQ({
          url: `store/${storeId}/reorderreminder/${orderId}`,
          method: 'POST',
          body: {
            ...createOrderPayload,
          },
          pathParams: {
            storeId: storeId,
          },
        })

        return result?.data
          ? { data: result.data as ICreateOrderReminderResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    updateOrderReminder: builder.mutation<IUpdateOrderReminderResponse, IUpdateOrderReminderPayload>({
      async queryFn(_args, _queryApi, _extraOptions, fetchWithBQ) {
        const { orderId, storeId } = _args
        const createOrderPayload = _args
        delete createOrderPayload.orderId
        const result = await fetchWithBQ({
          url: `store/${storeId}/reorderreminder/${orderId}`,
          method: 'PUT',
          body: {
            ...createOrderPayload,
          },
          pathParams: {
            storeId: storeId,
          },
        })

        return result?.data
          ? { data: result.data as IUpdateOrderReminderResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    deleteOrderReminder: builder.mutation<IDeleteOrderReminderResponse, IDeleteOrderReminderPayload>({
      async queryFn(_args, _queryApi, _extraOptions, fetchWithBQ) {
        const { orderId, storeId } = _args
        const result = await fetchWithBQ({
          url: `store/${storeId}/reorderreminder/${orderId}`,
          method: 'DELETE',
          pathParams: {
            storeId: storeId,
          },
        })

        return result?.data
          ? { data: result.data as IDeleteOrderReminderResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
    getNearestOrderReminder: builder.query<IGetNearestOrderReminderResponse, IGetNearestOrderPayload>({
      async queryFn(_args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = _args
        const result = await fetchWithBQ({
          url: `store/${storeId}/reorderreminder/nextreminder`,
          method: 'GET',
          pathParams: {
            storeId: storeId,
          },
        })

        return result?.data
          ? { data: result.data as IGetNearestOrderReminderResponse }
          : { error: result.error as FetchBaseQueryError }
      },
    }),
  }),
})

export const {
  useLazyGetOrderReminderQuery,
  useCreateOrderReminderMutation,
  useUpdateOrderReminderMutation,
  useLazyGetNearestOrderReminderQuery,
} = orderReminderApi
