import { useEffect } from 'react'

function LayoutKeyBoardAvoidingOffset() {
  useEffect(() => {
    if (!window.visualViewport) {
      return
    }

    let pendingUpdate = false

    function viewportHandler(event) {
      if (pendingUpdate) return
      pendingUpdate = true

      const layoutViewport = document.querySelector('header')
      requestAnimationFrame(() => {
        pendingUpdate = false

        if (!layoutViewport) return
        if (layoutViewport.clientWidth > 500) return

        // Since the bar is position: fixed we need to offset it by the
        // visual viewport's offset from the layout viewport origin.
        const viewport = event.target
        const offsetLeft = viewport?.offsetLeft || 0
        const offsetTop = viewport?.offsetTop || 0

        const header = document.querySelector('header')
        if (header) {
          header.style.zIndex = '4'
          if (offsetTop > 0) {
            header.style.transition = '0.5s'
            header.style.transform = `translate(${offsetLeft}px, ${offsetTop}px) scale(${1 / viewport.scale})`
          } else {
            header.style.transition = '0s'
            header.style.transform = ''
          }
        }
      })
    }

    window.visualViewport.addEventListener('scroll', viewportHandler)
    window.visualViewport.addEventListener('resize', viewportHandler)

    // Clean up function
    return () => {
      if (!window.visualViewport) return
      const header = document.querySelector('header')
      if (header) {
        header.style.transform = ''
        header.style.zIndex = '4'
      }
      window.visualViewport.removeEventListener('scroll', viewportHandler)
      window.visualViewport.removeEventListener('resize', viewportHandler)
    }
  }, [])

  return null
}

export default LayoutKeyBoardAvoidingOffset
