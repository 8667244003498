import { createSlice } from '@reduxjs/toolkit'

export interface IUiState {
  openDrawerMegaMenu: {
    isOpen: boolean
    id: string
    categoryName?: string
  }
  openDrawerFilters: boolean
  openDrawerSearch: boolean
  openDrawerNewsLetter: boolean
  openDrawerCartSummary: boolean
  hamburgerMenuDrawerOpen: boolean
  openDrawerProductNotificationAvailable: boolean
  lastSelectedProduct: string
  productsMobileListingLayout: 'compact' | 'full'
  openModalSignIn: boolean
  openModalRegistration: boolean
  showFullPageLoader: boolean
  isRemovingBasketItem: string | null
  isBasketBusy: boolean
}

export const initialStateUi: IUiState = {
  openDrawerMegaMenu: {
    isOpen: false,
    id: '',
    categoryName: '',
  },
  openDrawerFilters: false,
  openDrawerSearch: false,
  openDrawerNewsLetter: false,
  openDrawerCartSummary: false,
  hamburgerMenuDrawerOpen: false,
  openDrawerProductNotificationAvailable: false,
  lastSelectedProduct: '',
  productsMobileListingLayout: 'compact',
  openModalSignIn: false,
  openModalRegistration: false,
  showFullPageLoader: false,
  isRemovingBasketItem: null,
  isBasketBusy: false,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialStateUi,
  reducers: {
    openDrawerMegaMenu: (state: IUiState, action) => {
      state.openDrawerMegaMenu.id = action.payload.id
      state.openDrawerMegaMenu.categoryName = action.payload.name
      state.openDrawerMegaMenu.isOpen = true
    },
    closeDrawerMegaMenu: (state: IUiState) => {
      state.openDrawerMegaMenu.id = ''
      state.openDrawerMegaMenu.categoryName = ''
      state.openDrawerMegaMenu.isOpen = false
    },
    openDrawerFilters: (state: IUiState, action) => {
      state.openDrawerFilters = action.payload
    },
    openDrawerSearch: (state: IUiState, action) => {
      state.openDrawerSearch = action.payload
    },
    openDrawerNewsLetter: (state: IUiState, action) => {
      state.openDrawerNewsLetter = action.payload
    },
    openDrawerCartSummary: (state: IUiState, action) => {
      state.openDrawerCartSummary = action.payload
    },
    openDrawerProductNotificationAvailable: (state: IUiState, action) => {
      state.openDrawerProductNotificationAvailable = action.payload
    },
    toggleHamburgerMenuOpen: (state: IUiState, action) => {
      state.hamburgerMenuDrawerOpen = action.payload
    },
    lastSelectedProduct: (state: IUiState, action) => {
      state.lastSelectedProduct = action.payload
    },
    productsMobileListingLayout: (state: IUiState, action) => {
      state.productsMobileListingLayout = action.payload
    },
    openModalSignIn: (state: IUiState, action) => {
      state.openModalSignIn = action.payload
    },
    openModalRegistration: (state: IUiState, action) => {
      state.openModalRegistration = action.payload
    },
    showFullPageLoader: (state: IUiState, action) => {
      state.showFullPageLoader = action.payload
    },
    setRemovingBasketItem: (state: IUiState, action) => {
      state.isRemovingBasketItem = action.payload
    },
    setBasketBusy: (state: IUiState, action) => {
      state.isBasketBusy = action.payload
    },
  },
})

export const {
  openDrawerFilters,
  openDrawerMegaMenu,
  closeDrawerMegaMenu,
  openDrawerSearch,
  openDrawerNewsLetter,
  openDrawerCartSummary,
  openDrawerProductNotificationAvailable,
  toggleHamburgerMenuOpen,
  lastSelectedProduct,
  productsMobileListingLayout,
  openModalSignIn,
  openModalRegistration,
  showFullPageLoader,
  setRemovingBasketItem,
  setBasketBusy,
} = uiSlice.actions

export default uiSlice.reducer
