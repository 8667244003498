import { createApi } from '@reduxjs/toolkit/query/react'
import { fromServerToClientProduct } from '@utils/fromServerToClientEntity'
import { RootReducerState } from '../../redux/reducers'
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { PaginationResponse } from '../../services/RequestService'
import { IServerProduct } from '../../types/product'

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/v2/',
  }),

  endpoints: build => ({
    getProductDetails: build.query<any, any>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
        const state = queryApi.getState() as RootReducerState
        const result = await fetchWithBQ({
          url: 'products',
          method: 'get',
          queryParams: args['queryParams'] || {},
          body: args['body'] || {},
          extraParams: { siteContextKey: 'search' },
          pathParams: { storeId: state.site.currentSite?.storeID! },
        })

        return result?.data
          ? {
              data: transformResponse(result.data),
            }
          : { error: result.error }
      },
    }),
  }),
})
const transformResponse = (data: PaginationResponse<IServerProduct & { type: 'item' }>) => {
  return fromServerToClientProduct(data.contents[0])
  //console.log('TEST', fromServerToClientProduct(data.contents[0]))
  //return data
  //return data.contents ?? []
}
export const { useGetProductDetailsQuery, useLazyGetProductDetailsQuery } = productApi
