import dynamicConfigs from '../../configuration/config.json'

const boolean = (value: string | undefined) => {
  return Boolean(value && value.toLowerCase && value.toLowerCase() === 'true')
}

const enableAnalytics = () => {
  return boolean(process.env.NEXT_PUBLIC_ENABLE_ANALYTICS) || true
}

const enableMonetate = () => {
  return boolean(process.env.NEXT_PUBLIC_ENABLE_MONETATE) || false
}

const env = process.env
const config = {
  publicUrl: env['NEXT_PUBLIC_URL'] ?? '/',
  routerBaseName: env['NEXT_PUBLIC_ROUTER_BASENAME'] as string,
  name: env['NEXT_PUBLIC_STORENAME'] as string,
  storeGroup: env['NEXT_PUBLIC_STOREGROUP'] as string,
  env: 'production',
  log2api: true, // used to trace logs to api server.
  cmsApiUrl: env['NEXT_PUBLIC_API_URL'] as string,
  cmsApiUrlInternal: env['NEXT_PUBLIC_API_URL_INTERNAL'] as string,
  logServerUrl: env['NEXT_PUBLIC_LOG_SERVER_URL'] as string,
  tabApiUrl: env['NEXT_PUBLIC_API_TAB_URL'] as string,
  cmsImageServerUrlPreconnect: env['NEXT_PUBLIC_CMS_IMAGES_URL_PRECONNECT'] as string,
  cmsImageServerUrl: env['NEXT_PUBLIC_CMS_IMAGES_URL'] as string,
  defaultCountry: env['NEXT_PUBLIC_DEFAULT_COUNTRY'] as string,
  defaultLocale: env['NEXT_PUBLIC_DEFAULT_LOCALE'] as string,
  availableLocales: env['NEXT_PUBLIC_LOCALES']?.split(',') || [],
  storePrefix: env['NEXT_PUBLIC_STOREPREFIX'] as string,
  defaultDamDomain: env['NEXT_PUBLIC_DAM_DOMAIN'] as string,
  useMockedAttachments: env['NEXT_PUBLIC_USE_MOCKED_ATTACHMENTS'] as string,
  isCmsPreviewEnabled: env['NEXT_PUBLIC_PREVIEW_ENABLED'] as string,
  socialLogin: env['NEXT_PUBLIC_SOCIAL_LOGIN_REDIRECT_URL'] as string,
  permamentStoreDays: 30,
  apiCalltimeout: 500000,
  catalog: {
    topCategoriesDepthLimit: '11,11',
    productionListFields: 'facetView',
    keywordLimit: 4,
  },
  pagination: {
    pageLimit: 12,
    pageDefaultOffset: 0,
  },
  orders: {
    calculationUsage: '-1,-2,-3,-4,-5,-6,-7',
    calculateOrder: '1',
    inventoryValidation: true,
    allocate: '***',
    backOrder: '***',
    remerge: '***',
    check: '*n',
    sortOrderItemBy: 'orderItemID',
    pagination: {
      pageLimit: 10,
      pageSizeOptions: [10, 25, 50],
    },
  },
  enableAnalytics: enableAnalytics(),
  tealiumAnalytics: {
    profile: '',
    shopperSlug: '',
    gtagId: '',
    isEnabled: false,
  },
  googleAnalytics: {
    id: '',
    digitalCatalogId: '',
    shopperSlug: '',
    gtagId: '',
  },
  vmmv: {
    fallbackScript: env['NEXT_PUBLIC_VMMV_FALLBACK_SCRIPT'] as string,
    source: env['NEXT_PUBLIC_VMMV_SOURCE'] as string,
    key: env['NEXT_PUBLIC_VMMV_KEY'] as string,
    channel: env['NEXT_PUBLIC_VMMV_CHANNEL'] as string,
    brand: env['NEXT_PUBLIC_VMMV_BRAND'] as string,
    type: env['NEXT_PUBLIC_VMMV_TYPE'] as string,
    fromStore: env['NEXT_PUBLIC_VMMV_FROM_STORE'] === 'true',
    isTryOnEnabled: env['NEXT_PUBLIC_VMMV_TRY_ON_ENABLED'] === 'true',
    isTakeScreenshotEnabled: env['NEXT_PUBLIC_VMMV_TAKE_SCREENSHOT_ENABLED'] === 'true',
    isPictureModeEnabled: env['NEXT_PUBLIC_VMMV_PICTURE_MODE_ENABLED'] === 'true',
    isUploadPictureEnabled: env['NEXT_PUBLIC_VMMV_UPLOAD_PICTURE_ENABLED'] === 'true',
    isTakePictureEnabled: env['NEXT_PUBLIC_VMMV_TAKE_PICTURE_ENABLED'] === 'true',
    isVideoModeEnabled: env['NEXT_PUBLIC_VMMV_VIDEO_MODE_ENABLED'] === 'true',
    isQrCodeEnabled: env['NEXT_PUBLIC_VMMV_QR_CODE_ENABLED'] === 'true',
  },
  frameGenius: {
    fallbackScript: env['NEXT_PUBLIC_FASA_FALLBACK_SCRIPT'] as string,
    frameAdvKey: env['NEXT_PUBLIC_FASA_KEY'] as string,
    frameAdvisor: {
      apiCore: env['NEXT_PUBLIC_FASA_API_CORE'] as string,
      initPositionFloatingBtn: {
        x: parseInt(env['NEXT_PUBLIC_FASA_X_POS'] as string),
        y: parseInt(env['NEXT_PUBLIC_FASA_Y_POS'] as string),
      },
      multibrand: env['NEXT_PUBLIC_FASA_MULTIBRAND'] === 'true',
      enableVideoMode: env['NEXT_PUBLIC_FASA_ENABLE_VIDEO_MODE'] === 'true',
      productRequestRowsLimit: parseInt(env['NEXT_PUBLIC_FASA_PRODUCT_REQUEST_ROWS_LIMIT'] as string),
      apiPrefix: env['NEXT_PUBLIC_FASA_API_PREFIX'] as string,
      isDebuggingModeEnabled: env['NEXT_PUBLIC_FASA_ENABLED_DEBUG_MODE'] === 'true',
    },
    source: env['NEXT_PUBLIC_FASA_SOURCE'] as string,
  },
  searchContext: env['NEXT_PUBLIC_SEARCH_CONTEXT'] as string,
  transactionContext: env['NEXT_PUBLIC_TRANSACTION_CONTEXT'] as string,
  directBilling: {
    host: env['NEXT_PUBLIC_DIRECT_BILLING_HOST'] as string,
    termsConditionsUrl: env['NEXT_PUBLIC_DIRECT_BILLING_TERMS_CONDITIONS_URL'] as string,
    privacyPolicyUrl: env['NEXT_PUBLIC_DIRECT_BILLING_PRIVACY_POLICY_URL'] as string,
    landingPageUrl: env['NEXT_PUBLIC_DIRECT_BILLING_LANDING_PAGE_URL'] as string,
  },
  envInstance: env['NEXT_PUBLIC_ENV_INSTANCE'] as string,
  enableMonetate: enableMonetate(),
  storeLocator: {
    server: env['NEXT_PUBLIC_STORE_LOCATOR_SERVER'] as string,
    gmap: env['NEXT_PUBLIC_STORE_LOCATOR_GMAP'] as string,
  },
  algolia: {
    id: env['NEXT_PUBLIC_ALGOLIA_APP_ID'] as string,
    indexName: env['NEXT_PUBLIC_ALGOLIA_INDEX_NAME'] as string,
    apiKey: env['NEXT_PUBLIC_ALGOLIA_API_KEY'] as string,
    groupedSufix: env['NEXT_PUBLIC_ALGOLIA_GROUPED_SUFIX'] as string,
    ungroupedSufix: env['NEXT_PUBLIC_ALGOLIA_UNGROUPED_SUFIX'] as string,
    newestSufix: env['NEXT_PUBLIC_ALGOLIA_NEWEST_SUFIX'] as string,
    querySuggestionsSufix: env['NEXT_PUBLIC_ALGOLIA_QUERY_SUGGESTIONS_SUFIX'] as string,
    ascSufix: env['NEXT_PUBLIC_ALGOLIA_ASC_SUFIX'] as string,
    descSufix: env['NEXT_PUBLIC_ALGOLIA_DESC_SUFIX'] as string,
    guestSegment: env['NEXT_PUBLIC_ALGOLIA_GUEST_SEGMENT'] as string,
    basePriceListSufix: env['NEXT_PUBLIC_ALGOLIA_BASE_PRICE_LIST_SUFIX'] as string,
  },
  idMe: {
    server: env['NEXT_PUBLIC_IDME_SERVER'] as string,
    clientId: env['NEXT_PUBLIC_IDME_CLIENT_ID'] as string,
  },

  transactionHost: env['NEXT_PUBLIC_TRANSACTION_HOST'],
  transactionHostInternal: env['NEXT_PUBLIC_TRANSACTION_HOST_INTERNAL'],
  akamaiBypassKey: env['NEXT_PUBLIC_AKAMAI_BYPASS_KEY'] as string,

  forterSiteId: env['NEXT_PUBLIC_FORTER_SITE_ID'] as string,
  showShippingEstimanedDeliveryDate: env['NEXT_PUBLIC_SHOW_SHIPPING_ESTIMATED_DELIVERY_DATE'] === 'true',
  appBookEyeBenefitBarEnabled: env['NEXT_PUBLIC_APP_BOOK_EYE_BENEFIT_BAR_ENABLED'] === 'true',
  trustPilotEnabled: env['NEXT_PUBLIC_APP_TRUST_PILOT_ENABLED'] === 'true',
  wordLiftEnabled: env['NEXT_PUBLIC_APP_WORDLIFT_ENABLED'] === 'true',

  cacheContentMaxAge: Number(env['NEXT_CACHE_CONTENT_MAX_AGE'] || 300),
  cacheCategoryMaxAge: Number(env['NEXT_CACHE_CATEGORY_MAX_AGE'] || 300),
  cacheProductMaxAge: Number(env['NEXT_CACHE_PRODUCT_MAX_AGE'] || 600),
  cacheSwrFactor: Number(env['NEXT_CACHE_SWR_FACTOR'] || 2),
  productImageAkamaiPolicy: env['NEXT_PUBLIC_PRODUCT_IMAGE_AKAMAI_POLICY'] as string,
  productImageAkamaiDefaultWidth: env['NEXT_PUBLIC_PRODUCT_IMAGE_AKAMAI_DEFAULT_WIDTH'] as string,
  productImageAkamaiDefaultBgColor: env['NEXT_PUBLIC_PRODUCT_IMAGE_AKAMAI_DEFAULT_BACKGROUND_COLOR'] as string,
  productImageRatioWidth: env['NEXT_PUBLIC_PRODUCT_IMAGE_RATIO_WIDTH'] as string,
  productImageRatioHeight: env['NEXT_PUBLIC_PRODUCT_IMAGE_RATIO_HEIGHT'] as string,
  homepageSectionLimit: env['HOMEPAGE_SECTION_LIMIT'] as string,
}

// only to get correct configuration for
let configDynamic = {}
if (typeof window !== 'undefined') {
  const hostName = window.location.hostname
  if ('localhost' !== hostName) {
    configDynamic = dynamicConfigs[hostName]
  }
}

export type AppConfig = typeof config
const appConfig: AppConfig = { ...config, ...configDynamic }
export default appConfig
