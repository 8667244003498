import { createSlice } from '@reduxjs/toolkit'
import { ISeo } from '@typesApp/seo'

export type SeoStateInterface = {
  loading?: boolean
  seoData?: ISeo | null
}

const initialState: SeoStateInterface = { loading: false, seoData: null }

export const seoSlice = createSlice({
  name: 'seo',
  initialState,
  reducers: {
    setSeoData: (state: SeoStateInterface, action) => {
      state.seoData = action.payload
    },
  },
})

export const { setSeoData } = seoSlice.actions

export default seoSlice.reducer
