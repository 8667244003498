import { IncomingMessage } from 'node:http'
import domainUrlsConfiguration from '../../configuration/config.json'
import { DomainConfig } from '@typesApp/configurations/domain'

type HostNameURLProps = {
  locationOrigin: string
  protocol: string
  hostname: string
  hostnameUrls: DomainConfig
}

export const getHostnameUrls = (req: IncomingMessage | undefined): HostNameURLProps => {
  /* Akamai sends a comma-separated string of all origin hostnames for each jump: "uat.clearly.ca, uat.clearly.ca" */
  const port = getPort(req)
  const protocol = getProtocol(req)
  const hostname = getHost(req)
  const hostnameUrls = domainUrlsConfiguration?.[hostname] ?? {}
  const locationOrigin = `${protocol}://${hostname}${port ? `:${port}` : ''}`

  return {
    locationOrigin,
    protocol,
    hostname,
    hostnameUrls,
  }
}

const getPort = (req: IncomingMessage | undefined) => {
  if (process.env.NODE_ENV === 'development') {
    return req?.headers?.['x-forwarded-port'] || ''
  }
  return ''
}

const getProtocol = (req: IncomingMessage | undefined): string => {
  if (typeof window === 'undefined') {
    const HTTPS = 'https'
    const protocolHeader = req?.headers?.['x-forwarded-proto']

    if (Array.isArray(protocolHeader)) {
      return protocolHeader.includes(HTTPS) ? HTTPS : protocolHeader[0] // Prefer HTTPS if present
    }

    if (typeof protocolHeader === 'string' && protocolHeader.includes(',')) {
      const protocols = protocolHeader.split(',')
      return protocols.includes(HTTPS) ? HTTPS : protocols[0] // Prefer HTTPS if present
    }

    return protocolHeader ?? 'NO_PROTOCOL_FOUND'
  }

  return window.location.protocol.replace(':', '')
}

const getHost = (req: IncomingMessage | undefined): string => {
  if (typeof window === 'undefined') {
    return req?.headers?.host ?? 'HOST_NOT_FOUND'
  }

  return window.location.host
}
