import { RootReducerState } from '@redux/reducers'

export const contractSelector = (state: RootReducerState) => state.contract

export const currentContractIdSelector = (state: RootReducerState) => {
  let contractId = state.context?.entitlement?.currentTradingAgreementIds[0]
  if (!contractId && state.contract) {
    contractId = Object.keys(state.contract)[0]
  }
  return contractId ? String(contractId) : contractId
}

export const currentEntitledContractsSelector = (state: RootReducerState) =>
  state.context.entitlement?.currentTradingAgreementIds || []
