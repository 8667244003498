import { useMemo } from 'react'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { DeviceType } from '@typesApp/common'

export const useDeviceType = (): DeviceType | '' => {
  const { isMobile, isTablet, isDesktop } = useBreakpoint()

  const deviceType = useMemo(() => {
    if (isMobile) {
      return DeviceType.Mobile
    }
    if (isTablet) {
      return DeviceType.Tablet
    }
    if (isDesktop) {
      return DeviceType.Desktop
    }

    return ''
  }, [isMobile, isDesktop, isTablet])

  return deviceType
}
