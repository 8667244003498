//Standard libraries
import { AnyAction, createReducer } from '@reduxjs/toolkit'
//Redux
import {
  INIT_SITE_SUCCESS_ACTION,
  SET_ALGOLIA_BASED_PLP_ACTION,
  SET_HOSTNAME,
  SET_HOSTNAME_URLS,
} from '../actions/site'
import initStates from './initStates'
import { SET_LOCATION_ORIGIN } from '@redux/action-types/site'

/**
 * Site reducer
 */
const siteReducer = createReducer(initStates.site, builder => {
  builder.addCase(INIT_SITE_SUCCESS_ACTION, (state: any | any, action: AnyAction) => {
    Object.assign(state, { currentSite: { ...action.payload } })
  })
  builder.addCase(SET_ALGOLIA_BASED_PLP_ACTION, (state: any | any, action: AnyAction) => {
    Object.assign(state, {
      currentSite: {
        ...state.currentSite,
        storeCfg: {
          ...state.currentSite.storeCfg,
          userData: {
            ...state.currentSite.storeCfg.userData,
            ['algolia.based.plp']: action.payload,
          },
        },
      },
    })
  }),
    builder.addCase(SET_HOSTNAME, (state: any | any, action: AnyAction) => {
      Object.assign(state, {
        ...state,
        hostname: action.payload,
      })
    })
  builder.addCase(SET_HOSTNAME_URLS, (state: any | any, action: AnyAction) => {
    Object.assign(state, {
      ...state,
      hostnameUrls: action.payload,
    })
  })
  builder.addCase(SET_LOCATION_ORIGIN, (state: any | any, action: AnyAction) => {
    Object.assign(state, {
      ...state,
      locationOrigin: action.payload,
    })
  })
})
export default siteReducer
