import { createAsyncThunk } from '@reduxjs/toolkit'
import paymentInstructionService from '../../../foundation/apis/transaction/paymentInstruction.service'

export interface IFetchPaypalExpress {
  successUrl: string
  cancelUrl: string
  storeId: string
}

const fetchPaypalExpress = createAsyncThunk<any, IFetchPaypalExpress>('order/fetchPaypalExpress', async args => {
  const { successUrl, cancelUrl, storeId } = args
  const response = await paymentInstructionService.getPaypalPaymentInfo(successUrl, cancelUrl, storeId)
  return response
})

export default fetchPaypalExpress
