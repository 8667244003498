import { logout } from '@features/common/slice'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { orderApi } from '@features/order/query'
import { BankDetail } from '@typesApp/ideal'

export type PaymentError = {
  message: string
} | null

export type PaymentsState = {
  loading: boolean
  ideal: {
    availableBankDetails: BankDetail[]
    selectedBankId: string
  }
  error: PaymentError
}

export const initialState: PaymentsState = {
  loading: false,
  ideal: {
    availableBankDetails: [],
    selectedBankId: '',
  },
  error: null,
}

const paymentSlice = createSlice({
  name: 'Payments',
  initialState,
  reducers: {
    setIdealSelectedBankId: (state, action: PayloadAction<string>) => {
      state.ideal.selectedBankId = action.payload
    },
    setIdealAvailableBankDetails: (state, action: PayloadAction<BankDetail[]>) => {
      state.ideal.availableBankDetails = action.payload
    },
    setIdealError: (state, action: PayloadAction<PaymentError>) => {
      state.error = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(logout, () => initialState),
      builder.addMatcher(orderApi.endpoints.finalizeOrderWithCybersource.matchFulfilled, () => initialState)
  },
})

export const { setIdealSelectedBankId, setIdealAvailableBankDetails, setIdealError } = paymentSlice.actions

export default paymentSlice.reducer
