import contractService from '@foundation/apis/transaction/contract.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { LoginIdentityResponse } from '@typesApp/account'
import { IPayloadBasePromoCode } from '@typesApp/common'
import { IContractSliceState } from '../IContractSliceState'
import { preselectContract } from './preselectContract'

export type IFetchContractArgs = IPayloadBasePromoCode | LoginIdentityResponse | undefined

export const fetchContract = createAsyncThunk<IContractSliceState, IFetchContractArgs>(
  'contract/fetchContract',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const response = await contractService.findEligible(args)
      await dispatch(preselectContract(args))
      return { ...response.data }
    } catch (e) {
      return rejectWithValue(e)
    }
  }
)
