import { BankDetailsResponse, BankSelectRequest, BankSelectResponse } from '@typesApp/ideal'
import { axiosBaseQuery } from '../../../services/AxiosBaseQuery'
import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

export const IdealApi = createApi({
  reducerPath: 'IdealApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: build => ({
    fetchAvailableBankDetails: build.query<
      BankDetailsResponse,
      {
        storeId: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/ideal/banklist/@self`,
          method: 'GET',
          pathParams: { storeId },
        })

        if (result.error) {
          return { error: result.error as FetchBaseQueryError }
        }
        return { data: result.data as BankDetailsResponse }
      },
    }),
    selectBank: build.mutation<BankSelectResponse, BankSelectRequest>({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, ...rest } = args
        const result = await fetchWithBQ({
          url: `store/${storeId}/ideal/saleMerchantUrl/@self`,
          method: 'POST',
          body:
            {
              ...rest,
            } || {},
          pathParams: { storeId },
        })
        if (result.error) {
          return { error: result.error }
        }
        return { data: result.data as BankSelectResponse }
      },
    }),
  }),
})
